import React, { useState } from 'react'
import { Link, useHistory as useHerstory } from 'react-router-dom'
import Sopp from '../../images/thune_sopp.svg'

import { Container } from './styles'
import { useWebAuthn } from '../../webauthn/page'

const Login = () => {
    const [account, setAccount] = useState('')
    const { login, error, loading } = useLogin()

    if (error) return (
        <Container>
            <div>
                <h1>Noe gikk galt...</h1>
                <p>Det kan hende eposten ikke finnes i Navision.</p>
                <p>{error}</p>
            </div>
        </Container>
    )

    return (
        <div>

            <div className="flex justify-between items-center p-4">
                <img src={Sopp} alt="" className="max-h-8"/>

                <Link to="/login/sign-up"
                      className="hover:text-purple-600 font-black skew-x-12 text-xl underline decoration-2 hover:decoration-4 hover:decoration-green-300">
                    Prøv det GRATIS
                </Link>
            </div>


            <div className="mt-[10vh] ">
                <p className="text-center text-yellow-600 font-medium">Helten løfter blikket og stirrer på...</p>
                <h1 className="text-center text-6xl sm:text-9xl font-black skew-x-12 mega">
                    KANBAN!!
                </h1>
            </div>

            <div className="max-w-2xl m-auto">
                <div className="flex flex-col p-8 rounded-xl my-4">
                    <div className="flex flex-col mb-8">
                        <label htmlFor="email" className="dark:text-gray-300 text-gray-400 font-medium ml-2">
                            Din E-Post Adresse</label>
                        <input
                            id="email"
                            className="px-3 py-2 border-b-2 dark:border-b-gray-500 border-black border-dashed text-lg font-bold focus:outline-none bg-transparent"
                            type="email"
                            placeholder="deg@thuneprodukter.no"
                            value={account}
                            onChange={e => setAccount(e.target.value)}
                        />
                    </div>
                    <div className="flex flex-col sm:flex-row items-center justify-center space-x-4">
                        <button
                            className="text-orange-600 dark:text-orange-400  font-bold underline  text-base hover:decoration-2"
                            disabled={loading}
                            onClick={() => login(account)}>
                            {loading && (
                                <i className="fad fa-spinner-third animate-spin text-xl"/>
                            )}
                            Send meg midlertidig passord på Epost
                        </button>
                        <p className="text-gray-500">eller</p>
                        <SecurityKeyLogin account={account}/>
                    </div>
                </div>
            </div>

        </div>
    )
}

const useLogin = () => {
    const herstory = useHerstory()
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    const login = (account) => {
        setLoading(true)
        fetch('/api/kanban/v1/login', {
            method: 'POST',
            headers: {
                'Account': account
            }
        }).then(res => {
            if (res.status !== 200) {
                setError(`${res.status} ${res.statusText}`)
                return
            }
            herstory.push(`/login/verify?email=${account}`)
        }).catch(err => {
            console.error(err)
            setError('ukjent error')
        }).then(() => {
            setLoading(false)
        })
    }

    return {
        login,
        error,
        loading
    }
}

const SecurityKeyLogin = ({ account }) => {
    const herstory = useHerstory()
    const { login, loading } = useWebAuthn(account, () => {
        herstory.push('/')
    })

    if (!window.PublicKeyCredential) return <div className="bg-depth-5 text-warm-100 p-4 rounded-xl">
        Denne nettleseren støtter ikke innlogging med sikkerhetsnøkkler.
    </div>

    return (
        <button
            className="text-orange-500 dark:bg-gray-700 hover:bg-orange-300 py-2 px-3 rounded-2xl"
            onClick={login}>
            {loading ? (
                <i className="fad fa-spinner-third animate-spin text-xl"/>
            ) : (
                <i className="fas fa-key text-xl"/>
            )}
        </button>
    )
}


export default Login
