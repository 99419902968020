import axios from "axios"
import { useQuery } from "react-query"

export const useHoursWorkedPerDay = (year, month) => {
    return useQuery(['hours-worked-per-day', year, month], fetchData,
        {
            retry: false,
        })
}
const fetchData = ({ queryKey }) => {
    const year = queryKey[1]
    const month = queryKey[2]
    return axios.get(`/api/kanban/v1/kanban/hours-worked-overview?year=${year}&month=${month}`)
}

export const useHome = (date) => {
    return useQuery(['home', date], fetchHome, 
    {

    })
}

const fetchHome = ({queryKey}) => {
    console.log(queryKey)
    return fetch(`/api/kanban/v1/home?date=${queryKey[1]}`).then(res => res.json())

}