import React, { useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { PaperNavHeader2 } from '../navigation/sheet'
import Spinner from '../components/spinner'
import { useQuery } from 'react-query'
import { sortComponents } from '../kanban/lager'
import dayjs from 'dayjs'
import { Image } from '../drawing/image'

const doesItExist = (routingRef, routes) => {

    for (let i = 0; i < routes?.length ?? 0; i++) {
        if (routes[i].routing_reference_no === routingRef) {
            return routes[i]
        }

        const s = doesItExist(routingRef, routes[i].subroutes)
        if (s) {
            return s
        }
    }

    return null
}

export const ProductionPage = () => {
    const { order } = useParams()
    const { data: production, isError, error, isLoading } = useQuery(['production', 'order', order], () =>
        fetch(`/api/kanban/v1/production?po=${order}`).then(res => res.json())
    )

    if (isLoading) return <Spinner/>
    if (isError) return error.message

    return (
        <div className="m-auto min-h-screen" style={{ maxWidth: 1800 }}>
            <PaperNavHeader2 title="Prosjekt"/>
            <div className="relative max-w-5xl m-auto grid grid-cols-12" style={{ gridGap: '1rem' }}>
                <div
                    className={`col-span-12 flex flex-col space-y-4 `}
                    style={{ gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr)' }}>
                    {production?.items?.map((route, index) => (
                        <Product key={index} route={route}/>
                    ))}
                </div>
            </div>
        </div>
    )
}

const useOriginItem = (routingRef) => {
    const location = useLocation()
    const [, hash] = location?.hash?.split('#')
    return routingRef === parseInt(hash)
}

const Product = ({ route }) => {
    const {
        no,
        description,
        due_date,
        subroutes,
        operations,
        statistics
    } = route
    const dueDate = dayjs(due_date).format('DD MMMM YYYY')
    const isOrigin = useOriginItem(route.routing_reference_no)

    return (
        <div className="shadow-paper-lg dark:bg-gray-800 p-4 rounded-xl">
            <div className="flex space-x-4 mb-2">
                <Image no={no} className="rounded-lg max-h-36" />
                <div>
                    <Link to={`/route/${route.prod_order_no}/${route.prod_order_line_no}/${route.routing_no}`}
                          className={`font-bold text-left ${isOrigin && 'text-green-500'}`}>
                        {description}
                    </Link>
                    <Reol operations={operations}/>
                    <p className="font-medium text-gray-500">{dueDate}</p>
                    <Statistics {...statistics} />
                </div>
            </div>
            <SubroutesLister subroutes={subroutes}/>
        </div>
    )
}

const SubroutesLister = ({ subroutes }) => {
    return (
        <div className="flex flex-col">
            {subroutes.map((subroute, i) => (
                <Subroute subroute={subroute} key={i}/>
            ))}
        </div>
    )
}

const Subroute = ({ subroute }) => {
    const { no, routing_reference_no, description, due_date, statistics, components, operations, subroutes } = subroute
    const { color } = sortComponents(components)

    const [isOpen, setIsOpen] = useState(false)
    const toggle = () => setIsOpen(!isOpen)

    const hasSubroutes = subroutes.length > 0
    const isOrigin = useOriginItem(routing_reference_no)
    const diff = dayjs(due_date).diff(dayjs(), 'days')

    return (
        <div className="flex my-1 ">
            <ItemsLine show={hasSubroutes} toggle={toggle}/>
            <div className={`w-full`}>
                <div className={`flex relative justify-center items-start rounded-lg`}>
                    <Image no={no} className="max-h-14 rounded-lg" />
                    <div className="w-full ml-2">
                        <Link to={`/route/${subroute.prod_order_no}/${subroute.prod_order_line_no}/${subroute.routing_no}`}
                                className={`font-bold ${isOrigin && `text-green-500`}`}>{description}
                        </Link>
                        <div className="flex items-center justify-between">
                            <div className="flex space-x-4 items-center">
                                <p className="text-gray-500">{no}</p>
                                <p className="text-gray-500">{diff} dager</p>
                            </div>
                            <div className="flex items-center space-x-1">
                                <Reol operations={operations}/>
                                <Statistics {...statistics} />
                                <p className={`fas fa-shopping-cart  ${color}`}/>
                            </div>
                        </div>
                    </div>
                </div>
                {isOpen && (
                    <div className="ml-3">
                        <SubroutesLister subroutes={subroutes}/>
                    </div>
                )}
            </div>
        </div>
    )
}

const ItemsLine = ({ show, toggle }) => {
    if (!show) return null
    return (
        <div className="inline-block group cursor-pointer" onClick={toggle}>
            <div className="w-1 ml-1 mr-2 rounded-full bg-gray-500 group-hover:bg-purple-500 h-full block"/>
        </div>
    )
}

const Reol = ({ operations }) => {
    for (let i = operations?.length; i >= 0; i--) {
        if (operations[i]?.storage_location?.in_stock) {
            return (
                <p className="flex space-x-1 items-center">
                    <i className="fad fa-inventory"/>
                    <span>
                        {operations[i]?.storage_location.location}
                    </span>
                </p>
            )
        }
    }
    return null
}


const imageURL = (no) => {
    return `/api/kanban/v1/media?item=${encodeURIComponent(no)}`
}

const routeURL = (order, line, routing) => `/route/${order}/${line}/${routing}`

const Statistics = ({ absolute_percentage, live_percentage }) => (
    <div className="relative flex rounded overflow-hidden w-16">
        <div
            style={{ width: `${absolute_percentage}%` }}
            className="z-30 bg-green-400 dark:bg-green-600">
            <p className="text-gray-700 dark:text-white text-sm px-2 py-1 font-bold text-center">{absolute_percentage}%</p>
        </div>
        <div
            style={{ width: `${live_percentage}%` }}
            className="z-20 bg-green-400 dark:bg-green-600 bg-opacity-50 animate-pulse"
        />
        <div
            className="absolute inset-0 bg-gray-700 z-10"
        />
    </div>
)