import { useEffect, useRef, useState } from 'react'
import { matchSorter } from 'match-sorter'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-hot-toast'
import { useOutsideAlerter } from '../../kanban/page'
import { useKalkyleCacheInvalidate } from './thought'
import { keyParams } from '../index'
import { useParams } from 'react-router-dom'

export const WorkCenterMenuKalkyle = ({ operation }) => {
    const mutation = useWorkCenterMutationKalkyle(operation)

    return (
        <WorkCenterMenu mutation={mutation} name={operation?.center?.name ?? ""} />
    )
}

export const WorkCenterMenuKanban = ({ name, prodOrderNo, routingRefNo, routingNo, operationNo }) => {
    const mutation = useWorkCenterMutationKanban(prodOrderNo, routingRefNo, routingNo, operationNo)

    return (
        <WorkCenterMenu mutation={mutation} name={name} title="Hvor vil du flytte operasjonen til?" showIcon={false} />
    )
}

const WorkCenterMenu = ({ mutation, name, title = "Hvor kan dette skje?", showIcon = true }) => {
    const [searchText, setSearchText] = useState('')
    const [show, setShow] = useState(false)
    const toggle = () => setShow(!show)

    const ref = useRef()
    useOutsideAlerter(ref, () => {
        setShow(false)
    })

    const handleMutate = async ({ workCenter, machineCenter }) => {
        mutation.mutate({
            workCenter,
            machineCenter,
        })
    }

    const handleSelect = async (center) => {
        await setShow(false)
        handleMutate(center)
    }

    return (
        <div>
            <button onClick={toggle} className="text-left text-gray-400 rounded-full hover:underline">
                {showIcon && (
                    <i className="fas fa-hard-hat mr-1"/>
                )}
                {mutation.isLoading ? (<i className="fad fa-spinner-third animate-spin text-green-400"/>) : name}
            </button>
            {show && (
                <div ref={ref}
                     draggable="false"
                     className="absolute right-2 left-2 top-full z-30 px-3 py-2 bg-gradient-to-br from-pink-500 to-purple-400 rounded-xl pop-open">
                    <div className="flex flex-col">
                        <p className="py-1 pb-2 text-lg text-gray-900 font-bold text-center">{title}</p>
                        <div className="">
                            <input autoFocus type="text"
                                   className="px-3 py-2 w-full text-lg placeholder-gray-500 bg-gray-800 rounded-lg"
                                   placeholder="Mazak Nexus"
                                   onChange={e => setSearchText(e.target.value)}/>
                            <WorkCenterLister searchText={searchText} select={handleSelect}/>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

const WorkCenterLister = ({ searchText, select }) => {
    const { workCenters: centers, isLoading } = useWorkCenters()
    const [searchResult, setSearchResult] = useState([])

    useEffect(() => {
        if (!centers) return
        const list = matchSorter(centers, searchText, {
            keys: [
                'no', 'name',
                center => center?.machines?.map(c => c.name),
                center => center?.machines?.map(c => c.no),
            ]
        })
        setSearchResult(list)
    }, [searchText])

    if (searchText.length === 0) return (
        <div>
            <p className="py-2 font-medium text-center text-gray-800">Søk etter et arbeisenter eller maskinsenter</p>
        </div>
    )

    return (
        <div className="flex flex-col my-4 space-y-4">
            {isLoading && (
                <i className="fad fa-spinner-third animate-spin text-3xl"/>
            )}
            {searchResult?.slice(0, 5).map((center, i) => (
                <div key={i}>
                    <button
                        onClick={() => select({ workCenter: center.no, machineCenter: center.no})}
                        className="text-left text-white hover:underline">
                        {center.name}
                    </button>
                    <MachineCentersLister machines={center.machines ?? []} select={select}/>
                </div>
            ))}
        </div>
    )
}

const MachineCentersLister = ({ machines, select }) => {
    return (
        <div className="flex flex-wrap ">
            {machines?.map((m, i) => (
                <button onClick={() => select({ workCenter: m.work_center_no, machineCenter: m.no })}
                        className="text-white bg-purple-600 bg-opacity-50 px-3 py-2 m-[0.2rem] rounded-lg sm:hover:bg-opacity-100" key={i}>
                    <p>{m.name}</p>
                </button>
            ))}
        </div>
    )
}

const useWorkCenters = () => {
    const { data, ...rest } = useQuery(['work-centers'], () =>
        fetch('/api/kanban/v1/work-centers')
            .then(async res => {
                if (res.status !== 200) throw new Error(await res.text())
                return res.json()
            }))
    return {
        workCenters: data,
        ...rest
    }
}

const useWorkCenterMutationKanban = (prodOrderNo, prodOrderLineNo, routingNo, operationNo) => {
    const { center, machine } = useParams()
    const client = useQueryClient()

    const handle = ({ workCenter, machineCenter }) =>
        fetch(`/api/kanban/v1/kanban/operation/work-center`, {
            method: 'POST',
            body: JSON.stringify({
                'prod_order_no': prodOrderNo,
                'prod_order_line_no': prodOrderLineNo,
                'routing_no': routingNo,
                'operation_no': operationNo,
                'new_work_center_no': workCenter,
                'new_machine_center_no': machineCenter
            })
        }).then(async res => {
            if (res.status !== 202) throw new Error(await res.text())
            return {
                workCenter, machineCenter
            }
        })
    return useMutation(handle, {
        onSuccess: async (d) => {
            console.log({ d })
            await client.invalidateQueries(['workcenter', center, machine])
            toast.success('Operasjonen er flyttet!')
            client.invalidateQueries(['workcenter', d.workCenter, d.machineCenter])
        },
        onError: err => {
            toast.error('')
            console.error(`[WorkCenter] failed: ${err}`)
        }
    })
}


export const useWorkCenterMutationKalkyle = (operation) => {
    const { invalidateThoughtItems } = useKalkyleCacheInvalidate()

    let queries = `/default?id=${encodeURIComponent(operation.id)}`
    if (operation?.external_metadata?.published) {
        queries = `/published?${keyParams(operation.external_metadata.parent_key, operation.external_metadata.key)}`
    }

    const handler = ({ workCenter, machineCenter }) =>
        fetch(`/api/kanban/v1/kalkyle/operation/center${queries}`, {
            method: 'PUT',
            body: JSON.stringify({
                'work_center': workCenter,
                'machine_center': machineCenter
            })
        })

    return useMutation(handler, {
        onSuccess: async () => {
            await invalidateThoughtItems()
            toast.success('Arbeidssenter oppdatert i Navision!')
        }
    })
}
