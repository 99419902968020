import { useHistory as useHerstory } from 'react-router-dom'
import styled from 'styled-components'
import React from 'react'
import Seperator from '../components/Seperator'

export const PaperNavBackButton = ({ to }) => {
    const herstory = useHerstory()

    const navigate = () => {
        if (to) {
            herstory.replace(to)
            return
        }

        herstory.goBack()
    }

    return (
        <PaperNavBackButtonContainer onClick={navigate}>
            <i className="fas fa-chevron-left"/>
            {' '}
            Tilbake
        </PaperNavBackButtonContainer>
    )
}

const PaperNavBackButtonContainer = styled.button`
  font-weight: bold;
  border: 2px solid rgb(var(--rgb-purple));
  color: rgb(var(--rgb-purple));
  padding: .5rem .8rem;
  border-radius: 1rem;
  background-color: rgba(var(--rgb-medium-gray), .3);
`

export const SheetImageHeader = ({ children, image, left, right, title, subtitle }) => {
    return (
        <div>
            <SheetHeaderImageContainer>
                <ImageContainer className="image">
                    <Image src={image}  onError={(e) => e.target.style.display = 'none'}/>
                </ImageContainer>
                <div className="left">
                    {left}
                </div>
                <h1 className="header">
                    {title}
                </h1>
                {subtitle && (
                    <h3 className="subtitle">
                        {subtitle}
                    </h3>
                )}
                <div className="right">
                    {right}
                </div>
            </SheetHeaderImageContainer>
            <div>
                {children}
            </div>
            <Seperator lg/>
        </div>
    )
}

const ImageContainer = styled.div`
  display: flex;
  height: 300px;
`

const SheetHeaderImageContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: 
    "left     .        right"
    "image    image    image"
    "header   header   header"
    "subtitle subtitle subtitle";
    
  .left {
    grid-area: left;
  }
  .right {
    grid-area: right;
  }
  .image {
    grid-area: image;
    margin: auto;
    padding: 0.5em;
  }
  .header {
    grid-area: header;
    text-align: center;
    margin: 0;
    padding: 0;
  }
  .subtitle {
    grid-area: subtitle;
    text-align: center;
    color: var(--depth-1);
    margin: 0;
    padding: 0;
  }
`

const Image = styled.img`
  display: block;
  margin: auto;
  max-height: 100%;
  border-radius: 2rem;
`

export const PaperNavHeader2 = ({ title, left, right, children }) => {
    return (
        <div>
            <PaperNavHeaderContainer>
                <div className="left">
                    {left}
                </div>
                <h1 className="header text-2xl md:text-4xl lg:text-5xl text-center font-bold">
                    {title}
                </h1>
                <div className="right">
                    {right}
                </div>
            </PaperNavHeaderContainer>
            <div>
                {children}
            </div>
            <Seperator lg/>
        </div>
    )
}

const PaperNavHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: 
    "left   .    right"
    "header header header";
   padding: 1em;
   margin: 0;
    
  .left {
    grid-area: left;
  }
  
  .right {
    grid-area: right;
  }
  
  h1 {
    padding: 0;
    margin: 0;
  }
  
  .header {
    grid-area: header;
  }
  
`
