import React, { useEffect, useRef, useState } from 'react'


import { ImageCircle } from '../../kanban/responsible_employee'

import { useDeletePostitComment, usePostComment, usePostPostitComment, useUpdatePostitComment } from './useComments'
import { useOutsideAlerter } from '../../kanban/page'
import { useToggle } from '../../kalkyle'

export const CommentComponent = ({ comment, author, timestamp }) => {

  return (
    <div className='flex flex-col after:border-b-[1px] after:border-gray-500 after:border-opacity-50 p-2'>
      <p className='mb-2 text-xl'>
        {comment}
      </p>
      <div className='flex justify-between font-bold text-gray-500'>
        <p>
          {author}
        </p>
        <p>
          {getTimeString(timestamp)}
        </p>
      </div>
    </div>
  )
}

const DeleteDialogue = ({ id, table, fkey }) => {
  const { open, close, isOpen, toggle } = useToggle(false)
  const mutate = useDeletePostitComment()

  const ref = useRef()

  useOutsideAlerter(ref, close)

  const handleDelete = async () => {
    await mutate.mutateAsync({
      id: id,
      table: table,
      key: fkey,
      fkey: fkey
    })
    close()
  }

  return (
    <div className="flex flex-col">
      <button onClick={toggle} className='bg-rose-600 px-3 sm:py-1 py-3 rounded-xl active:bg-red-400' >
        {mutate.isLoading ?
          <i className="fad fa-spinner-third text-xl animate-spin" />
          :
          <p>Slett</p>
        }
      </button>

      {isOpen && (
        <div ref={ref}
          className="pop-open absolute flex flex-col items-center justify-around p-4 transform translate-x-[10rem] inset-x-0 top-full z-20 shadow-paper-md rounded-xl bg-gradient-to-br from-blue-400 via-indigo-400 to-purple-500">
          <p className="text-indigo-100 font-bold text-center">Sikker på at du vil slette kommentaren?</p>
          <div className="flex justify-between font-bold">
            <button
              className="bg-red-500 sm:hover:bg-red-600 text-red-100 border-2 border-red-500 flex justify-center items-center rounded-full px-6 mr-2"
              onClick={handleDelete}>
              <i className="fas fa-trash m-2" /> Slett
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export const PostitComment = ({ comment, author, timestamp, color, commentId, canEdit, table, fkey }) => {
  const [peelValueRight, setPeelValueRight] = useState(0)
  const [peelValueLeft, setPeelValueLeft] = useState(0)
  const [myColor, setMyColor] = useState("")
  const [isEditing, setIsEditing] = useState(false)
  const ref = useRef(null)

  const { mutateAsync: mutateEdit, isError: updateError, error: updateErrorMsg, isLoading: updateLoading } = useUpdatePostitComment()


  useEffect(() => {
    if (comment === undefined) return
    const threshold = 15
    const commentLengthModulo = comment.length % threshold

    if (commentLengthModulo > threshold / 2) {
      setPeelValueRight(commentLengthModulo)
    } else {
      setPeelValueLeft(threshold - commentLengthModulo)
    }


  }, [])

  useEffect(() => {
    setMyColor(`bg-gradient-to-b from-${color}-300 to-${color}-500`)
  }, [color])

  useEffect(() => {
    setIsEditing(false)
  }, [comment])



  const handleClick = () => {
    if (!canEdit) return
    setIsEditing(true)
  }

  const updateComment = async (newComment) => {

    if (newComment === undefined) return
    await mutateEdit({
      table,
      id: commentId,
      comment: newComment,
      fkey: fkey
    })
    if (updateError) alert('Kunne ikke oppdatere.', updateErrorMsg)

  }


  if (isEditing) {
    return <PostPostitCommentComponent table={table} fkey={fkey} commentId={commentId} startActive={true} initialColor={color} initialContent={comment} isEditing={true} setIsEditing={setIsEditing} updateFunc={updateComment} />
  }


  return (
    <div
      ref={ref}
      onClick={handleClick}
      style={{
        rotate: ((peelValueRight < peelValueLeft ? peelValueLeft : -peelValueRight) / 15) + 'deg'
      }}
      className='w-full relative aspect-square'>


      <div className='absolute left-1/2 -translate-x-1/2 -top-14 sm:scale-90 md:scale-100 scale-75 '>
        <ImageCircle employee={author} />

      </div>
      <div style={{
        borderBottomRightRadius: (peelValueRight / 2) + 'rem ' + (peelValueRight / 2) + 'px',
        borderBottomLeftRadius: (peelValueLeft / 2) + 'rem ' + (peelValueLeft / 2) + 'px',
      }}
        className={`w-full h-full z-10 transition-all max-h-full p-2 py-8 ${myColor}`}>

        <AutoFittingText comment={comment}>


          <p className='sm:text-sm text-xs absolute bottom-1 right-1'>
            {getTimeString(timestamp)}
          </p>
          {/*           {canEdit && <div className='absolute -translate-y-3/4 top-0 flex justify-around p-1 w-full text-xl z-50'>
            <button className='text-gray-500 sm:hover:bg-gray-200 p-2 rounded-lg'>
              <i className="fas fa-comment-edit  cursor-pointer m-2" onClick={() => setIsEditing(true)}></i>

            </button>
            <DeleteDialogue table={table} id={commentId} />
          </div>} */}
        </AutoFittingText>


      </div>
      <div className='bg-[#00000054] blur-sm absolute inset-x-[3px] -bottom-1 h-8 -z-20'>

      </div>

    </div>
  )
}

const AutoFittingText = ({ comment, children }) => {
  const textDivRef = useRef(null)

  useEffect(() => {
    fitText()
  }, [comment])

  useEffect(() => {
    window.addEventListener('resize', fitText)

  }, [])

  const fitText = () => {
    if (!textDivRef.current) return
    textDivRef.current.style.visibility = 'invisible'

    textDivRef.current.style.fontSize = '10rem'
    while (textDivRef.current.scrollHeight > textDivRef.current.offsetHeight || textDivRef.current.scrollWidth > textDivRef.current.offsetWidth) {
      let currentSize = textDivRef.current.style.fontSize.replace('rem', '')
      if (currentSize < 1) break
      textDivRef.current.style.fontSize = currentSize - 0.1 * currentSize + 'rem'

    }
    textDivRef.current.style.visibility = 'visible'
  }
  return (

    <div ref={textDivRef} style={{
      fontSize: '10rem',
      visibility: 'hidden',
    }}
      className='flex flex-col w-full text-black h-full text-center items-center overflow-y-scroll justify-center'>



      <p className=''>
        {comment}
      </p>
      {children}

    </div>
  )
}


const getTimeString = (date) => {
  const myDate = new Date(date)
  const now = new Date()
  const seconds = 1000
  const minutes = seconds * 60
  const hours = minutes * 60



  const difference = now.getTime() - myDate.getTime()

  if (difference > 12 * hours) {
    return myDate.toLocaleTimeString('no-NO', { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' })
  } else if (difference > 60 * minutes) {
    const hrs = Math.floor(difference / hours)
    return `${hrs} time${hrs !== 1 ? 'r' : ''} siden`
  } else if (difference > 60 * seconds) {
    const mins = Math.floor(difference / minutes)
    return `${mins} minutt${mins !== 1 ? 'er' : ''} siden`
  } else if (difference > seconds) {
    const sec = Math.floor(difference / seconds)
    return `${sec} sekund${sec !== 1 ? 'er' : ''} siden`
  } else {
    return 'for et øyeblikk siden'
  }
}

const PostitColorPicker = ({ setColor, reference }) => {

  return (
    <div ref={reference} className='w-full h-full grid grid-cols-2 items-center justify-center shadow-2xl dark:bg-gray-700 rounded-lg gap-2 p-2 sm:p-4 aspect-square text-3xl'>
      <div className='bg-lime-300 bg-opacity-50 shadow-lg outline dark:outline-none outline-lime-400 dark:bg-gray-600 rounded-lg h-full w-full flex flex-col justify-center items-center text-lime-900 dark:text-lime-400 active:bg-lime-400 active:dark:bg-lime-500  cursor-pointer '
        onClick={() => setColor('lime')}
      >
        <i className="fas fa-comment-dots"></i>
        <p className='text-xs sm:text-sm'>Kommentar</p>
      </div>
      <div className=' bg-fuchsia-300 bg-opacity-50 shadow-lg outline dark:outline-none outline-fuchsia-400 dark:bg-gray-600 rounded-lg h-full w-full flex flex-col justify-center items-center text-fuchsia-900 dark:text-fuchsia-400 active:bg-fuchsia-400 active:dark:bg-fuchsia-500  cursor-pointer '
        onClick={() => setColor('fuchsia')}
      >
        <i className="fas fa-question-circle"></i>
        <p className='text-xs sm:text-sm'>Spørsmål</p>
      </div>
      <div className=' bg-yellow-300 bg-opacity-50 shadow-lg outline dark:outline-none outline-yellow-400 dark:bg-gray-600 rounded-lg h-full w-full col-span-2 flex flex-col justify-center items-center text-yellow-900 dark:text-yellow-400 active:bg-yellow-400 active:dark:bg-yellow-500  cursor-pointer '
        onClick={() => setColor('yellow')}
      >
        <i className="fas fa-exclamation-triangle"></i>
        <p className='text-xs sm:text-sm '>Obs!</p>
      </div>
    </div>
  )
}

export const PostPostitCommentComponent = ({ table, fkey, shouldResize, shouldBeSquare = true, showLoading, isEditing, setIsEditing, startActive = false, initialColor = "", initialContent = "", updateFunc, commentId }) => {
  const [isActive, setIsActive] = useState(startActive)
  const [comment, setComment] = useState(initialContent)
  const [color, setColor] = useState(initialColor)
  const { mutate, isError, isLoading } = usePostPostitComment()
  const ref = useRef()

  const handlePostComment = () => {
    if (isEditing) {
      updateFunc(comment)
      closeCommentSection()
    } else {

      mutate({
        table,
        fkey,
        comment,
        color
      })
      if (!isError) {
        console.log("success!")
        closeCommentSection()
      }
    }
  }
  useOutsideAlerter(ref, () => {
    if (setIsEditing) {
      setIsEditing(false)
    } else {
      setIsActive(false)
      setColor("")
      setComment("")
    }


  })

  const handleEnterPress = (e) => {
    if (e.key === 'Enter' && e.shiftKey == false) {
      e.preventDefault()
      handlePostComment()
    }
  }

  const closeCommentSection = () => {

    if (isEditing) {
      setIsEditing(false)
    } else {
      setComment("")
      setIsActive(false)
      setColor("")
    }
  }
  const handleTextChange = (e) => {
    setComment(e.target.value)
    if (!shouldResize) return
    e.target.style.height = 'inherit'
    e.target.style.height = `${e.target.scrollHeight}px`
  }

  if (!isActive) {
    return <button className={`hover:underline w-full bg-gray-100 dark:bg-gray-800 rounded-lg aspect-square`} onClick={() => setIsActive(true)}>Legg til kommentar...</button>
  }
  else if (color === "") {
    return <PostitColorPicker reference={ref} setColor={setColor} />
  }

  return (

    <div ref={ref} className={`px-2 relative ${shouldBeSquare && 'aspect-square'}`}>
      <div className='w-full h-full flex flex-col justify-end dark:bg-gray-800  p-[2px] rounded-b-xl shadow-lg'>

        <textarea value={comment} onChange={handleTextChange} onKeyDown={handleEnterPress} autoFocus className={`p-2 w-full grow resize-none text-lg text-black  
        ${color === "lime" && 'bg-gradient-to-b from-lime-300 to-lime-500'}
        ${color === "fuchsia" && 'bg-gradient-to-b from-fuchsia-300 to-fuchsia-500'}
        ${color === "yellow" && 'bg-gradient-to-b from-yellow-300 to-yellow-500'}
        `}> </textarea>
        <div className=' dark:bg-gray-800 rounded-b-xl gap-2 flex flex-row-reverse justify-between'>
          {/*           <button className='bg-rose-600 px-3 py-1 rounded-xl  active:bg-red-400 mx-2' onClick={() => {
            closeCommentSection()
          }}><p>Avbryt</p></button> */}


          {isLoading && showLoading &&
            <div className='absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2'>
              <i className="fad fa-spinner-third text-2xl animate-spin " />
            </div>
          }
          <button className='bg-emerald-600 px-3 py-1 rounded-xl active:bg-green-400 relative'
            onClick={() => handlePostComment()} >
            <p className={`${isLoading && 'invisible'}`}>
              Ok
            </p>

          </button>
          {commentId && <DeleteDialogue id={commentId} table={table} fkey={fkey} />}


        </div>
      </div>
    </div>

  )
}

export const PostCommentComponent = ({ table, fkey, shouldResize, shouldBeSquare, showLoading }) => {
  const [isActive, setIsActive] = useState(false)
  const [comment, setComment] = useState("")
  const { mutateAsync, isError, isLoading } = usePostComment()

  const handlePostComment = async () => {
    await mutateAsync({
      table,
      fkey,
      comment
    })
    if (!isError) {
      closeCommentSection()
    }
  }
  const closeCommentSection = () => {
    setComment("")
    setIsActive(false)
  }
  const handleTextChange = (e) => {
    setComment(e.target.value)
    if (!shouldResize) return
    e.target.style.height = 'inherit'
    e.target.style.height = `${e.target.scrollHeight}px`
  }
  if (!isActive) {
    return <button className={`hover:underline ${shouldBeSquare && 'aspect-square'}`} onClick={() => setIsActive(true)}>Legg til kommentar...</button>
  }

  return (

    <div className={`px-2 ${shouldBeSquare && 'aspect-square'}`}>

      <div className='w-full h-full flex flex-col justify-end dark:bg-gray-800 rounded-xl p-[2px]'>

        <textarea value={comment} onChange={handleTextChange} autoFocus className='p-2 w-full grow resize-none text-lg rounded-t-xl dark:bg-gray-700'> </textarea>
        <div className='dark:bg-gray-800 rounded-b-xl flex justify-end'>
          <button className='bg-rose-600 px-3 py-1 rounded-xl active:bg-red-400 mx-2' onClick={() => {
            closeCommentSection()
          }}><p>Avbryt</p></button>

          <button className='bg-emerald-600 px-3 py-1 rounded-xl active:bg-green-400 relative'
            onClick={() => handlePostComment()} >
            <p className={`${isLoading && 'invisible'}`}>
              Ok
            </p>
            {isLoading && showLoading &&
              <div className='absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2'>
                <i className="fad fa-spinner-third text-2xl animate-spin " />
              </div>
            }

          </button>


        </div>
      </div>
    </div>

  )
}