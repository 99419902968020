import { Link } from 'react-router-dom'
import { useQuery } from 'react-query'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { ImageCircle, ResponsibleEmployeeDropDown } from '../kanban/responsible_employee'
import { useAllResponsibleEmployees } from '../kanban/useResponsibleEmployee'
import { IndirectHourReporting, IndirectTimeEntry } from './indirect_hour_reporting'
import { CalendarComponent } from '../components/Calendar'
import { useState } from 'react'
import { useHome } from './useHome'
import { Image } from '../drawing/image'

dayjs.extend(utc)

const App = () => {
    const [selectedDate, setSelectedDate] = useState(null)
    const [showCalendar, setShowCalendar] = useState(false)
    const { data: home, refetch } = useHome(selectedDate)

    return (
        <div className="m-auto mb-40 max-w-7xl">
            <h1 className="flex items-center px-4 mb-4 text-3xl font-black text-green-400 rounded-lg transform skew-x-12 sm:text-7xl">
                HJEM
                <i className="ml-4 text-4xl fad fa-home" />
            </h1>

            <div className=" mb-8 border-4 border-gray-100 dark:border-gray-700 dark:bg-gray-800 sm:rounded-3xl">
                <div className="p-4 flex justify-between">
                    <div className=''>
                        <div onClick={() => setShowCalendar(p => !p)} className='select-none cursor-pointer'>
                            <p className="text-2xl font-bold text-pink-500"><i
                                className="m-auto fas fa-calendar-day" /> Dagens</p>
                            {selectedDate ?
                                <p className="">Ting du jobbet på {new Date(selectedDate).toLocaleDateString('no-NO', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}.</p>
                                :
                                <p>Ting du har jobbet på i dag.</p>
                            }
                        </div>
                        <CalendarComponent showCalendar={showCalendar} setSelectedDate={setSelectedDate} selectedDate={selectedDate} />
                    </div>
                    <IndirectHourReporting />
                </div>
                <div className="p-4 bg-gray-100 dark:bg-gray-800 sm:rounded-b-3xl">
                    {home?.today?.items?.length === 0 || home?.today?.indirect_time?.length === 0 && (
                        <div className="text-gray-500">Ingenting, enda.</div>
                    )}
                    {home?.today?.items?.map((item, index) => (
                        <div className="flex flex-row mt-2 align-center" key={index}>
                            <div className="overflow-hidden my-auto w-32 max-h-32 rounded-xl">
                                <img src={`/api/kanban/v1/media?item=${item.item_no}`} alt="" />
                            </div>
                            <div className="flex flex-col p-4">
                                <Link className="font-bold hover:underline"
                                    to={`/route/${item?.order_no}/${item?.line_no}/${item.routing_no}`}>
                                    {item.description}
                                </Link>
                                <div className="flex">
                                    <p><i className="fas fa-clock" /> {item.hours.toFixed(1)}</p>
                                    <p className="ml-4"><i className="fas fa-cubes" /> {item.parts}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                    {home?.today?.indirect_time?.map((entry, index) => (
                        <IndirectTimeEntry entry={entry} key={index} />
                    ))}
                </div>
            </div>

            <LastVisitedLister visits={home?.visited} />

            {home?.production && (
                <div>
                    <div className="flex justify-center items-center pt-8">
                        <div className="w-full h-1 bg-gradient-to-r from-purple-400 to-green-500" />
                        <h2 className="px-3 py-2 text-3xl font-bold text-green-500 border-2 border-green-500 transform skew-x-12">
                            Prosjekter
                        </h2>
                    </div>
                    <div className="grid my-4"
                        style={{ gridGap: '1rem', gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))' }}>
                        {home?.production?.map(prod => (
                            <div className="flex flex-col p-4 bg-gray-100 rounded-xl dark:bg-gray-800">
                                <Link className="hover:text-green-400 hover:underline"
                                    to={`/production/${prod.no}`}>

                                    <p className="font-bold">{prod.daily_speech}</p>
                                    <p>{prod.no} <span className="mx-2 text-xl font-bold">·</span> {prod.sales_order_no}
                                    </p>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            )}


            <div className="flex justify-center items-center pt-8 pb-2">
                <div className="w-full h-1 bg-gradient-to-r from-purple-400 to-pink-500" />
                <h2 className="px-3 py-2 text-3xl font-bold text-pink-500 border-2 border-pink-500 transform skew-x-12">
                    Arbeidssenter
                </h2>
            </div>
            <WorkCenterLister />
        </div>
    )
}


const LastVisitedLister = ({ visits }) => {
    if (!visits || visits.length === 0) return null

    return (
        <div>
            <div className="flex justify-center items-center">
                <div className="w-full h-1 bg-gradient-to-r from-purple-400 to-yellow-500" />
                <h2 className="px-3 py-2 text-3xl font-bold text-yellow-500 border-2 border-yellow-500 transform skew-x-12">
                    Historikk
                </h2>
            </div>
            <div className="overflow-x-scroll whitespace-nowrap">
                {visits?.map(visited => (
                    <Visit visit={visited} />
                ))}
            </div>
        </div>
    )
}

const Visit = ({ visit }) => {
    const { order_no, line_no, routing_no, item_no } = visit

    const time = dayjs(visit.timestamp).utc(false)
    const now = dayjs().utc(false)

    const diff = now.diff(time, 'second')

    const hours = diff / 3600
    const days = hours / 24

    return (
        <div key={visit.item_no}
            className="inline-block overflow-hidden mx-3 my-2 bg-yellow-500 bg-opacity-5 rounded-xl border-4 border-yellow-400 border-opacity-10 dark:border-gray-700 dark:bg-gray-800">
            <div className="flex flex-col justify-center">
                <Image no={item_no} className="m-auto h-32" />
                <div className="p-4 rounded-b-xl dark:bg-gray-800">
                    <Link to={routeURL(order_no, line_no, routing_no)} className="text-yellow-800 hover:underline hover:text-yellow-400 dark:text-gray-100">
                        <p className="font-medium text-center">{item_no}</p>
                    </Link>
                    <p className="font-medium text-yellow-900 dark:text-gray-300">
                        <i className="mr-1 fad fa-history" />
                        {hours < 1 ? 'Nylig besøkt' :
                            days >= 1 ? `${days.toFixed(0)} dager siden` : `${hours.toFixed()} timer siden`
                        }</p>
                </div>
            </div>
        </div>
    )
}

const useWorkCenters = () => {
    const { data, isError, isLoading, error } = useQuery('work-centers', () =>
        fetch('/api/kanban/v1/home/work-centers').then(res => res.json())
    )

    const withMachineCenters = data?.filter?.(wc => wc.machines && wc.machines.length > 0)
    const withoutMachineCenters = data?.filter?.(wc => !wc.machines || wc.machines.length === 0)

    return {
        withMachineCenters,
        withoutMachineCenters,
        isError, isLoading, error
    }

}

export const workCenterUrl = (wc, mc) => `/kanban/work-center/${encodeURIComponent(wc)}/${encodeURIComponent(mc)}`
export const routeURL = (order, line, routing) => `/route/${encodeURIComponent(order)}/${encodeURIComponent(line)}/${encodeURIComponent(routing)}`

const WorkCenterLister = () => {
    const { withMachineCenters, withoutMachineCenters } = useWorkCenters()
    const { data: employees, isLoading, isFetching } = useAllResponsibleEmployees()
    return (
        <div className="px-2 sm:px-0">
            {withMachineCenters?.map((wc, index) => (
                <div key={index}>
                    <div className="flex py-2">
                        <Link to={workCenterUrl(wc.no, wc.no)}>
                            <p className="text-xl font-bold text-gray-400 hover:underline">{wc.name}</p>
                        </Link>
                    </div>
                    <div className="grid mt-1"
                        style={{ gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', gridGap: '1rem' }}>
                        {wc?.machines?.map((mc, index) => (
                            <div className='relative mt-10 flex flex-col'>
                                <div className='absolute self-center -top-12'>
                                    <ImageCircle employee={employees?.data?.find(e => e.work_center_no === wc.no && e.machine_center_no === mc.no)} isLoading={isLoading} />
                                    <ResponsibleEmployeeDropDown center={wc.no} machine={mc.no} />
                                </div>
                                <Center key={index} name={mc.name} hours={mc.hours} to={workCenterUrl(wc.no, mc.no)} />
                            </div>
                        ))}
                    </div>
                    <div className="my-8 w-full bg-gray-100 dark:bg-gray-700" style={{ height: 2 }} />
                </div>
            ))}
            <div className="grid"
                style={{ gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', gridGap: '1rem' }}>
                {withoutMachineCenters?.map((wc, index) => (
                    <div className='relative mt-10 flex flex-col'>
                        <div className='absolute self-center -top-12'>
                            <ImageCircle employee={employees?.data?.find(e => e.work_center_no === wc.no)} isLoading={isLoading} />
                            <ResponsibleEmployeeDropDown center={wc.no} machine={wc.no} />
                        </div>
                        <Center key={index} name={wc.name} hours={wc.hours} to={workCenterUrl(wc.no, wc.no)} />
                    </div>
                ))}
            </div>
        </div>
    )
}

const Center = ({ to, name, hours, className }) => {
    return (
        <div className="px-4 pb-4 pt-8 bg-pink-500 border-pink-500 homepage-border  ">
            <Link className={`font-bold text-pink-900 dark:text-gray-100 hover:text-pink-400 hover:underline text-center ${className} `}
                to={to}>
                <p>
                    {name}
                </p>
            </Link>
            <Hours hours={hours} />
        </div>
    )
}

const Hours = ({ hours, className }) => (
    <div className={`flex justify-between ${className}`}>
        <Hour hours={hours?.active ?? 0}>
            <i className="text-sm text-red-500 fad fa-fire" />
        </Hour>
        <Hour hours={hours?.available ?? 0}>
            <i className="text-sm text-green-500 fad fa-check" />
        </Hour>
        <Hour hours={hours?.planned ?? 0}>
            <i className="text-sm text-yellow-500 fad fa-calendar-day" />
        </Hour>
    </div>
)

const Hour = ({ hours, children }) => (
    <p className="flex flex-col items-center">
        <span>
            {children}
        </span>
        <span className="font-bold text-pink-900 dark:text-gray-400">{hours.toFixed(0)}t</span>
    </p>
)


export default App
