import { useKalkyleCacheInvalidate } from '../operation/thought'
import { useState } from 'react'
import axios from 'axios'
import { toast } from 'react-hot-toast'

export const useFileUpload = (uri, callbackFn) => {
    const { invalidateThoughtItems } = useKalkyleCacheInvalidate()
    const [uploading, setUploading] = useState([])

    const appendFiles = async (files) => {
        for (const file of files) {
            await setUploading(prev => [...prev, {
                progress: 0,
                objectURL: URL.createObjectURL(file),
                file: file
            }])
        }

        for (const file of files) {
            try {
                await handler(file)
                await setUploading(prev => prev.filter(p => p.file.name !== file.name))
            } catch (err) {
                console.log('err ', err)
            }
        }
    }

    const handler = async (file) => {
        const data = new FormData()
        data.append('fileupload', file)

        try {
            const res = await axios.post(uri, data, {
                onUploadProgress: progressEvent => {
                    const percentage = (progressEvent.loaded / progressEvent.total * 100).toFixed(0)
                    setUploading(prev => {
                        return prev.map(p => {
                            if (p.file.name !== file.name) return p
                            return Object.assign({}, p, {
                                progress: percentage
                            })
                        })
                    })
                }
            })
            if (callbackFn) await callbackFn(res.data)
        } catch (err) {
            toast.error(`kunne ikke laste opp bilde`)
            console.log('err: ', err)
        }

        await invalidateThoughtItems()
    }

    return {
        appendFiles, uploading
    }
}