import { UploadingAsBuiltImage, useAsBuiltUpload } from './upload'
import { Link } from 'react-router-dom'
import { useRouteParams } from '../route/page'
import { useRef } from 'react'

export const AsBuiltImages = ({ records }) => {
    const { order, line, routing } = useRouteParams()
    const ref = useRef(null)

    const { uploading, appendFiles } = useAsBuiltUpload(order, line, routing)

    const onClick = () => {
        ref.current.click()
    }

    return (
        <div>
            <div className="flex items-center overflow-scroll space-x-4 p-4">
                <button onClick={onClick}
                        className="flex flex-col justify-center items-center text-green-500 px-3 py-2 rounded-xl">
                    <i className="fad fa-plus-circle text-xl"/>
                    <p className="w-full">
                        Fremgangsbilde
                    </p>
                </button>
                {uploading?.map(file => (
                    <UploadingAsBuiltImage file={file} key={file.name} />
                ))}
                {records?.map(image => (
                    <AsBuiltImage key={image.ID} image={image}/>
                ))}
            </div>

            <input style={{ display: 'none' }}
                   type="file"
                   accept="image/png, image/jpeg"
                   multiple
                   ref={ref}
                   onChange={e => {
                       const arr = []
                       for (let i = 0; i < (e?.target?.files?.length ?? 0); i++) {
                           if (e.target?.files?.[i]) {
                               arr.push(e.target.files[i])
                           }
                       }
                       appendFiles(arr)
                   }}/>
        </div>
    )
}

const AsBuiltImage = ({ image, ...props }) => {
    const url = `/api/kanban/v1/drawing/as-built/download?id=${image.ID}`

    return (
        <Link to={`/as-built/${image.ID}`}>
            <div
                className="relative group flex flex-col items-center h-32 w-32 hover:ring-2 ring-offset-2 ring-green-500 active:ring-4 ring-offset-white dark:ring-offset-gray-800 rounded-xl"
                style={{ minWidth: '8rem' }}>
                <img className={`rounded-xl inline-block w-full h-full object-cover`}
                     alt="loading"
                     src={image.objectURL ? image.objectURL : url}
                     {...props}
                     onError={(e) => e.target.style.display = 'none'}
                />
            </div>
        </Link>
    )
}
