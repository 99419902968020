import * as bowser from 'bowser'
import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import styled, { css } from 'styled-components'
import { useUserContext } from '../../navigation/routing'
import { useState } from 'react'
import { Link } from 'react-router-dom'

dayjs.extend(weekday)

const User = () => {
    const { user, setCompany, uploading, uploadingError } = useUserContext()
    if (uploading) return <p>laster...</p>

    console.log("user: ", user)

    const handleSetCompany = async company => {
        await setCompany(company)
        window.location.reload()
    }

    return (
        <Container>
            <Paper>
                <div className="flex justify-between items-center flex-wrap">
                    <div className="">
                        <h1 className="font-bold">{user?.name}</h1>
                        <Subtitle>{user?.email}</Subtitle>
                    </div>
                    <SignOut />
                </div>
                <div className="mt-4">
                    <h2 className="text-lg sm:text-xl font-bold text-yellow-500 p-2"><i className="fad fa-key"/> Sikkerhetsnøkler</h2>
                    <h3 className="text-warm-500">Den beste måten du kan logge inn på. Legg til så mange du vil, men hold styr på dem.</h3>
                    <div className="my-4 bg-depth-5 rounded-2xl overflow-hidden">
                        {user?.credentials?.map(credential => (
                            <div key={credential.id} className="p-4 hover:bg-depth-4">
                                <div>{credential.keyname}</div>
                            </div>
                        ))}
                    </div>
                    <Link to="/user/webauthn">
                        <button className="bg-depth-6 dark:bg-depth-5 p-4 my-4 rounded-lg hover:bg-yellow-500 hover:text-white transition-colors">
                            Legg til nøkkel
                        </button>
                    </Link>
                </div>

                <div className="mt-8">
                    <h2 className="text-lg sm:text-xl font-bold text-blue-500 p-2"><i className="fad fa-building"/> Selskaper</h2>
                    <div className="flex flex-col overflow-hidden rounded-xl dark:bg-depth-5">
                        {user?.company?.available.map(company => (
                            <ToggleButton className="p-4 hover:bg-depth-5 dark:hover:bg-depth-4" activeClassName="text-blue-500" inactiveClassName="" onClick={() => handleSetCompany(company)} key={company} active={company === user.company.selected}>
                                {company === user.company.selected && (
                                    <i className="fas fa-check-circle"/>)} {company}
                            </ToggleButton>
                        ))}
                    </div>
                </div>
                <div className="mt-8">
                    <h2 className="text-lg sm:text-xl font-bold text-red-500 p-2"><i className="fad fa-sign-in-alt"/> Innlogginger</h2>
                    <HistoryContainer>
                        {user?.login_history?.map((confirmation, i) => (
                            <LoginLog key={confirmation.timestamp} log={confirmation}/>
                        ))}
                    </HistoryContainer>
                </div>
            </Paper>
        </Container>
    )
}

const SignOut = () => {
    const [loading, setLoading] = useState(false)

    const signOut = async () => {
        setLoading(true)
        const res = await fetch(`/api/kanban/v1/login`, {
            method: "DELETE"
        })

        if (res.status !== 202) {
            const txt = await res.text()
            window.alert(`Det skjedde noe feil: ${txt}`)
            setLoading(false)
            return
        }

        window.location.reload()
    }

    return (
        <button onClick={signOut} disabled={loading} className="flex flex-col justify-center items-center bg-red-500 hover:bg-red-600 text-white p-2 rounded-lg">
            <i className="fas fa-door-open"/>
            Logg Ut
        </button>
    )

}

const ToggleButton = ({ className, activeClassName, inactiveClassName, active, children, ...props }) => {
    return <button className={`${className} ${active ? activeClassName : inactiveClassName}`} {...props}>{children}</button>
}

const CompanyButton = styled.button`
  background-color: rgba(var(--rgb-medium-gray), 0.3);
  padding: 1rem;
  
  text-align: left;

  ${props => props.selected && css`
    color: var(--green);
  `};
  
  :hover {
    background-color: rgba(var(--rgb-medium-gray), 0.5);
  }
  
`

const HeaderContainer = styled.div`
  padding: 0 0 2rem 0;
`

const Container = styled.div`
  padding: 1rem;
  max-width: 40rem;
  margin: auto;
`

const Subtitle = styled.h3`
  color: var(--depth-1);
`

const SectionTitle = styled.h3`
  text-align: center;
`

const LoginLog = ({ log }) => {
    const browser = bowser.getParser(log.device)

    return (
        <div>
            <p><b>{browser.getOSName()} {browser.getBrowserName()}</b></p>
            <p>{dayjs(log.timestamp).format('DD MMMM YYYY')}</p>
            <p>{log.ipAddress}</p>
        </div>
    )
}

const HistoryContainer = styled.div`
  display: grid;
  grid-gap: 1rem;
`

const Paper = styled.div`
  background-color: var(--paper-1);
  box-shadow: var(--paper-shadow);
  padding: 1rem;
  border-radius: 1rem;
`

export default User
