import { useKalkyleCacheInvalidate } from './operation/thought'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import { useDrop2 } from './item/child'
import { ActualPdfViewer } from './drawing'
import { useChartDimensions } from '../kanban/page'
import { useFileUpload } from './item/fileupload'
import { useWhatsHovering } from './page'

export const Add = () => {
    const { addMutation } = useAddItemMutation()
    const addPublishedItemMutation = useAddPublishedItemMutation()
    const whatsHovering = useWhatsHovering()

    const params = useParams()
    const {
        uploading,
        appendFiles
    } = useFileUpload(`/api/kanban/v1/kalkyle/thought-item/add-from-file?kalkyle_id=${encodeURIComponent(params.id ?? '')}`,
        () => {
            console.log('done')
        })

    const { isHovering, funcs } = useDrop2((event) => {
        const text = event.dataTransfer.getData('text')
        const payload = JSON.parse(text)

        switch (whatsHovering) {
            case 'braindump':
                addMutation.mutate({ id: payload.id })
        }

    }, {}, appendFiles)

    return (
        <div>
            {uploading && (
                <DrawingUploadingLister list={uploading}/>
            )}
            <div {...funcs}
                 className={`
                py-4
                transition-all
                rounded-xl
                text-gray-100 dark:text-gray-700
                flex flex-col justify-center items-center space-y-1
                border-2 border-dashed 
                ${isHovering ? 'bg-gray-100 dark:bg-green-500/10 border-green-500' : "border-gray-400 dark:border-gray-700"}
        `}>
                {(addMutation.isLoading || addPublishedItemMutation.isLoading) ? (

                    <i className="fad fa-spinner-third animate-spin text-xl"/>
                ) : (
                    <>
                        <i className={`fa-duotone fa-arrow-down-to-bracket text-xl text-gray-400 `}/>
                    </>
                )}
            </div>
        </div>
    )
}

const DrawingUploadingLister = ({ list }) => {
    return (
        <div className="grid" style={{ gridGap: "1rem", gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))"}}>
            {list?.map((uploading, index) => (
               <DrawingUploading uploading={uploading} key={index} />
            ))}
        </div>
    )
}

const DrawingUploading = ({ uploading }) => {
    const [ref, dimension] = useChartDimensions()
    return (
        <div className="flex flex-col dark:bg-gray-800 p-4 rounded-xl" ref={ref}>
            <ActualPdfViewer url={uploading.objectURL} isLoading={false} dimensions={dimension}/>
            <div className="flex mt-2">
                <div>
                    <i className="fad fa-spinner-third animate-spin text-green-500"/>
                </div>
                <p className="ml-1">{uploading.progress}%</p>
            </div>
            <p>{uploading.file.name}</p>
            <p>{uploading.file.type}</p>
        </div>
    )
}

export const useAddPublishedItemMutation = () => {
    const { id, childID } = useParams()
    const { invalidateThoughtItems } = useKalkyleCacheInvalidate()

    const handle = (materialNo) =>
        fetch(`/api/kanban/v1/kalkyle/published-item/copy?kalkyle_id=${id}&parent_id=${encodeURIComponent(childID ?? '')}&item_no=${encodeURIComponent(materialNo)}`, {
            method: 'POST'
        }).then(async res => {
            if (res.status !== 202) throw new Error(await res.text())
        })

    return useMutation(handle, {
        onSuccess: async () => {
            await invalidateThoughtItems()
            toast.success('Materiale lagt til!')
        },
        onError: err => {
            toast.error('Error: ' + err)
            console.error(`[AddMaterial] failed: ${err}`)
        }
    })
}


const useAddItemMutation = () => {
    useKalkyleCacheInvalidate()
    const client = useQueryClient()
    const { id: kalkyleID } = useParams()

    const handleAddItem = ({ id }) =>
        fetch(`/api/kanban/v1/kalkyle/thought-item/add?id=${id}`, {
            method: 'PUT'
        }).then(async res => {
            if (res.status !== 202) throw new Error(await res.text())
        })

    const addMutation = useMutation(handleAddItem, {
        onSuccess: async data => {
            await client.invalidateQueries(['braindumps', kalkyleID])
            await client.invalidateQueries(['thought-items', kalkyleID])
        }
    })

    return {
        addMutation
    }
}