import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { useRef } from 'react'
import { DurationMenu, useThoughtOperationDurationMutation } from './duration'
import { WorkCenterMenuKalkyle } from './work_center'
import { OperationDescription, useThoughtOperationDescriptionMutation } from './description'
import { useToggle } from '../index'
import { useOutsideAlerter } from '../../kanban/page'
import { toast } from 'react-hot-toast'

const ThoughtOperation = ({ operation, lastInLine }) => {
    const { id, description, center, full_price } = operation
    const mutation = useThoughtOperationDurationMutation(id)
    const descriptionMutation = useThoughtOperationDescriptionMutation(id)

    return (
        <div
            className={`relative pl-8 ${!lastInLine && 'after:absolute after:top-[calc(1.875rem+1px)] after:bottom-0 after:left-[0.6875rem] after:w-[2px] after:bg-slate-200 dark:after:bg-slate-200/5'}`}>
            <OperationMenu id={id}/>
            <div className="flex flex-col">
                <OperationDescription description={description} mutation={descriptionMutation}/>
                <div className="flex flex-wrap justify-start w-full text-left">
                    <DurationMenu id={id}
                                  runTime={operation.run_time}
                                  setupTime={operation.setup_time}
                                  fullDuration={operation.full_duration}
                                  mutation={mutation}/>
                    <WorkCenterMenuKalkyle operation={operation} />
                    {full_price > 0 && (
                        <p className="z-0 ml-2 font-medium text-left text-green-500">{full_price.toFixed(0)} kr</p>
                    )}
                </div>
            </div>
        </div>
    )
}

const useMoveOperationMutation = (id) => {
    const { invalidateThoughtItems } = useKalkyleCacheInvalidate()

    const handle = (direction) =>
        fetch(`/api/kanban/v1/kalkyle/thought-operation/move?id=${id}&direction=${direction}`, {
            method: 'PUT',
        }).then(async res => {
            if (res.status !== 202) throw new Error(await res.text())
        })

    return useMutation(handle, {
        onSuccess: async () => {
            await invalidateThoughtItems()
            toast.success('Operasjonen ble flytta!')
        },
        onError: err => {
            toast.error('Kunne ikke flytte operasjonen: ' + err)
            console.error(`[MoveOperation] failed: ${err}`)
        }
    })
}

const OperationMenu = ({ id }) => {
    const ref = useRef()
    const { isOpen, close, toggle, open } = useToggle(false)

    const removeMutation = useRemoveOperationMutation(id)
    const moveMutation = useMoveOperationMutation(id)

    const handleRemoveMutation = () => {
        close()
        removeMutation.mutate()
    }

    const handleMoveUp = () => {
        close()
        moveMutation.mutate("UP")
    }

    const handleMoveDown = () => {
        close()
        moveMutation.mutate("DOWN")
    }

    const isLoading = (removeMutation.isLoading || moveMutation.isLoading)

    useOutsideAlerter(ref, close)

    return (
        <div>
            <button onClick={toggle}
                    className={`absolute left-0 
                        flex items-center justify-center 
                        w-[calc(1.375rem+1px)] h-[calc(1.375rem+1px)] 
                        font-bold rounded-full shadow-inner border-2
                        ${isOpen ? 'bg-pink-500/50 border-pink-500 text-pink-900 dark:text-pink-100' : 'hover:bg-purple-200/50 bg-stone-200/50 dark:bg-gray-600/50 border-stone-200 dark:border-gray-600 hover:border-purple-200'}
                    `}>
                {isLoading ? (
                    <i className="fad fa-spinner-third animate-spin text-sm text-green-500"/>
                ) : (
                    <>
                        {isOpen && (
                            <i className="fad fa-check text-sm"/>
                        )}
                    </>
                )}
            </button>
            {isOpen && (
                <div className="flex absolute left-0 top-full right-0 z-20">
                    <div
                        ref={ref}
                        className="flex space-x-2 bg-gradient-to-bl from-purple-300 to-pink-500 p-4 rounded-xl pop-open">
                        <div className="flex flex-col space-y-1">
                            <button
                                onClick={handleMoveUp}
                                className="flex flex-col bg-pink-100/50 p-4 rounded-lg text-pink-900 hover:bg-pink-100/100">
                                <i className="fad fa-chevron-up"/>
                            </button>
                            <button
                                onClick={handleMoveDown}
                                className="flex flex-col bg-pink-100/50 p-4 rounded-lg text-pink-900 hover:bg-pink-100/100">
                                <i className="fad fa-chevron-down"/>
                            </button>
                        </div>
                        <button
                            onClick={handleRemoveMutation}
                            className="flex flex-col justify-center items-center bg-pink-100/50 p-4 rounded-lg hover:bg-red-200/100 text-pink-900 hover:text-red-600">
                            <i className="fad fa-trash-alt "/>
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}

export const useRemoveOperationMutation = (opID) => {
    const { invalidateThoughtItems } = useKalkyleCacheInvalidate()

    const handle = () =>
        fetch(`/api/kanban/v1/kalkyle/thought-operation/remove?id=${encodeURIComponent(opID)}`, {
            method: 'DELETE'
        }).then(async res => {
            if (res.status !== 202) throw new Error(await res.text())
        })

    return useMutation(handle, {
        onSuccess: async () => {
            await invalidateThoughtItems()
            toast.success('Fjernet!')
        },
        onError: err => {
            toast.error('noe gikk galt:' + err)
            console.error(`[RemoveOperation] failed: ${err}`)
        }
    })
}

export const ThoughtOperationsLister = ({operations }) => {
    return (
        <ol className="relative space-y-2 mx-4">
            {operations?.map((op, index) => (
                <ThoughtOperation key={index} operation={op} lastInLine={index + 1 === operations?.length}/>
            ))}
        </ol>
    )
}

export const useKalkyleCacheInvalidate = () => {
    const { id, childID } = useParams()
    const client = useQueryClient()

    const invalidateThoughtItems = async () => {
        await client.invalidateQueries(['thought-items', id])
        if (childID) {
            await client.invalidateQueries(['kalkyle', 'thought-item', childID])
        }
    }

    const invalidateBrainDumps = () =>
        client.invalidateQueries(['braindumps', id])

    return {
        invalidateThoughtItems,
        invalidateBrainDumps
    }
}

const useReorderMutation = () => {
    const { id: kalkyleID } = useParams()
    const client = useQueryClient()

    const handleReorder = ({ id, nextID, prevID }) =>
        fetch(`/api/kanban/v1/kalkyle/thought-operation/reorder?id=${id}&prev_id=${prevID}&next_id=${nextID}`, {
            method: 'PUT'
        })

    const reorderMutation = useMutation(handleReorder, {
        onSuccess: async data => {
            console.log('data: ', data)
            await client.invalidateQueries(['thought-items', kalkyleID])
        }
    })

    return {
        reorderMutation
    }
}

