import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Viewer from '../Search/Viewer'
import leopard from '../../assets/leopard.jpg'


const ItemInfoPage = () => {
    const { articleno } = useParams();
    const { chargeno } = useParams();
    const [itemInfo, setItemInfo] = useState();
    const [pictureLink, setPictureLink] = useState();
    const [showViewer, setShowViewer] = useState(false)
    const [qrBytes, setQrBytes] = useState()
    document.onkeydown = checkKey;

    function checkKey(e) {
        e = e || window.event;
        if (e.keyCode == '27' && showViewer == true) {
            setShowViewer(false)
        }
    }

    useEffect(() => {
        articleno && getInfo({ artNo: articleno })
        
    }, [articleno])

    const getInfo = ({ artNo }) => {
        if (artNo === undefined) {
            return
        }
        fetch(`/api/kanban/v1/kalkyle/materials/search/one?article_number=${artNo}`)
            .then((response) => response.json())
            .then((data) => setItemInfo(data));


    }
    
    return (
        <div className="flex flex-col align-middle p-2 m-auto ">
            <Link to="/search">
                <div className="my-2 rounded-lg  h-10 w-44 bg-gray-700 flex justify-center items-center"><p className="font-bold border-b-2 w-full text-center mx-2">Tilbake til søk</p></div>
            </Link>
            {itemInfo && BasicInfo(itemInfo, { chargeno, pictureLink, showViewer, setShowViewer, setQrBytes, qrBytes })}
            {itemInfo && OtherCharges(itemInfo, {chargeno})}
        </div>
    )
}

const BasicInfo = (props, { chargeno, pictureLink, showViewer, setShowViewer, setQrBytes, qrBytes }) => {
    return (
        <Card>
            {/* <h1 className="text-3xl mb-4">Informasjon</h1> */}
            <div className="flex justify-between max-h-80">
                <div className="flex flex-col justify-between">
                    <div>
                        <h1 className="text-2xl font-semibold mb-4">{props.description}</h1>
                        <p className="text-xl my-2">Artikkelnummer: {props.no}</p>
                        <p className="text-xl mt-2">Charge: {chargeno}</p>

                    </div>
                    {showQr(props, { setQrBytes, qrBytes, chargeno })}

                </div>

                <span className="w-1/3 max-h-full overflow-hidden rounded-lg">
                    <button onClick={() => {
                        setShowViewer(!showViewer)
                        console.log(showViewer)
                    }}>
                        {(pictureLink !== undefined) ?
                            <img className="object-cover max-h-full max-w-full" src={pictureLink} alt="Bilde" />
                            :
                            <img className="object-cover max-h-full max-w-full" src={leopard} alt="Bilde" />
                        }

                    </button>
                </span>
            </div>

            {showViewer ?
                <Viewer className="h-3/6" imageLink={pictureLink !== undefined ? pictureLink : leopard} closeButton={() => (setShowViewer(!showViewer))} >

                </Viewer>
                :
                null
            }

        </Card>

    )
}

const showQr = (props, { setQrBytes, qrBytes, chargeno }) => {
    const getBytes = ({ setQrBytes }) => (
        fetch(`/api/kanban/v1/kalkyle/materials/search/getqr?article_number=${props.no}&charge_number=${chargeno}`)
            .then((response) => response.json())
            .then((data) => setQrBytes(data.qrimage))
    )

    return (
        <>
            {qrBytes && (
                <Viewer imageLink={`data:image/jpeg;base64,${qrBytes}`} closeButton={() => (setQrBytes(null))} >

                </Viewer>
            )}
            <span className="">
                <button className="font-bold text-lg hover:bg-gray-500 border-b-2"
                    onClick={() => getBytes({ setQrBytes })}
                >
                    Print QR
                </button>
            </span>
        </>
    )
}

const OtherCharges = (props, {chargeno}) => {
    if (props.inventory.length < 2) {
        return null
    }
    

    return (

        <div className="">

            <Card className="overflow-hidden">
                <h1 className="text-2xl max-h-80">Fler med samme artikkelnummer:</h1>
                {
                    props.inventory && (
                        props.inventory.map((inv) => 
                            
                            <div key={inv.stock_quantity} className="p-1 my-2">

                                <Link
                                    className="text-left text-gray-000 w-full text-lg hover:bg-gray-500 border-b-2 "
                                    to={`/item/${props.no}/${inv.stock_quantity}`}
                                >Charge: {inv.stock_quantity}</Link>
                            </div>
                        ))}
            </Card>
        </div>

    )
}

const Card = (props) => {
    return (
        <div className="w-full rounded-lg bg-gray-100 dark:bg-gray-700 p-4 my-2 shadow-2xl overflow-hidden">
            {props.children}
        </div>
    )
}


export default ItemInfoPage;