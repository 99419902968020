import React, { useEffect, useRef, useState } from 'react'
import { TimeSelector } from '../kanban/operation'
import { useOutsideAlerter } from '../kanban/page'
import { useAddIndirectTime, useRemoveIndirectTime } from './useIndirectTime'
import { useToggle } from '../kalkyle'

export const IndirectHourReporting = () => {
    const [show, setShow] = useState(false)

    return (
        <span className="relative touch-manipulation">
            <button onClick={() => setShow(!show)} className="sm:hover:bg-gray-700 px-3 py-2 rounded-xl">
                <i className="fad fa-stopwatch text-2xl"/>
            </button>
            <IndirectTimeForm show={show} setShow={setShow} className={'right-0'}/>
        </span>
    )
}

const IndirectTimeForm = ({ show, setShow, className, initialDesc, initialTime, id }) => {
    const [desc, setDescription] = useState('')
    const [formError, setFormError] = useState([])
    const resetFunc = useRef(null)

    const mutation = useAddIndirectTime()

    useEffect(() => {
        if (initialDesc) {
            setDescription(initialDesc)
        }
    }, [])

    const submitForm = async (hours, minutes) => {
        const timeSpent = hours + (minutes / 60)
        await mutation.mutateAsync({ desc, timeSpent, id })

        if (mutation.isError) {
            setFormError([`${mutation.error}. Prøv igjen senere.`])
        } else {
            if (!id) {
                setDescription('')
                resetFunc.current()
                setDescription('')
            }
            setShow(false)
        }
    }

    const buttonIsClicked = (hours, minutes) => {
        let isError = false
        setFormError([])
        if (!desc) {
            isError = true
            setFormError(p => [...p, 'Beskrivelsen kan ikke være tom.'])
        }
        if (hours === 0 && minutes === 0) {
            isError = true
            setFormError(p => [...p, 'Tiden kan ikke være 0.'])
        }
        if (desc.length < 5) {
            isError = true
            setFormError(p => [...p, 'Beskrivelsen må være lenger enn 5 tegn.'])
        }
        if (desc.length > 500) {
            isError = true
            setFormError(p => [...p, 'Beskrivelsen må være kortere enn 500 tegn.'])
        }
        if (isError) return

        submitForm(hours, minutes)
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setFormError([])
        }, 5000)

        return () => clearTimeout(timer)
    }, [formError])

    const handleTextareaChange = (e) => {
        setDescription(e.target.value)
        e.target.style.height = 'inherit'
        e.target.style.height = `${e.target.scrollHeight}px`
        if (desc.length > 500) {
            e.target.style.backgroundColor = 'red'
        } else {
            e.target.style.backgroundColor = 'white'
        }
    }
    return (
        <TimeSelector
            buttonClick={buttonIsClicked}
            show={show} setShow={setShow}
            buttonText="Sånn, det stemmer bedre."
            className={className}
            childrenBeforeButton={true}
            resetFunc={resetFunc}
            isLoading={mutation.isLoading}
            initialTime={initialTime}
        >
            <div className="py-2 col-span-6">
                <h1 className="text-center font-medium mb-2">Hva gjorde du?</h1>
                <textarea
                    placeholder="Vaska golvet og børi ved..."
                    className="w-full text-black rounded-lg px-3 py-2 placeholder-indigo-300"
                    onChange={handleTextareaChange}
                    value={desc}
                    spellCheck={false}
                >
                </textarea>
                <div className="col-span-6 font-bold text-red-200">
                    {mutation.isError && <p>{mutation.error.message} {mutation.error.response.data}</p>}
                    {formError && formError.map((e, id) => <p key={id} className="">{e}</p>)}
                </div>
            </div>
        </TimeSelector>
    )
}

export const IndirectTimeEntry = ({ entry }) => {
    const [editMode, setEditMode] = useState(false)

    const handleEdit = () => {
        setEditMode(true)
    }

    if (entry === undefined) return null

    return (
        <div className={`flex flex-row mt-2 align-center relative`}>
            <div className="flex justify-between  items-center w-full">
                <button className="flex flex-col p-4 text-left sm:hover:underline" onClick={() => handleEdit()}>
                    <p className="">{entry.description}</p>
                    <p className="font-medium text-gray-500 dark:text-gray-300">
                        <i className="fas fa-clock"/> {entry.time_spent.toFixed(1)}
                    </p>
                </button>
                <DeleteDialogue entry={entry}/>
            </div>
            <IndirectTimeForm show={editMode}
                              setShow={setEditMode}
                              className="top-full left-0"
                              initialDesc={entry.description}
                              initialTime={entry.time_spent}
                              id={entry.id}/>
        </div>
    )
}

const DeleteDialogue = ({ entry }) => {
    const { open, close, isOpen, toggle } = useToggle(false)
    const mutate = useRemoveIndirectTime()
    const ref = useRef()

    useOutsideAlerter(ref, close)

    const handleDelete = async () => {
        const { id } = entry
        await mutate.mutateAsync({ id })
        close()
    }

    return (
        <div className="flex flex-col relative">
            <button className="text-gray-500 sm:hover:bg-gray-200 p-2 rounded-lg" onClick={toggle}>
                {mutate.isLoading ?
                    <i className="fad fa-spinner-third text-xl animate-spin"/>
                    :
                    <i className="fas fa-trash m-2 cursor-pointer"/>
                }
            </button>
            {isOpen && (
                <div ref={ref}
                     className="pop-open absolute w-52 flex flex-col space-y-2 items-center justify-around p-4 top-full right-0 z-20 shadow-paper-md rounded-xl bg-gradient-to-br from-blue-400 via-indigo-400 to-purple-500">
                    <p className="text-indigo-100 font-bold text-center">Sikker på at du vil slette stemplinga?</p>
                    <div className="flex justify-between font-bold">
                        <button
                            className="bg-red-500 sm:hover:bg-red-600 text-red-100 border-2 border-red-500 flex justify-center items-center rounded-full px-6 mr-2"
                            onClick={handleDelete}>
                            <i className="fas fa-trash m-2"/> Slett
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}