import React from 'react'
import styled, { keyframes } from 'styled-components'

const Spinner = () => {
    return (
        <Container>
            <Rotating>
                <i className="fad fa-spinner-third"/>
            </Rotating>
        </Container>
    )
}

const Container = styled.div`
  display: grid;
  place-items: center;
  min-height: 50vh;
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Rotating = styled.div`
  animation: ${rotate} 2s linear infinite;
  
  i {
    font-size: 3rem;
    color: var(--primary-pink);
`

export default Spinner