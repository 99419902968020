import { useMutation, useQueryClient } from 'react-query'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { Link, useParams } from 'react-router-dom'
import { ItemMenuBar } from './menubar'
import { ThoughtOperationsLister, useKalkyleCacheInvalidate } from '../operation/thought'
import { HoverMaterial, Material, useAddMaterialMutation } from './material'
import { useRecoilState } from 'recoil'
import { currentHoveringAtom } from '../page'
import { useChartDimensions } from '../../kanban/page'
import { ActualPdfViewer, useDrawing } from '../drawing'
import { Image } from '../../drawing/image'

export const Child = ({ item }) => {
    const { id, operations } = item
    const { addMutation } = useAddOperationMutation(item)
    const addSubItemMutation = useAddSubItemMutation(item.id)
    const addMaterailMutation = useAddMaterialMutation(item.id)

    const handleAdd = async (event) => {
        const text = event.dataTransfer.getData('text')
        const payload = JSON.parse(text)

        if (payload.id === id) return

        switch (payload.origin) {
            case 'braindump':
                await addMutation.mutate({
                    id: payload.id
                })
                break
            case 'item':
                await addSubItemMutation.mutate(payload.id)
                break
            case 'material':
                await addMaterailMutation.mutate(payload.id)
                break
        }
    }

    const options = {
        ignoreID: id
    }

    const { isHovering, files, metadata, funcs } = useDrop2(handleAdd, options)
    const [whatsHovering, setWhatsHovering] = useRecoilState(currentHoveringAtom)

    const onDragStart = async event => {
        const txt = event.dataTransfer.getData('text')
        if (txt.length !== 0) return
        setWhatsHovering('item')
        event.dataTransfer.setData('text/plain', JSON.stringify({
            origin: 'item',
            id: id
        }))
    }

    const onDragEnd = (event) => {
        funcs.onDragEnd(event)
        setWhatsHovering('')
    }

    return (
        <div className="">
            <ChildrenIndicator isLoading={addSubItemMutation.isLoading}
                               itemID={item.id} hasChildren={item?.children?.length ?? 0 > 0}
                               isHovering={isHovering}/>
            <div
                className={`
                relative shadow-paper-md border  dark:bg-gray-800
                sm:rounded-xl
                ${isHovering ? 'border-green-500' : 'border-gray-100 dark:border-gray-800'}`}
                draggable
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                onDragOver={funcs.onDragOver}
                onDrop={funcs.onDrop}
                onDragEnter={funcs.onDragEnter}
                onDragLeave={funcs.onDragLeave}
            >
                <div className="">
                    <div className="flex justify-center items-center py-2 cursor-move">
                        <div className="w-14 h-[5px] rounded-full bg-stone-200 dark:bg-gray-600"/>
                    </div>
                    <div className="p-2">
                        <Preview tag={item.tag} image={item.image} drawing={item.drawing} />
                    </div>
                    <div className="px-4">
                        <ItemMenuBar item={item}/>
                        <div className="flex flex-wrap pt-2">
                            {files?.map((file, index) => (
                                <div key={index}>
                                    <p>{file.name}</p>
                                </div>
                            ))}
                        </div>
                        <div className="flex flex-wrap py-2">
                            {addMaterailMutation.isLoading && (
                                <HoverMaterial/>
                            )}
                            {item.materials?.map((mat, index) => (
                                <Material key={index} material={mat}/>
                            ))}
                        </div>
                    </div>
                    <div>
                        <ThoughtOperationsLister operations={operations}/>
                    </div>
                    <div className={`
                                ${isHovering && `h-8`}
                                ${addMutation.isLoading ? 'py-2' : 'h-0'}
                                bg-gray-100 dark:bg-gray-700 rounded-lg
                                mx-4
                                transition-all`}>
                        {addMutation.isLoading && (
                            <div className="flex justify-center items-center">
                                <i className="fad fa-spinner-third animate-spin text-xl text-green-500"/>
                            </div>
                        )}
                    </div>
                </div>
                <Link className=""
                      to={`/kalkyle/create/${item?.kalkyle_id ?? ''}/item/details/${item?.id ?? ''}`}>
                    <div className="py-2 m-auto rounded-b-xl sm:hover:bg-stone-100/50 sm:hover:dark:bg-gray-700">
                        <p className="text-center font-bold text-stone-400 dark:text-gray-500">
                            Se Mer
                        </p>
                    </div>
                </Link>
            </div>
        </div>
    )
}

const Preview = ({ tag, image, drawing }) => {
    if (drawing.exists && !image.exists) return (
       <PreviewPDF id={drawing.id} />
    )

    return (
        <Image no={tag} className="rounded-xl max-h-64"/>
    )
}

const PreviewPDF = ({ id }) => {
    const [ref, dimension] = useChartDimensions()
    const { isLoading, drawing } = useDrawing(id, "id")

    return (
       <div ref={ref}>
           <ActualPdfViewer url={drawing} isLoading={isLoading} dimensions={dimension}/>
       </div>
    )
}

export const ChildrenIndicator = ({ itemID, hasChildren, isHovering, isLoading }) => {
    const { id } = useParams()
    const hide = !hasChildren
    return (
        <Link to={`/kalkyle/create/${id}/child/${itemID}`}>
            <div className={`
                kalkyle-child-card w-11/12 block m-auto
                bg-gray-100 dark:bg-gray-800 dark:bg-opacity-70 
                rounded-t-lg border-b border-white dark:border-gray-900 dark:sm:hover:bg-gray-700
                hover:bg-opacity-20 dark:hover:bg-opacity-100
                transition-all
                ${hide ? `
                    ${isLoading ? '' : 'h-0 pointer-events-none'}
                ` : 'h-6'}
                ${isHovering && `h-8 bg-opacity-80 dark:bg-opacity-100`}
                `}>
                {isLoading && (
                    <div className="flex justify-center items-center py-1">
                        <i className="fad fa-spinner-third animate-spin dark:text-gray-500"/>
                    </div>
                )}
            </div>
        </Link>
    )
}

const useAddSubItemMutation = (parentID) => {
    const { invalidateThoughtItems } = useKalkyleCacheInvalidate()

    const handle = (id) =>
        fetch(`/api/kanban/v1/kalkyle/thought-item/parent?id=${id}&parent_id=${parentID}`, {
            method: 'PUT'
        })

    return useMutation(handle, {
        onSuccess: async () => {
            await invalidateThoughtItems()
            toast.success('Lagt til som underdel!')
        },
        onError: err => {
            toast.error(`Noe gikk galt: ${err}`)
            console.error(`[AddSubItem] failed: ${err}`)
        }
    })
}

export const PublishMenu = ({ published, id }) => {
    const publish = usePublishMutation(id)

    if (published) return (
        <div className="px-2 flex items-center space-x-1 text-red-300">
            <i className="fad fa-lock"/>
            <p className="font-medium ">Navision</p>
        </div>
    )

    return (
        <button onClick={publish.mutate}
                className="hover:text-green-600 dark:hover:text-green-400 sm:hover:bg-gray-200 sm:dark:hover:bg-gray-700 px-3 rounded-md font-medium">
            {publish.isLoading ? (
                <i className="fad fa-spinner-third animate-spin"/>
            ) : (
                <>
                    <i className="fad fa-cloud-upload"/> Legg til Navision
                </>
            )}
        </button>
    )
}

const useAddOperationMutation = (item) => {
    const { id: kalkyleID } = useParams()
    const client = useQueryClient()

    let queyr = `item_id=${item.id}`
    if (item?.external_metadata?.published ?? false) {
        queyr = `key=${encodeURIComponent(item.external_metadata.key)}`
    }

    const handleAddOperation = ({ id }) => fetch(`/api/kanban/v2/kalkyle/thought-operation/add/convert?${queyr}&dump_id=${id}`, {
        method: 'POST'
    }).then(async res => {
        if (res.status !== 202) throw new Error(await res.text())
    })

    const addMutation = useMutation(handleAddOperation, {
        onSuccess: async () => {
            await client.invalidateQueries(['braindumps', kalkyleID])
            await client.invalidateQueries(['thought-items', kalkyleID])
        },
        onError: error => {
            toast.error(`Kunne ikke legget til fra huskelista: ${error}`)
        }
    })

    return {
        addMutation
    }
}

const usePublishMutation = (itemID) => {
    const { invalidateThoughtItems } = useKalkyleCacheInvalidate()

    const handlePublish = () =>
        fetch(`/api/kanban/v1/kalkyle/thought-item/publish?id=${encodeURIComponent(itemID)}`, {
            method: 'POST'
        }).then(async res => {
            if (res.status !== 202) throw new Error(await res.text())
        })

    return useMutation(handlePublish, {
        onSuccess: async () => {
            await invalidateThoughtItems()
            toast.success('')
        },
        onError: async (err) => {
            console.log(err)
            toast.error(err.toString())
        }
    })
}

export const useDrop = (handleDrop, options, setFiles) => {
    const { ignoreID } = options
    const [dragType, setDragType] = useState('')
    const [hoverCount, setHoverCount] = useState(0)
    const [metadata, setMetadata] = useState(null)


    const onDragEnter = async (event) => {
    }

    const onDragLeave = async () => {
        setHoverCount(prev => prev - 1)
    }

    const onDragOver = async event => {
        //event.preventDefault()
    }

    const onDragEnd = event => {
        setHoverCount(0)
        setDragType('')
        event.preventDefault()
    }

    const onDrop = async (event) => {
        event.preventDefault()

        if (isFileDrop(event)) {
            appendFiles(event)
            setHoverCount(0)
            return
        }

        await handleDrop(event)
        setHoverCount(0)
    }

    const isFileDrop = event => {
        event.preventDefault()
        for (const i in event.dataTransfer.types) {
            if (event.dataTransfer.types[i] === 'Files') {
                return true
            }
        }

        return false
    }

    const appendFiles = (event) => {
        const arr = []
        for (let i = 0; i < (event?.dataTransfer?.files?.length ?? 0); i++) {
            if (event.dataTransfer?.files?.[i]) {
                arr.push(event.dataTransfer.files[i])
            }
        }

        if (setFiles) setFiles(arr)
    }

    const isHovering = (n) => {
        return hoverCount > 0 && dragType === n
    }

    return {
        isHovering, metadata,
        funcs: { onDragEnter, onDragLeave, onDragOver, onDragEnd, onDrop },
        dragType
    }
}

export const useDrop2 = (handleDrop, options, handleSetTheFiles) => {
    const { ignoreID } = options
    const [hoverCount, setHoverCount] = useState(0)

    const onDragEnter = async () => {
        setHoverCount(prev => prev + 1)
    }

    const onDragLeave = async () => {
        setHoverCount(prev => prev - 1)
    }

    const onDragOver = async event => {
        event.preventDefault()
    }

    const onDragEnd = event => {
        setHoverCount(0)
    }

    const isFileDrop = event => {
        event.preventDefault()
        for (const i in event.dataTransfer.types) {
            if (event.dataTransfer.types[i] === 'Files') {
                return true
            }
        }
        return false
    }

    const appendFiles = (event) => {
        const arr = []
        for (let i = 0; i < (event?.dataTransfer?.files?.length ?? 0); i++) {
            if (event.dataTransfer?.files?.[i]) {
                arr.push(event.dataTransfer.files[i])
            }
        }
        handleSetTheFiles(arr)
    }

    const onDrop = async (event) => {
        setHoverCount(0)

        if (isFileDrop(event)) {
            appendFiles(event)
            setHoverCount(0)
            return
        }

        await handleDrop(event)
        setHoverCount(0)
    }
    const isHovering = hoverCount > 0

    return {
        isHovering,
        funcs: { onDragEnter, onDragLeave, onDragOver, onDragEnd, onDrop }
    }
}