import { useEffect, useRef, useState } from 'react'
import { useFileUpload } from '../kalkyle/item/fileupload'
import { useDrop2 } from '../kalkyle/item/child'

export const Image = ({ no, className = 'h-16 md:w-16 rounded-lg md:rounded-full' }) => {
    const [imageSrc, setImageSrc] = useState(imgSrc(no))

    const ref = useRef()
    const {
        uploading,
        appendFiles
    } = useFileUpload(`/api/kanban/v1/kalkyle/item/image?no=${encodeURIComponent(no ?? '')}`,
        ({ id }) => {
            setImageSrc(`/api/kanban/v1/kalkyle/item/image?id=${id}`)
        })

    useEffect(() => {
        setImageSrc(imgSrc(no))
    }, [no])

    const { isHovering, funcs } = useDrop2(() => {
    }, {}, appendFiles)

    const isUploading = uploading.length > 0

    if (!no || no.length === 0) return null

    return (
        <div className={`relative ${className}`}>
            <img onClick={() => ref.current.click()} {...funcs} className={`
                    relative
                    ${isUploading ? 'blur-sm' : 'filter-none'}
                    border-2 transition-all
                    ${isHovering ? 'border-green-500' : 'border-transparent sm:hover:border-green-500'}
                    cursor-pointer
                    object-cover
                    ${className}`}
                 src={imageSrc} alt=""/>

            {isUploading && (
                <div className="flex absolute inset-0 justify-center items-center">
                    <i className="fad fa-spinner-third animate-spin text-green-500"/>
                </div>
            )}

            <input style={{ display: 'none' }}
                   type="file"
                   accept="image/png, image/jpeg"
                   multiple
                   ref={ref}
                   onChange={e => {
                       const arr = []
                       for (let i = 0; i < (e?.target?.files?.length ?? 0); i++) {
                           if (e.target?.files?.[i]) {
                               arr.push(e.target.files[i])
                           }
                       }
                       appendFiles(arr)
                   }}/>
        </div>
    )
}

const imgSrc = (no) => {
    return `/api/kanban/v1/media?item=${no}`
    //if (grp === "id") return `/api/kanban/v1/kalkyle/item/image?id=${no}`
}