import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { profilePictureURL } from '../route/page'
import { useEligibleEmployees, useMutateResponsibleEmployee, useResponsibleEmployee } from './useResponsibleEmployee'
import { useOutsideAlerter } from './page'
import * as blobs2 from 'blobs/v2'

export const ImageCircle = ({ employee, isLoading }) => {
    const [hasImage, setHasImage] = useState(true)

    useEffect(() => {
        setHasImage(true)
    }, [employee?.email])

    const svgPath = useMemo(() => {
       return blobs2.svgPath({
           seed: Math.random(),
           extraPoints: 8,
           randomness: 1,
           size: 80,
       });
    }, [])

    if (isLoading) {
        return <div
            className="animate-pulse rounded-full h-16 w-16 bg-gray-400 text-sm flex justify-center items-center overflow-hidden">
            <i className="fad fa-spinner-third text-red-500"/>
        </div>
    }

    if (employee === undefined) {
        return <div
            className="rounded-full h-16 w-16 bg-pink-300 dark:bg-gray-700 dark:border-2 dark:border-gray-900 text-sm flex justify-center items-center overflow-hidden">
            <p className="text-center text-xl text-pink-900 dark:text-gray-400">
                <i className="fad fa-question"/>
            </p>
        </div>
    }

    if (!hasImage) {
        return <div
            className="rounded-full h-16 w-16 bg-pink-300 dark:bg-pink-900 dark:border-2 dark:border-gray-900 text-pink-900 dark:text-gray-300 font-bold text-sm flex justify-center items-center overflow-hidden">
            <p className="text-center">{employee.first_name}</p>
        </div>
    } else {
        return <svg width={80} height={80}>
            <defs>
                <clipPath id={`shape-${employee?.email}`}>
                    <path fill="red" d={svgPath} />
                </clipPath>
            </defs>
            <image
                x={-10} y={-10}
                height={100} width={100}
                clipPath={`url(#shape-${employee?.email})`}
                href={profilePictureURL(employee?.email)}
                onError={() => setHasImage(false)}
            />
        </svg>
    }
}

export const CurrentManager = () => {
    const { center, machine } = useParams()
    const { data, isError, error, isLoading } = useResponsibleEmployee(center, machine)

    return (
            <div className="dark:bg-gray-800 shadow-paper-sm relative sm:rounded-xl px-3 py-2">
                <p className="pb-2 dark:text-gray-300 font-bold"><i className="fas fa-crown mr-1"/> Hvem er ansvarlig?</p>
                <div className="flex space-x-2 items-center">
                    <ImageCircle employee={data?.data}/>
                    <CurrentManagerName data={data} />
                </div>
                <ResponsibleEmployeeDropDown center={center} machine={machine}/>
            </div>
    )
}

const CurrentManagerName = ({ data }) => {
    if (!data) return null
    return (
        <p className="font-medium dark:text-gray-300">
            {`${data?.data.first_name} ${data?.data.middle_name ? data?.data.middle_name.concat(' ') : ' '}${data?.data.last_name}`}
        </p>
    )
}

export const ResponsibleEmployeeDropDown = ({ center, machine }) => {
    const { mutate } = useMutateResponsibleEmployee()
    const [searchTerm, setSearchTerm] = useState('')
    const { data, isLoading, isError, error } = useEligibleEmployees()
    const [isChangingManager, setIsChangingManager] = useState(false)
    const [employeeList, setEmployeeList] = useState([])

    const ref = useRef()
    useOutsideAlerter(ref, () => {
        setIsChangingManager(false)
    })

    const findEmployee = (empList, searchterm) => {
        return Promise.resolve().then(v => {
            if (empList === undefined) return
            let newList = []
            for (let i = 0; i < empList.length; i++) {
                const fullName = empList[i].first_name.concat(' ', empList[i].middle_name && empList[i].middle_name.concat(' '), empList[i].last_name)
                if (fullName.toLowerCase().includes(searchterm.toLowerCase())) {
                    newList.push(empList[i])
                }
            }
            return setEmployeeList(newList)
        })
    }

    const handleChangeManager = (obj) => {
        const { emp, wasClicked } = obj
        if (wasClicked) {
            mutate({ center, machine, emp })
            setIsChangingManager(false)
            return
        } else if (employeeList.length === 1) {
            mutate({ center, machine, emp: employeeList[0] })
            setIsChangingManager(false)
        }
    }

    const handleKeyDown = (Key) => {
        const { key } = Key
        if (key === 'Enter') handleChangeManager({ wasClicked: false })
        else if (key === 'Escape') setIsChangingManager(false)
        //console.log(key)
    }

    useEffect(() => {
        if (!searchTerm || searchTerm === '') {
            setEmployeeList(data)
            return
        } else {
            findEmployee(data.data, searchTerm)
        }
        //console.log(employeeList)
    }, [searchTerm])

    if (!isChangingManager) {
        return (
            <div className="absolute z-10 inset-0 cursor-pointer"
                 onClick={() => setIsChangingManager(true)}
            ></div>
        )
    }




    return (
        <div
            ref={ref}
            className="absolute z-20 bg-gradient-to-br from-blue-400 via-indigo-400 to-purple-500 shadow-paper-md p-2 rounded-xl flex flex-col overflow-y-scroll min-w-max max-h-80 top-full left-1/2 -translate-x-1/2"
            onBlur={(event) => {
                if (!event.currentTarget.contains(event.relatedTarget)) {
                    setIsChangingManager(false)
                }
            }}>

            <div className="flex flex-col space-y-1">
            {data?.data?.map((emp, id) => {
                return <DropDownItem
                    key={id}
                    name={`${emp?.first_name} ${emp?.middle_name} ${emp?.last_name}`}
                    clicked={() => {
                        handleChangeManager({ wasClicked: true, emp })
                    }}
                />
            })}
            </div>
        </div>
    )
}

const DropDownItem = ({ name, clicked }) => {
    return <button onMouseDown={clicked}
                   className="cursor-pointer hover:underline py-2 px-3 rounded-lg text-left xl:my-0 bg-indigo-200 bg-opacity-25 sm:hover:bg-opacity-50 text-gray-900"
    >{name}</button>

}
/*
const DropDown = ({ employees, setIsChangingManager }) => {
    const { mutate } = useMutateResponsibleEmployee()
    const { center, machine } = useParams()
    const [searchTerm, setSearchTerm] = useState('')
    const [employeeList, setEmployeeList] = useState([])

         const findEmployee = (empList, searchterm) => {
            return new Promise((resolve, reject) => {
                resolve(empList.filter(emp => {
                    const fullName = emp.first_name.concat(' ', emp.middle_name && emp.middle_name.concat(' '), emp.last_name)
                    if (fullName.toLowerCase().includes(searchterm.toLowerCase())) return true
                    else return false
                }))
            }
        } 

    const findEmployee = (empList, searchterm) => {
        return Promise.resolve().then(v => {
            if (empList === undefined) return
            let newList = []
            for (let i = 0; i < empList.length; i++) {
                const fullName = empList[i].first_name.concat(' ', empList[i].middle_name && empList[i].middle_name.concat(' '), empList[i].last_name)
                if (fullName.toLowerCase().includes(searchterm.toLowerCase())) {
                    newList.push(empList[i])
                }
            }


            return setEmployeeList(newList)
        })
    }

    const handleChangeManager = (obj) => {
        const { emp, wasClicked } = obj
        if (wasClicked) {
            mutate({ center, machine, emp })
            setIsChangingManager(false)
            return
        }
        else if (employeeList.length === 1) {
            mutate({ center, machine, emp: employeeList[0] })
            setIsChangingManager(false)
        }
    }

    const handleKeyDown = (Key) => {
        const { key } = Key
        if (key === "Enter") handleChangeManager({ wasClicked: false })
        else if (key === "Escape") setIsChangingManager(false)
        //console.log(key)
    }

    useEffect(() => {
        setEmployeeList(employees)
    }, []);

    useEffect(() => {
        if (!searchTerm || searchTerm === '') {
            setEmployeeList(employees)
            return
        }
        else {
            findEmployee(employees, searchTerm)
        }
        //console.log(employeeList)
    }, [searchTerm]);
    return (
        <div className='absolute z-20 bg-white shadow-md dark:bg-gray-700 p-2 rounded-lg top-full border-2 flex flex-col overflow-x-hidden overflow-y-scroll min-w-20 max-h-80'>
            <input
                className={`text-gray-900 outline-none focus:ring-2 mb-2 ring-purple-400 ring-opacity-50 ring-offset-2 ring-offset-white dark:ring-offset-gray-900 rounded-lg py-3 px-6 w-full bg-gray-100 dark:bg-gray-600 dark:text-white`}
                autoFocus
                type="text"
                placeholder="Søk..."
                value={searchTerm}
                onChange={event => setSearchTerm(event.currentTarget.value)}
                onKeyDown={handleKeyDown}

            />


            {employeeList?.map((emp, id) => {
                return <DropDownItem
                    key={id}
                    name={`${emp?.first_name} ${emp?.middle_name} ${emp?.last_name}`}
                    clicked={() => {
                        handleChangeManager({ wasClicked: true, emp })
                    }}
                />
            })}

        </div>
    )
}


const DropDownItem = ({ name, clicked }) => {
    return <button onMouseDown={clicked}
        className='cursor-pointer hover:underline my-2 xl:my-0'
    >{name}</button>

}
 */
