import { useHistory as useHerstory, useParams } from 'react-router-dom'
import React, { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import '../styling/slider.css'
import { toast } from 'react-hot-toast'
import { Notification } from '../consumption/menu'

const useOperationParams = () => {
    const { order, line, routing, operation } = useParams()
    return {
        order_no: decodeURIComponent(order),
        line_no: decodeURIComponent(line),
        routing_no: decodeURIComponent(routing),
        operation_no: decodeURIComponent(operation)
    }
}

const useOwnershipTransfer = (order, line, routing, operation) => {
    const { data, isLoading } = useQuery(['transfer-ownership', order, line, routing, operation], () =>
        fetch('/api/kanban/v1/transfer-ownership/begin', {
            method: 'POST',
            body: JSON.stringify({
                'order_no': order,
                'line_no': parseInt(line),
                'route_no': routing,
                'operation_no': operation
            })
        }).then(res => {
            if (res.status !== 200) throw new Error('failed ')
            return res.json()
        })
    )

    return {
        data, isLoading
    }
}

export const TransferPage = () => {
    const herstory = useHerstory()
    const { order_no, line_no, routing_no, operation_no } = useOperationParams()
    const { data, isLoading } = useOwnershipTransfer(order_no, line_no, routing_no, operation_no)

    const [acknowledged, setAcknowledged] = useState(false)
    const [center, setCenter] = useState(null)
    const [reol, setReol] = useState(data?.storage_location?.location ?? "")
    const [description, setDescription] = useState('')
    const [count, setCount] = useState(1)


    const handleDestinationChange = event => {
        const value = event.target.value

        if (value === 'LAGER') {
            setCenter({
                workCenterNo: null,
                machineCenterNo: null
            })
            return
        }

        const [workCenterNo, machineCenterNo] = value.split(',')
        setCenter({
            workCenterNo,
            machineCenterNo
        })

        console.log(workCenterNo, machineCenterNo)
    }

    if (isLoading) return <p>loading...</p>

    const { item, operation, storage_location, next_work_centers } = data
    const showSubmit = acknowledged && (reol.length > 1 || storage_location.in_stock)

    return (
        <div className="max-w-4xl m-auto px-4 sm:p-0">
            <div className="">
                <button onClick={() => herstory.goBack()}>{'<- '}Tilbake</button>
            </div>

            <div className="text-center mb-8">
                <h1 className="hdg--divider-pink bg-white dark:bg-gray-900 text-4xl font-bold">
                    <span className="bg-white dark:bg-gray-900 px-2">Viderefør</span>
                </h1>
            </div>

            <div className="p-4 bg-gray-100 dark:bg-gray-800 rounded-xl text-center">
                <div className="flex items-center justify-center">
                    <input type="checkbox" id="hours-checkbox" className="m-0 p-0 mr-2" checked={acknowledged}
                           onChange={e => setAcknowledged(e.target.checked)}/>
                    <label htmlFor="hours-checkbox">
                        Jeg bekrefter at delene er faglig riktig utført.
                    </label>
                </div>
            </div>

            {acknowledged && (
                <div className="my-8">
                    <div className="flex justify-center">
                        <p className="font-medium">
                            <i className="far fa-clock"/> {data?.operation?.metadata?.hours_used} / {data?.operation?.metadata?.hours_allocated?.toFixed(1)} timer
                        </p>
                    </div>
                    <p className="">
                        <span className="font-medium">Har det oppstått avvik?</span>
                        <br/>
                        <span className="text-gray-500 dark:text-gray-400">
                            Hvis det har oppstått avvik, vennligst forklar.
                        </span>
                    </p>
                    <textarea onChange={e => setDescription(e.target.value)}
                              value={description}
                              placeholder="f.eks transporten gikk raskere fordi vi brukte trucken"
                              className="p-4 rounded-md shadow-sm border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 w-full focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
                </textarea>
                </div>
            )}

            {acknowledged && (
                <div className="grid grid-cols-6 gap-6 mt-8">
                    <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="placement" className="font-medium">Hvem får ansvaret for delene?</label>
                        <select name="placement" id="placement" onChange={handleDestinationChange}
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-lg sm:text-base">
                            {data?.next_work_centers?.map(wc => (
                                <option key={`${wc.workCenterNo},${wc.machineCenterNo}`}
                                        value={[wc.work_center_no, wc.machine_center_no]}>
                                    {wc.name}
                                </option>
                            ))}
                            <option value="LAGER">Lager</option>
                        </select>
                    </div>

                    <Location reol={reol} setReol={setReol} />

                    {(showSubmit) && (
                        <Quantity
                            count={count} setCount={setCount}
                            max={(data?.item?.quantity ?? 0) - (data?.operation?.metadata?.parts_manufactured ?? 0)}
                        />
                    )}
                </div>
            )}
            {showSubmit && (
                <Submit operation={operation} location={reol} count={count} description={description} />
            )}
        </div>
    )
}

const Submit = ({ operation, location, count, description }) => {
    const mutation = useFinishTransferOwnership(operation.prod_order_no, operation.routing_reference_no, operation.routing_no)

    return (
        <div className="flex justify-center mt-8">
            <button
                onClick={() => mutation.mutate({
                    location: location,
                    task: operation.task,
                    count: count,
                    description: description,
                })}
                className="bg-fuchsia-200 text-fuchsia-900 px-6 py-2 rounded-full font-bold text-lg">

                {mutation.isLoading ? (
                    <div>
                        <i className="fad fa-spinner-third text-white text-4xl animate-spin"/>
                    </div>
                ) : (
                    "Viderefør"
                )}

            </button>
        </div>
    )
}

const useFinishTransferOwnership = (order, line, routing) => {
    const herstory = useHerstory()
    const client = useQueryClient()

    const transferOwnership = async blah => fetch(`/api/kanban/v1/transfer-ownership/finish`, {
        method: "POST",
        body: JSON.stringify(blah)
    }).then(async res => {
        if (res.status !== 200) throw new Error(await res.text())
    })

    return useMutation(transferOwnership, {
        onSuccess: async () => {
            await herstory.replace(`/route/${order}/${line}/${routing}`)
            await client.invalidateQueries(["route", order, line, routing])
            toast.custom(t => (
                <OwnershipTransferedSuccessfullyNotification t={t} />
            ))
        }
    })
}

const OwnershipTransferedSuccessfullyNotification = ({ t }) => {
    return (
        <Notification t={t}>
            <div className="flex space-x-2 items-center">
                <i className="fas fa-check"/>
                <div className="flex flex-col">
                    <p className="font-bold">Delene er videreført!</p>
                </div>
            </div>
        </Notification>
    )
}

const Location = ({ reol, setReol }) => {
    return (
        <div className="col-span-6 sm:col-span-3">
            <label htmlFor="reol" className="font-medium">Hvilken reol har du lagt de i?</label>
            <input type="text" id="reol" placeholder="v30"
                   value={reol}
                   onChange={e => setReol(e.target.value)}
                   className="mt-1 block py-2 px-3 border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 rounded-md shadow-sm w-full text-lg sm:text-sm focus:outline-none focus:ring-blue-500 focus:border-indigo-500"/>
        </div>
    )
}

const Quantity = ({ max, count, setCount }) => {
    const onChange = event => {
        const n = parseInt(event.target.value)
        if (n < 1) {
            setCount(1)
            return
        }

        if (n > max) {
            setCount(max)
            return
        }

        setCount(n)
    }

    return (
        <div className="col-span-6 sm:col-span-3">
            <p className="font-medium">Hvor mange deler ønsker du å videreføre?</p>
            <input type="number" value={count} onChange={onChange}
                   className="shadow-sm border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 py-2 px-3 block mt-1 text-lg sm:text-sm rounded-md w-full focus:outline-none focus:ring-blue-500 focus:border-indigo-500"/>
            <input type="range" min={0} max={max} value={count} className="w-full py-4"
                   onChange={onChange}/>
        </div>
    )
}

