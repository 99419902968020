import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

const statusContinue = 200
const statusUnauthorized = 401

export const useBouncer = () => {
    const location = useLocation()
    const history = useHistory()

    useEffect(() => {
        if (location.pathname.startsWith('/login')) return
        fetch('/api/kanban/v2/bouncer')
            .then(res => {
                switch (res.status) {
                    case 202:
                        break
                    default: history.replace('/login')
                }
            })
            .catch(err => console.error(err))
    }, [location.pathname])


    const logout = () => {
    }

    return {
        logout
    }
}

const Bouncer = () => {
    return (
        <div>
        </div>
    )
}