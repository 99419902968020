import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  place-items: center;
  padding: 15vh 1rem 1rem 1rem;
  grid-gap: 1rem;
 
  max-width: 800px;
  margin: auto;
  
  h1 {
    margin: 0;
    padding: 0;
    font-size: 3rem;
    text-align: center;
  }
  
  p {
    text-align: center;
  }
  
  #mega {
    font-size: 6rem;
    background: -webkit-linear-gradient(45deg, #f7ff00, #db36a4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  @media screen and (max-width: 600px) {
    h1 {
      font-size: 2rem;
    }
    #mega {
      font-size: 3rem;
    }
  }
`

export const Input = styled.input`
  all: unset;
  
  background-color: rgba(var(--rgb-medium-gray), .2);
  border: 2px solid rgba(var(--rgb-medium-gray), .4);
  padding: 1rem;
  border-radius: 1rem;
  font-size: 1rem;
  font-weight: bold;
  background-image: none;
  
  :focus {
    outline: none;
    border-color: var(--primary-pink);
  }
`

export const Button = styled.button`
  margin: auto;
  background: var(--pink-gradient);
  padding: .8rem 2.3rem;
  font-size: 1.3rem;
  border-radius: 1rem;
  color: white;
  
`
