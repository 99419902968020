import React, { createContext, useContext, useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import useUser from '../hooks/useUser'
import Navbar from './bar'
import { WebauthnPage } from '../webauthn/page'
import { UpdatesPage } from '../updates/page'
import Home from '../home/page'
import Kanban from '../kanban/page'
import User from '../pages/user'
import { RoutePage } from '../route/page'
import { ProductionPage } from '../production/page'
import { TransferPage } from '../ownership/transfer_page'
import { AsBuiltPage } from '../asbuilt/page'
import { HerderiPage } from '../herderi/page'
import { KalkyleLister } from '../kalkyle/list'
import ItemInfoPage from '../components/ItemInfoPage/ItemInfoPage'
import { InventoryPage } from '../inventory/page'
import { ItemPage } from '../kalkyle/item/page'
import { CreateKalkyle, KalkyleChild, KalkyleDetails } from '../kalkyle/page'

const UserContext = React.createContext({})
export const useUserContext = () => useContext(UserContext)

const SnackbarContext = createContext({})
export const useSnackbarContext = () => useContext(SnackbarContext)

const App = () => {
    const user = useUser()
    const [showSnackbar, setShowSnackbar] = useState(false)


    useEffect(() => {
        if (!showSnackbar) return

        const timeout = setTimeout(() => {
            setShowSnackbar(false)
        }, 6000)

        return () => clearTimeout(timeout)
    }, [showSnackbar])


    const setSnackbar = () => {
        setShowSnackbar(true)
    }

    return (
        <RecoilRoot>
            <UserContext.Provider value={user}>
                <SnackbarContext.Provider value={{
                    setSnackbar
                }}>
                    <Navbar/>
                    <Switch>
                        <Route path="/user/webauthn">
                            <WebauthnPage/>
                        </Route>
                        <Route path="/test">
                        </Route>
                        <Route path="/updates">
                            <UpdatesPage/>
                        </Route>
                        <Route path="/kanban/work-center/:center/:machine">
                            <Kanban/>
                        </Route>
                        <Route path="/user">
                            <User/>
                        </Route>
                        <Route path="/item/:articleno/:chargeno">
                            <ItemInfoPage />
                        </Route>
                        <Route path="/route/:order/:line/:routing">
                            <RoutePage/>
                        </Route>
                        <Route path="/production/:order">
                            <ProductionPage/>
                        </Route>
                        <Route path="/transfer-ownership/:order/:line/:routing/:operation">
                            <TransferPage/>
                        </Route>
                        <Route path="/as-built/:id">
                            <AsBuiltPage/>
                        </Route>
                        <Route path="/app/herderi">
                            <HerderiPage />
                        </Route>
                        <Route path="/kalkyle/view/:id/item/details/:childID">
                            <ItemPage />
                        </Route>
                        <Route path="/kalkyle/create/:id/item/details/:childID">
                            <KalkyleDetails />
                        </Route>
                        <Route path="/kalkyle/create/:id/child/:childID">
                            <KalkyleChild />
                        </Route>
                        <Route path="/kalkyle/create/:id">
                            <CreateKalkyle />
                        </Route>
                        <Route path="/kalkyle">
                            <KalkyleLister />
                        </Route>
                        <Route path="/search">
                            <InventoryPage />
                        </Route>
                        <Route path="/">
                            <Home/>
                        </Route>
                    </Switch>
                    <Snackbar show={showSnackbar} setShow={setShowSnackbar}/>
                </SnackbarContext.Provider>
            </UserContext.Provider>
        </RecoilRoot>
    )
}

const Snackbar = ({ show, setShow }) => {
    return (
        <div className={`
                fixed left-4 right-4 z-40
                transition-all duration-400
                ${show ? 'bottom-8 opacity-100' : '-bottom-full opacity-0'}
        `}>
            <div className="m-auto max-w-3xl m-4 bg-green-600 rounded text-white">
                <div className="flex items-center justify-items-stretch p-2">
                    <div className="mr-2">
                        <i className="fas fa-check bg-green-700 p-2 rounded-xl"/>
                    </div>
                    <div className="flex flex-col w-full">
                        <p className="font-bold">
                            Suksess!
                        </p>
                        <p className="text-sm">
                            Det kan ta noen minutter før timene blir oppdatert på Arbeidssenteret.
                        </p>
                    </div>
                    <div className="hidden sm:inline">
                        <button onClick={() => setShow(false)}>
                            OK
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default App