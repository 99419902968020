import { useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useEffect, useRef, useState } from 'react'
import { useOutsideAlerter } from '../kanban/page'
import { useRecoilState } from 'recoil'
import { currentHoveringAtom } from './page'

export const BrainDumps = () => {
    const { braindumps, isLoading } = useBrainDumps()

    useEffect(() => {
        const keypressHandler = event => {
            if (event.target.localName !== 'input') {
            }
        }
        document.addEventListener('keypress', keypressHandler)
        return () => document.removeEventListener('keypress', keypressHandler)
    }, [])

    return (
        <div className="shadow-lg dark:shadow-transparent shadow-green-500/20 dark:shadow-none border bg-green-200 bg-opacity-50 border-2 border-green-400 dark:border-gray-800 dark:bg-gray-800 sm:rounded-xl flex flex-col px-3 py-2">
            <h3 className="font-bold text-base text-green-900 dark:text-gray-100">
                <i className="fad fa-mind-share mr-1"/>
                Huskeliste
            </h3>
            <p className="text-green-900 dark:text-gray-400">
                <span className="font-medium">Legg til ting du kommer på.</span> Nyttig hvis du ikke veit hvor du skal starte, enda.</p>
            {isLoading && (
                <div className="block m-auto my-8">
                    <i className="fad fa-spinner-third animate-spin text-4xl"/>
                </div>
            )}
            <div className="flex flex-col space-y-1 mt-2">
                {braindumps?.map((todo, index) => (
                    <BrainDump {...todo} key={index}/>
                ))}
            </div>
            <AddBrainDump/>
        </div>
    )
}

const useBrainDumps = () => {
    const { id } = useParams()
    const { data, isLoading } = useQuery(['braindumps', id], () =>
        fetch(`/api/kanban/v1/kalkyle/braindump/list?id=${encodeURIComponent(id)}`)
            .then(async res => {
                if (res.status !== 200) throw new Error(await res.text())
                return res.json()
            }))

    return {
        braindumps: data,
        isLoading
    }
}

const AddBrainDump = () => {
    const [description, setDescription] = useState('')
    const [show, setShow] = useState(false)
    const { add } = useAddBrainDump(() => {
        setDescription('')
    })

    const handleAdd = () =>
        add.mutate({
            description
        })

    useEffect(() => {
        const keypressHandler = event => {
            if (event.target.localName !== 'input') {
                if (event.key === 'n') {
                    setShow(true)
                }
            }
            if (event.key === 'Enter' && description.length > 0 && show) {
                handleAdd()
            }
        }
        document.addEventListener('keypress', keypressHandler)
        return () => document.removeEventListener('keypress', keypressHandler)
    }, [show, description])

    useEffect(() => {
        const keypressHandler = event => {
            if (event.key === 'Escape') {
                setShow(false)
            }
        }
        document.addEventListener('keydown', keypressHandler)
        return () => document.removeEventListener('keydown', keypressHandler)
    }, [])


    const ref = useRef()
    useOutsideAlerter(ref, () => {
        setShow(false)
    })

    return (
        <div className="relative flex justify-center">
            <button
                className="text-green-500 dark:text-green-400 mt-4 px-6 py-2 sm:hover:bg-green-200 sm:dark:hover:bg-gray-600 rounded-xl"
                onClick={() => setShow(!show)}>
                <i className="fas fa-plus-circle text-2xl"/>
            </button>
            {show && (
                <div ref={ref}
                     className="z-20 absolute top-full left-0 right-0 grid grid-cols-6 bg-gradient-to-br from-blue-400 via-indigo-500 to-purple-400 px-3 py-3 rounded-xl pop-open"
                     style={{ gridGap: '.5rem' }}>
                    <p className="col-span-6 text-indigo-100 font-bold text-center text-lg">Hva har du kommet på?</p>
                    <input type="text"
                           autoFocus
                           placeholder="Frese gjenger 2x4"
                           value={description}
                           onChange={e => setDescription(e.target.value)}
                           className="col-span-6 text-base rounded-lg px-3 py-2 text-black dark:bg-gray-800 dark:text-white outline-none focus:ring-4 ring-indigo-300 placeholder-gray-500 font-medium"/>
                    <button onClick={handleAdd}
                            className="col-span-6 px-6 py-2 mt-2 bg-emerald-400 text-green-900 m-auto rounded-full ">
                        {add.isLoading ? (
                            <i className="fad fa-spinner-third animate-spin"/>
                        ) : (
                            <span>Legg til</span>
                        )}
                    </button>
                </div>
            )}
        </div>
    )
}

const BrainDump = ({ id, description }) => {
    const [ hovering, setHovering ] = useRecoilState(currentHoveringAtom)
    const { remove } = useBrainDumpMutations()

    const handleRemove = () =>
        remove.mutate({
            id
        })

    const onDragStart = (event) => {
        console.log("ondragstarT: ", event)
        setHovering('braindump')
        event.dataTransfer.setData('text/plain', JSON.stringify({
            origin: 'braindump',
            id: id,
        }))
    }

    const onDragEnd = () => {
        setHovering('')
        console.log("ended")
    }

    return (
        <div onDragEnd={onDragEnd} onDragStart={onDragStart} draggable
             className="bg-green-200 dark:bg-gray-700 rounded-md px-3 py-2 flex justify-between items-center cursor-move">
            <p className="text-green-900 dark:text-gray-200 text-left">{description}</p>
            {remove.isLoading ? (
                <div>
                    <i className="fad fa-spinner-third text-green-600 px-3 animate-spin"/>
                </div>
            ) : (
                <button onClick={handleRemove}>
                    <i className="fas fa-trash text-red-400 hover:text-red-700 px-3"/>
                </button>
            )}
        </div>
    )
}

const useAddBrainDump = (callbackFn) => {
    const { id } = useParams()
    const client = useQueryClient()

    const handleCreateBraindump = ({ description }) => fetch(`/api/kanban/v1/kalkyle/braindump/add?id=${id}`, {
        method: 'POST',
        body: JSON.stringify({
            description
        })
    }).then(async res => {
        if (res.status !== 202) throw new Error(await res.text())
    })

    const add = useMutation(handleCreateBraindump, {
        onSuccess: async data => {
            await client.invalidateQueries(['braindumps', id])
            if (callbackFn) callbackFn()
        }
    })

    return {
        add
    }
}

const useBrainDumpMutations = () => {
    const { id: kalkyleID } = useParams()
    const client = useQueryClient()

    const handleRemoveBraindump = ({ id }) => fetch(`/api/kanban/v1/kalkyle/braindump/remove?id=${id}`, {
        method: 'DELETE'
    }).then(async res => {
        if (res.status !== 202) throw new Error(await res.text())
    })

    const remove = useMutation(handleRemoveBraindump, {
        onSuccess: async data => {
            await client.invalidateQueries(['braindumps', kalkyleID])
        }
    })

    return {
        remove
    }
}