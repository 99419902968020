import * as d3 from 'd3'
import styled, { css } from 'styled-components'
import React from 'react'

export const nametag = (fullName) => {
    const alphabet = [
        //'0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
        'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J',
        'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T',
        'U', 'V', 'W', 'X', 'Y', 'Z'
    ]

    const parts = fullName?.split(' ')?.splice(0, 2)
    if (!parts) {
        return "?"
    }

    let short = ''
    let pos = 0

    for (let i = 0; i < alphabet.length; i++) {
        if (parts[0][0]?.toUpperCase() === alphabet[i]) {
            pos = i
        }
    }

    parts.forEach(part => {
        short += part[0]
    })

    const scale = d3.scaleLinear()
        .domain([0, 26])
        .range([0, 1])

    const color = d3.interpolateRainbow(scale(pos))
    const ce = d3.color(color)

    const whiteOverlay = (ce.r*0.299 + ce.g*0.587 + ce.b*0.114) > 160

    if (short === "undefined") {
        short = "?"
    }

    return [`${ce.r}, ${ce.g}, ${ce.b}`, short, !whiteOverlay]
}

export const NametagIcon = ({ className, fullName, mini, soft }) => {
    const [color, initials, whiteText] = nametag(fullName)

    return (
        <NameIcom className={className} color={color} whiteText={whiteText} mini={mini} soft={soft}>
            <p>
                {initials ?? '?'}
            </p>
        </NameIcom>
    )
}

export const NameTagCircleStackable = ({ names, soft }) => {
    return (
        <Stackable>
            {names.map(name => {
                return (
                    <NametagIcon key={name} fullName={name} soft={soft} mini/>
                )
            })}
        </Stackable>
    )
}

const Stackable = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-items: center;
`

const NameIcom = styled.div`
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 44px;
  width: 44px;
  border-radius: 50%;
  background-color: rgba(${props => props.color});
  
  p {
    padding: 0;
    font-size: 22px;
    font-weight: bold;
    display: block;
    margin: 0 auto;
    text-align: center;
    color: white;
    ${props => !props.whiteText && css`
      color: black;
    `};
    
  }
  
    
  ${props => props.mini && css`
    margin-right: 4px;
    height: 24px;
    width: 24px;
    p {
      font-size: 12px;
    }
  `}
    
  @media (max-width: 800px) {
    ${props => !props.mini && css`
        height: 35px;
        width: 35px;
        p {
          font-size: 15px;
        }
    `}
    
     ${props => props.mini && css`
        margin-right: 4px;
        height: 24px;
        width: 24px;
        p {
          font-size: 12px;
        }
  `}
  }

  


`