import { Link, useParams } from 'react-router-dom'
import { useKalkyle } from './page'

export const SiteMap = () => {
    const kalkyle = useKalkyle()

    return (
        <div
            className="hidden sm:block shadow-lg dark:shadow-transparent shadow-purple-500/20 border bg-purple-200 bg-opacity-50 border-2 border-purple-400 border-gray-100 dark:border-gray-800 dark:bg-gray-800 px-3 py-2 rounded-xl">
            <Oversikt/>
            <SiteMapLister ccc={kalkyle?.data?.items}/>
        </div>
    )
}

const Oversikt = ({}) => {
    const { id, childID } = useParams()
    const isOnTopLevel = childID === undefined

    return (
        <div>
            <Link to={`/kalkyle/create/${id}`}
                  className={`font-bold hover:underline  ${isOnTopLevel ? 'text-purple-900 dark:text-green-400' : 'text-purple-700 dark:text-gray-400'}`}>
                <i className="fas fa-crow mr-1"/> Oversikt
            </Link>
            <p className="text-purple-900 dark:text-gray-400"><span
                className="font-medium">Naviger deg rundt.</span> Nyttig når prosjektet blir stort.</p>
        </div>
    )
}

// This function is recursive on the second level.
const SiteMapLister = ({ ccc }) => {
    if ((ccc?.length ?? 0) <= 0) return null
    return (
        <div>
            {ccc?.map((item, index) => (
                <RoadLister item={item} key={index}/>
            ))}
        </div>
    )
}

const RoadLister = ({ item }) => {
    const { id, childID } = useParams()
    const active = item.id === childID

    return (
        <div className="flex mb-1">
            <div className={`
                w-1 block
                ${active ? 'bg-purple-600 dark:bg-green-400' : 'bg-purple-300 dark:bg-gray-700'}
                rounded-full ml-1 mr-2`}/>
            <div>
                <div className="flex space-x-2 items-center">
                    {item?.image.exists && (
                        <img className="w-8 h-8 my-1 fill object-cover rounded-lg"
                             src={`/api/kanban/v1/kalkyle/item/image?id=${item.image.id}`}/>
                    )}
                    <Road id={item.id} active={active} description={item.description}
                          locked={item?.external_metadata?.published}/>
                </div>
                <SiteMapLister ccc={item?.children}/>
            </div>
        </div>
    )
}

const Road = ({ id, active, description, locked }) => {
    const { id: kalkyleID } = useParams()

    return (
        <Link to={`/kalkyle/create/${kalkyleID}/item/details/${id}`} className={`
                ${active ? 'text-purple-900 font-bold dark:text-green-400' : 'text-purple-700 dark:text-gray-400'}
                sm:hover:underline
        `}>
            <div className="flex space-x-2 items-center">

                {description ? (
                    <p>{description}</p>
                ) : (
                    <span className="italic">Mangler beskrivelse</span>
                )}

                {locked && (
                    <i className="fas fa-lock text-gray-400 text-sm"/>
                )}
            </div>
        </Link>
    )
}
