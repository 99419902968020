import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { ProfilePicture, RouteItem } from '../route/page'
import { FilesLister } from '../files/files'
import { useKanbanPreferences } from './page'
import { routeURL } from '../home/page'
import { useVisibilityHook } from 'react-observer-api'
import { Image } from '../drawing/image'
import { getUnit } from './lager'

export const Item = ({ item }) => {
    const herstory = useHistory()
    const { show_files, show_operations, show_metadata } = useKanbanPreferences()
    const {setElement, isVisible} = useVisibilityHook({
        threshold: 0.5,
        rootMargin: '100px'
    })

    if (!item) return null

    const to = `/route/${item.prod_order_no}/${item.prod_order_line_no}/${item.routing_no}`

    if (!show_files && !show_operations && !show_metadata) {
        return (
            <img
                className="h-full w-full object-cover rounded-3xl hover:ring-4 ring-purple-500 cursor-pointer transition-all lift-from-paper-sm"
                onClick={() => herstory.push(to)}
                src={`/api/kanban/v1/media?item=${item.no}`} alt=""
                onError={(e) => e.target.style.display = 'none'}
            />
        )
    }

    return (
        <div ref={setElement} className={`
                relative
                py-4 sm:py-8
                px-2 sm:px-8
                shadow-paper-lg border border-gray-100 dark:border-gray-800
                sm:rounded-3xl 
                dark:bg-gray-800`}>
            {isVisible && (
                <>
                    <Image no={item.no} className="max-h-60 rounded-3xl" />

                    {item?.folder?.drawing?.is_put && (
                        <div className="absolute right-0 top-0 flex justify-center items-center bg-purple-300 text-purple-900 sm:rounded-tr-3xl rounded-bl-xl px-3 py-2">
                            <i className="fad fa-calculator mr-1 skew-x-12"/>
                            <p className="font-black skew-x-12">KALKYLE</p>
                        </div>
                    )}

                    {show_metadata && (
                        <Metadata item={item}/>
                    )}

                    {show_files && (
                        <FilesLister files={item?.folder?.current ?? []}/>
                    )}

                    {show_operations && (
                        <div className="relative mt-8">
                            <RouteItem item={item}/>
                        </div>
                    )}

                </>
            )}
        </div>
    )
}

export const ItemLister = ({ items }) => {
    const { show_files, show_operations, show_metadata } = useKanbanPreferences()
    if (!items || items.length === 0) return null

    if (!show_files && !show_operations && !show_metadata) {
        return (
            <div className="grid" style={{
                gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
                gridAutoRows: '300px',
                gridGap: '1rem'
            }}>
                {items?.map((item, index) => (
                    <Item item={item} key={index}/>
                ))}
            </div>
        )
    }

    return (
        <div className="grid" style={{ gridGap: '2rem' }}>
            {items?.map((item, index) => (
                <Item item={item} key={index}/>
            ))}
        </div>
    )
}

const Metadata = ({ item }) => {
    const to = routeURL(item.prod_order_no, item.prod_order_line_no, item.routing_no)

    return (
        <div className="flex items-center mt-4">
            <ProfilePicture email={item?.owner_email} className="max-h-16 rounded-full"/>
            <div className="flex flex-col justify-center ml-4">
                <Link to={to}>
                    <h3 className="font-medium hover:underline">{item.description}</h3>
                </Link>
                <div className="flex flex-wrap text-gray-400">
                    <p className="mr-4">{item.quantity}{getUnit(item.unit_of_measure ?? "")}</p>
                    <p className="mr-4">{item.no}</p>
                    <p className="mr-4">{item.prod_order_no}</p>
                    <p className="mr-4">{item.sales_order_no}</p>
                    <p className="mr-4">{item?.statistics?.absolute_percentage}%</p>
                </div>
            </div>
        </div>
    )
}