import axios from "axios"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useUserContext } from "../../navigation/routing"

const fetchComments = ({ queryKey }) => {
    const table = queryKey[1]
    const fkey = queryKey[2]
    return fetch(`/api/kanban/v1/comments/${table}/get?key=${fkey}`).then(async res => {
        if (res.status !== 200) throw new Error(await res.text())
        return res.json()
    })
    //return axios.get(`/api/kanban/v1/comments/${table}/get?key=${fkey}`)
}

export const useGetComments = (table, fkey) => {
    return useQuery(['comments', table, fkey], fetchComments,
        {
            refetchIntervalInBackground: false,
            refetchInterval: 60000,
        })
}


const postComments = ({ table, fkey, comment }) => {
    console.log("table, fkey: ", table, fkey)
    return axios.post(`/api/kanban/v1/comments/${table}/post?key=${fkey}&content=${comment}`)
}


export const usePostComment = () => {
    const queryClient = useQueryClient()

    return useMutation(postComments, {
        /*  onMutate: async (mutationobj) => {
             console.log("Mutationobj: ", mutationobj)
             const {comment, fkey, table} = mutationobj
 
             await queryClient.cancelQueries('comments')
 
             const previousData = await queryClient.getQueryData(['comments', table, fkey])
 
             queryClient.setQueryData(['comments', table, fkey], (oldData) => {
                 if (oldData.data) {
 
                     return {
                         ...oldData,
                         data: [...oldData.data, {
                             content: comment,
                             id: Math.random().toString(),
                             timestamp: new Date().toISOString(),
                             can_edit: true,
                             author: ""
                         }]
                     }
                 } else {
                     return {
                         data: [{
                             content: comment,
                             id: Math.random().toString(),
                             timestamp: new Date().toISOString(),
                             can_edit: true,
                             author: ""
                         }]
                     }
                 }
             })
         },
         onError: (_error, _obj, context) => (queryClient.setQueryData(context.previousData)), */
        onSettled: () => {
            queryClient.invalidateQueries('comments')
        }
    })
}


const updateComment = ({ table, id, comment, fkey }) => {
    console.log(fkey)
    return axios.put(`/api/kanban/v1/comments/${table}/update?id=${id}&content=${comment}&key=${fkey}`)
}

const deleteComment = ({ table, id, key }) => {
    console.log(key)
    return axios.delete(`/api/kanban/v1/comments/${table}/delete?id=${id}&key=${key}`)
}


const postPostitComments = ({ table, fkey, comment, color }) => {
    console.log("table, fkey: ", table, fkey)
    return axios.post(`/api/kanban/v1/comments/${table}/post?key=${fkey}&content=${comment}&color=${color}`)
}

export const useUpdatePostitComment = () => {
    const queryClient = useQueryClient()
    return useMutation(updateComment, {
        onMutate: async (mutationobj) => {

            const { table, fkey, comment, id } = mutationobj
            queryClient.setQueryData(['comments', table, fkey], (oldData) => {
                console.log("Old Data:", oldData)
                return [...oldData.map(c => {
                    if (c.id === id) {
                        c.content = comment
                    }
                    return c
                })]

            })
        },
        onSettled: () => {
            queryClient.invalidateQueries('comments')
        }
    })
}

export const useDeletePostitComment = () => {
    const queryClient = useQueryClient()
    return useMutation(deleteComment, {
        onMutate: async (mutationobj) => {
            const { table, fkey, id } = mutationobj
            queryClient.setQueryData(['comments', table, fkey], (oldData) => {
                return [...oldData.filter(c =>
                    (c.id !== id)
                )]
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries('comments')
        }
    })
}

export const usePostPostitComment = () => {
    const queryClient = useQueryClient()
    const { user } = useUserContext()

    return useMutation(postPostitComments, {
        onMutate: async (mutationobj) => {
            console.log("Mutationobj: ", mutationobj)
            const { comment, fkey, table, color } = mutationobj

            await queryClient.cancelQueries('comments')

            const previousData = await queryClient.getQueryData(['comments', table, fkey])

            queryClient.setQueryData(['comments', table, fkey], (oldData) => {
                console.log("Old Data:", oldData)
                console.log(user)

                if (oldData) {

                    return [...oldData, {
                        content: comment,
                        id: Math.random().toString(),
                        timestamp: new Date().toISOString(),
                        can_edit: true,
                        author: {email: user?.email, first_name: user?.name },
                        color
                    }]

                } else {
                    return [{
                        content: comment,
                        id: Math.random().toString(),
                        timestamp: new Date().toISOString(),
                        can_edit: true,
                        author: {email: user?.email, first_name: user?.name },
                        color
                    }]

                }
            })
        },
        onError: (_error, _obj, context) => (queryClient.setQueryData(context.previousData)),
        onSettled: () => {
            queryClient.invalidateQueries('comments')
        }
    })
}

