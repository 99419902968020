import { Link, useHistory as useHerstory } from 'react-router-dom'
import { useState } from 'react'
import { useQuery } from 'react-query'
import dayjs from 'dayjs'
import { ProfilePicture } from '../route/page'

export const KalkyleLister = () => {

    const { data, isLoading } = useQuery(['all', 'kalkyler'], () =>
        fetch('/api/kanban/v1/kalkyle/find/all')
            .then(async res => {
                if (res.status !== 200) throw new Error(await res.text())
                return res.json()
            }))

    return (
        <div className="max-w-5xl m-auto ">
            <h1 className="flex items-center text-green-400 px-4 rounded-lg text-3xl sm:text-7xl font-black transform skew-x-12 ">
                <i className="fad fa-shapes mr-4 "/>
                FORMGIVER
            </h1>
            <div
                className={`
                    relative mt-4 pt-16 py-8 flex flex-col 
                    bg-purple-800 dark:bg-purple-400 bg-opacity-5 dark:bg-opacity-5 
                    ring-4 ring-purple-600 ring-opacity-30 
                    sm:rounded-3xl min-h-screen`}>
                <Header/>
                {isLoading && (
                    <div className="flex justify-center items-center">
                    <i className="fad fa-spinner-third text-green-500 animate-spin text-2xl"/>
                    </div>
                )}
                <div className="flex flex-col">
                    {data?.map(kalkyle => (
                        <Cell key={kalkyle.id} {...kalkyle} />
                    ))}
                </div>
            </div>
        </div>
    )
}

const Header = () => {
    const { create } = useCreateKalkyle()
    return (
        <div className="absolute right-0 -top-10 justify-between items-center flex px-3 py-4">
            <button onClick={create}
                    className={`
                            px-3 py-1 
                            flex items-center justify-center
                            bg-white dark:bg-gray-900  
                            font-bold text-base
                            transition-all
                            transform sm:hover:scale-110
                            sm:hover:text-white sm:dark:hover:text-gray-900
                            text-green-400 sm sm:hover:bg-green-400 sm:dark:hover:bg-green-600
                            border-2 border-green-400 dark:border-green-500 rounded-full`}>
                <i className="fad fa-plus text-2xl mr-2"/> <span>Skap Form</span>
            </button>
        </div>
    )
}

const Cell = ({ id, owner, description, created_at, owner_id }) => {
    const day = dayjs(created_at).format('DD MMMM')
    return (
        <div className="grid items-center rounded-lg hover:bg-purple-500 transition-all hover:bg-opacity-10 transform px-10 py-2" style={{
            gridTemplateColumns: 'auto 1fr auto',
            gridGap: '0.5rem'
        }}>
            <ProfilePicture email={owner?.e_mail} className="max-h-14 rounded-full border-2 border-indigo-400 border-opacity-50"/>
            <div className="flex flex-col items-start justify-start">
                <Link to={`/kalkyle/create/${id}`}
                      className="font-bold hover:underline hover:text-purple-500">{description}</Link>
                <p className="text-gray-400">{owner_id}</p>
            </div>
            <div>
                <p className="text-gray-400 text-sm font-medium">{day}</p>
            </div>
        </div>
    )
}

const useCreateKalkyle = () => {
    const herstory = useHerstory()

    const [isError, setIsError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')

    const create = async () => {
        await setIsLoading(true)
        await setIsError(false)

        const res = await fetch('/api/kanban/v1/kalkyle/create', {
            method: 'POST'
        })
        if (res.status !== 200) {
            setIsError(true)
            setIsLoading(false)
            setError(await res.text())
            return
        }

        const json = await res.json()
        herstory.push(`/kalkyle/create/${json.id}`)
    }


    return {
        create
    }
}
