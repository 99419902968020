import { useEffect, useRef } from 'react'
import { useQuery } from 'react-query'
import { atom, useRecoilState } from 'recoil'
import dayjs from 'dayjs'
import { getUnit } from '../kanban/lager'

export const toobeltTextSearchAtom = atom({
    key: 'toolbelt-text-search-atom',
    default: ''
})

export const Toolbelt = () => {
    const [text, setText] = useRecoilState(toobeltTextSearchAtom)
    const { data, isLoading } = useMaterialSearch(text)
    const ref = useRef()

    useKeyPress('v', () => {
        ref.current.focus()
    }, { ignoreInput: false }, true)

    return (
        <div className="shadow-paper-sm border border-gray-100 dark:border-gray-800 dark:bg-gray-800 sm:rounded-xl flex flex-col px-3 py-2">
            <h3 className="text-center font-bold text-lg">
                <i className="fad fa-toolbox mr-1"/>
                Verktøybelte
            </h3>
            <p className="font-medium text-gray-400">Hva trenger vi for å lage dette?</p>

            <input ref={ref} type="text"
                   placeholder="Indicator, ITM-xoxo..."
                   onChange={e => setText(e.target.value)} value={text}
                   className={`
                        bg-gray-100 dark:bg-gray-700 
                        border-gray-200 border-2 dark:border-gray-600 focus:border-purple-400
                        rounded-md text-lg px-3 py-2 mt-1
                        outline-none focus:ring-4 ring-indigo-600 ring-opacity-50
                        transition-all`}
            />

            {isLoading && (
                <div className="flex justify-center items-center my-4">
                    <i className="fad fa-spinner-third animate-spin text-green-500"/>
                </div>
            )}
            <MaterialsLister materials={data} text={text}/>
        </div>
    )
}

const MaterialsLister = ({ text, materials }) => {
    return (
        <div className="flex flex-col my-2">
            {materials?.map((material, index) => (
                <Material key={material.no} material={material}/>
            ))}

            {text.length > 0 && (
                <div className="text-center">
                    <p className="dark:text-gray-400">Finner ikke delen du ser etter?</p>
                    <button
                        className="text-gray-600 dark:text-gray-200 sm:hover:bg-gray-100 sm:dark:hover:bg-gray-700 transition-colors px-6 py-1 rounded-md">Legg
                        til <span className="text-green-500 underline">{text}</span></button>
                </div>
            )}
        </div>
    )
}

const Material = ({ material }) => {

    const onDragStart = async event => {
        console.log("start: ", {event})
        if (event.dataTransfer.getData('text').length !== 0) return
        event.dataTransfer.setData('text/plain', JSON.stringify({
            origin: 'material',
            id: material.no
        }))
    }

    return (
        <div draggable onDragStart={onDragStart}
             className="group lift-from-paper hover:shadow-paper-md bg-white dark:bg-gray-800 sm:dark:hover:bg-gray-700 px-3 py-2 rounded-lg cursor-move">
            <p className="font-medium text-left">
                {material.inventory.length > 0 && (
                    <i className="fad fa-warehouse-alt text-green-500 mr-2"/>
                )}
                {material.description}
            </p>
            <p>{material.replenishment_system}</p>
            <p className="text-gray-500 dark:text-gray-300">{material.no}</p>
            <div className={`hidden ${material.inventory.length > 0 && 'group-hover:inline-block'}`}>
                <div className="flex flex-col ">
                    {material?.inventory?.map((inventory, index) => (
                        <div className="flex space-x-2 text-gray-400 items-center justify-start" key={index}>
                            <p>+</p>
                            <p className="font-bold">{inventory.stock_quantity.toFixed(2)}{getUnit(material.base_unit_of_measure)}</p>
                            <p>{dayjs(inventory.last_purchased_price).format('DD MMMM YYYY')}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export const useMaterialSearch = (searchText) => {

    const { data, isLoading, isFetching } = useQuery(['kalkyle', 'materials', searchText], () =>
        fetch(`/api/kanban/v1/kalkyle/materials/search?search_text=${encodeURIComponent(searchText)}`)
            .then(async res => {
                if (res.status !== 200) throw new Error(await res.text())
                return res.json()
            }), {
        enabled: searchText.length > 0
    })

    return {
        data, isLoading, isFetching
    }
}

export const useKeyPress = (key, handler, opts, enabled) => {
    useEffect(() => {
        if (!enabled) return

        const keypressHandler = event => {
            if (event.key !== key) return

            if (event.target.localName === 'input') {
                if (opts && opts.ignoreInput === false) return
            }

            handler(event)
        }

        document.addEventListener('keypress', keypressHandler)
        return () => document.removeEventListener('keypress', keypressHandler)
    }, [key, handler, enabled])
}