import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Link, useHistory as useHerstory, useLocation, useParams } from 'react-router-dom'
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react'

import { Components, getUnit } from '../kanban/lager'
import { Files } from '../files/files'
import { useClipboard } from '../pages/test'

import 'draft-js/dist/Draft.css'
import { AsBuiltImages } from '../asbuilt/lister'
import { atom, useRecoilState } from 'recoil'
import { toast } from 'react-hot-toast'
import { Operation } from '../kanban/operation'
import { PDFViewer } from '../kalkyle/item/page'
import { useGetComments } from '../components/comment/useComments'
import { PostitComment, PostPostitCommentComponent } from '../components/comment'
import { Image } from '../drawing/image'


export const RoutePage = () => {
    const { order, line, routing } = useRouteParams()
    const { isLoading, isFetching, isError, error, route, refetchMutation } = useRoute(order, line, routing)
    const herstory = useHerstory()
    const { data: comments, isFetching: isFetchingComment } = useGetComments("route-page", order + line + routing)


    useScrollRestoration()

    if (isError) return (
        <div>
            {error.message}
        </div>
    )

    if (isLoading) return (
        <div className="max-w-4xl m-auto">
            <div className="pt-32 flex justify-center">
                <i className="fad fa-spinner-third animate-spin text-6xl text-purple-400" />
            </div>
        </div>
    )

    return (
        <div className="max-w-5xl m-auto">
            <div className="flex justify-between p-4 text-purple-700 dark:text-purple-300">
                <button onClick={() => herstory.goBack()}>{'<- '} Tilbake</button>
                <button onClick={refetchMutation.mutate}>
                    <i className={`fad fa-redo ${refetchMutation.isLoading && 'animate-spin'}`}/>
                </button>
            </div>

            <div
                className="tc-shadows-sm dark:bg-gray-800 mx-8 p-2 border-t border-l border-r border-gray-100 dark:border-gray-900 rounded-t-2xl flex justify-center z-10 ">
                <Link className="px-8 py-2 text-center text-gray-800 dark:text-gray-100 hover:underline"
                      to={`/production/${route.prod_order_no}#${route.routing_reference_no}`}>
                    Prosjekt
                    <br />
                    <span className="font-bold">
                        {route?.po_desc}
                    </span>
                </Link>
            </div>

            <div className="min-h-screen pb-32 flex flex-col">
                <Route route={route} />
                <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 gap-y-16 p-6 my-10 relative'>
                    {/* <img src={korktavle} className='absolute w-full -z-20 object-cover h-full rounded-sm border-[1rem] border-orange-800 ' /> */}

                    {comments?.map((e, id) => {
                        return <PostitComment comment={e.content} canEdit={e.can_edit} commentId={e.id} author={e.author} timestamp={e.timestamp} color={e.color} table={"route-page"} fkey={order + line + routing} />
                    })}
                    {/* <PostCommentComponent table={'route-page'} fkey={order + line + routing} /> */}

                    <PostPostitCommentComponent table={"route-page"} fkey={order + line + routing} shouldBeSquare={true} />
                </div>
            </div>
        </div>
    )
}

export const useRouteParams = () => {
    const params = useParams()
    return {
        order: decodeURIComponent(params.order),
        line: decodeURIComponent(params.line),
        routing: decodeURIComponent(params.routing)
    }
}

const useScrollRestoration = () => {
    const location = useLocation()
    const [scroll, setScroll] = useRecoilState(useScrollPositionWithID(location.pathname))

    useLayoutEffect(() => {
        window.scroll(0, scroll)
    }, [location.pathname])

    const handleScroll = () => {
        setScroll(window.pageYOffset)
        console.log(window.pageYOffset)
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [])

}

const useScrollPositionWithID = (id) => {
    return useMemo(() => {
        return atom({
            key: id,
            default: 0
        })
    }, [id])
}

export const Route = ({ route }) => {
    const refreshMutation = useProductionRouteRefresh(route?.prod_order_no, route?.prod_order_line_no, route?.routing_no)

    return (
        <div className="w-full">
            <div
                className="bg-white shadow-paper-lg dark:bg-gray-800 border border-gray-100 dark:border-gray-900 rounded-2xl pt-4 z-20">
                <div  className="px-4">
                    {route?.folder?.drawing?.is_put ? (
                        <>
                            <PDFViewer id={route?.no ?? ''} resourceQuery="no" itemNo={route?.no ?? ''}/>
                        </>
                    ) : (
                        <div className="flex items-center justify-center h-64">
                            <Image no={route?.no ?? ''} className="rounded-3xl max-h-64 h-64" />
                        </div>
                    )}
                </div>

                <AsBuiltImages records={route?.as_built_records} itemNo={route?.no} />

                <div className="flex justify-center px-4">
                    <div className="flex items-center my-4">
                        <ProfilePicture email={route?.owner_email} className="max-h-16 rounded-full" />
                        <div className="flex flex-col justify-center ml-4 w-full">
                            <h3 className="font-medium text-gray-600 dark:text-gray-200">{route?.description}</h3>
                            <div className="flex items-center flex-wrap text-gray-400 gap-2">
                                <p>{route?.quantity}{getUnit(route?.unit_of_measure ?? "")}</p>
                                <p>·</p>
                                <p>{route?.no}</p>
                                <p>·</p>
                                <p>{route?.prod_order_no}</p>
                                <p>·</p>
                                <p>{route?.sales_order_no}</p>
                                <p>·</p>
                                <p>{route?.statistics?.absolute_percentage}%</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-wrap justify-center space-x-4">
                    <Label data={route}/>
                    <a href={`/api/kanban/v1/jump?order_no=${route?.sales_order_no}`} target="_blank">
                        <div
                            className="flex items-center justify-center bg-gradient-to-br from-purple-500 to-indigo-400 px-3 py-2 text-sm rounded-lg font-medium text-white">
                            <i className="fal fa-radar mr-2" /> Hopp til radar
                        </div>
                    </a>
                    <button onClick={refreshMutation.mutate}>
                        <i className={`fa-solid fa-arrow-rotate-right ${refreshMutation.isLoading && 'animate-spin'}`}>
                        </i>
                    </button>
                </div>

                <div className="px-2 sm:px-8 py-8">
                    <Files data={route} folder={route?.folder} />
                </div>

                <div className="py-4 px-2 sm:px-8">
                    <RouteItem item={route} />
                </div>
            </div>
        </div>
    )
}

const Label = ({ data }) => {
    const labelURL = `/api/kanban/v1/label?order_no=${encodeURIComponent(data.prod_order_no)}&line_no=${encodeURIComponent(data.routing_reference_no)}&routing_no=${encodeURIComponent(data.routing_no)}`
    const { copyBlob, isLoading } = useClipboard(labelURL)

    const handleCopy = async () => {
        await copyBlob()

        toast.success('Merkelapp kopiert!', {
            position: 'bottom-center'
        })
    }

    return (
        <div>
            <div className="flex">
                <a className="rounded-l-lg bg-gradient-to-br from-indigo-500 to-blue-400 hover:from-indigo-600 text-white font-medium px-3 py-2 text-sm"
                    href={labelURL}>
                    <i className="fad fa-tags" /> Merkelapp
                </a>
                <button onClick={handleCopy}
                    className="rounded-r-lg bg-gradient-to-br from-fuchsia-500 to-pink-400 hover:from-fuchsia-600 text-white font-medium px-3 py-2 text-sm focus:outline-none">
                    {isLoading ? (
                        <i className="fad fa-spinner-third animate-spin" />
                    ) : (
                        <i className="fad fa-clipboard" />
                    )}
                </button>
            </div>
        </div>
    )
}

export const Divider = ({ title, fn, children, subtitle, bg = 'dark:bg-gray-800' }) => {
    return (
        <div className={`${subtitle && 'mb-4'} text-sm font-bold grid items-center`}
            style={{ gridTemplateColumns: '1fr auto' }}>
            <h2 className="hdg--divider">
                <span className={`pr-2 bg-white ${bg} text-gray-700 dark:text-gray-100`}>
                    {title}
                </span>
                {subtitle && (
                    <span className="absolute left-0 top-full font-medium text-gray-500 dark:text-gray-400">
                        {subtitle}
                    </span>
                )}
            </h2>
            {children && (
                <div className="hdg--divider-button">
                    <button onClick={fn ?? null} className="flex items-center">
                        {children}
                    </button>
                </div>
            )}
        </div>
    )
}

export const profilePictureURL = (email) =>
    `/api/kanban/v1/kanban/profile-picture?email=${email}`
export const ProfilePicture = ({ name, email, className }) =>
    <img className={className}
        src={profilePictureURL(email)}
        alt="?" />

export const RouteItem = ({ item, ...props }) => (
    <div {...props}>
        <div className="relative">
            <div className="item-line absolute top-0 bottom-0 bg-gray-200 dark:bg-gray-500" />
            <Components
                orderNo={item.order_no} lineNo={item.routing_reference_no} routingNo={item.routing_no}
                components={item.components} />
            <div className="pt-4">
                {item?.operations?.map((op, index) => (
                    <Operation quantity={item.quantity} operation={op} key={index} />
                ))}
            </div>
        </div>
        <NextRoute item={item} />
    </div>
)


const NextRoute = ({ item }) => {
    const [open, setOpen] = useState(false)
    const toggle = () => setOpen(!open)

    const { replenishment_for: items, quantity } = item

    const f = quantity > 1 ? 'Disse' : 'Denne'

    if (items?.length === 0) return (
        <div className="flex items-center">
            <i className="fad fa-warehouse text-2xl w-8 h-8 fill-current text-gray-400" />
            <div className="ml-4 font-medium text-sm text-gray-500">
                <p>Ferdig!</p>
            </div>
        </div>
    )

    const makeSentance = (items) => {
        if (!items) return ''

        const sentanceBody = `${f} skal brukes i`

        if (items.length === 1) {
            const [item] = items
            return `${sentanceBody} ${item?.description}`
        }

        return `${sentanceBody} ${items.length} andre deler`
    }

    const sentence = makeSentance(items)

    return (
        <div className="flex items-start">
            <div className="">
                <i className="fad fa-warehouse w-8 h-8 fill-current text-purple-500" />
            </div>
            <div className="ml-4">
                <button className="font-medium text-sm" onClick={toggle}>
                    {sentence} <i className={`fas fa-chevron-${open ? 'up' : 'down'}`} />
                </button>
                {open && items?.map((item, index) => (
                    <div key={index} className="flex my-4">
                        <img className="rounded-xl z-20 max-w-sm max-h-32"
                            src={`/api/kanban/v1/media?item=${item.no}`} alt="" />
                        <div className="ml-4 flex flex-col">
                            <Link className="font-bold hover:underline"
                                to={`/route/${item.prod_order_no}/${item.prod_order_line_no}/${item.routing_no}`}>{item.description}</Link>
                            <p>{item.sales_order_no}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

const useProductionRouteRefresh = (order, line, routing) => {
    const client = useQueryClient()

    const handleRefetchMutationHandler = () =>
        fetch(`/api/kanban/v1/item?order_no=${encodeURIComponent(order)}&line_no=${encodeURIComponent(parseInt(line))}&routing_no=${encodeURIComponent(routing)}`, {
            method: 'DELETE',
            body: JSON.stringify({
                'order_no': order,
                'line_no': parseInt(line),
                'routing_no': routing
            })
        })

    const refetchMutation = useMutation(handleRefetchMutationHandler, {
        onSuccess: async () => {
            await client.invalidateQueries(["route", order, `${line}`, routing])
            await client.invalidateQueries(["production", "order", order])
            toast.success("Synkonisert!")
        }
    })

    return refetchMutation
}

export const useRoute = (order, line, routing) => {
    const client = useQueryClient()

    const handleGetRoute = () =>
        fetch('/api/kanban/v1/item', {
            method: 'POST',
            body: JSON.stringify({
                'order_no': order,
                'line_no': parseInt(line),
                'routing_no': routing
            })
        }).then(res => {
            return res.json()
        }).then(json => {
            console.log(json)
            return json
        })

    const handleRefetchMutationHandler = () =>
        fetch(`/api/kanban/v1/item?order_no=${encodeURIComponent(order)}&line_no=${encodeURIComponent(parseInt(line))}&routing_no=${encodeURIComponent(routing)}`, {
            method: 'DELETE',
            body: JSON.stringify({
                'order_no': order,
                'line_no': parseInt(line),
                'routing_no': routing
            })
        })

    const refetchMutation = useMutation(handleRefetchMutationHandler, {
        onSuccess: async () => {
            await client.invalidateQueries(["route", order, line, routing])
        }
    })

    const { data, ...rest } = useQuery(['route', order, line, routing], handleGetRoute, {
        refetchInterval: 5000
    })

    return {
        route: data,
        refetchMutation,
        ...rest
    }
}