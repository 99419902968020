import { useHistory, useParams } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import dayjs from 'dayjs'

export const AsBuiltPage = () => {
    const herstory = useHistory()
    const { id } = useParams()

    const { data, isLoading } = useQuery(['as-built', id], () =>
        fetch(`/api/kanban/v1/drawing/as-built?id=${id}`)
            .then(async res => {
                if (res.status !== 200) {
                    throw new Error(await res.text())
                }
                return res.json()
            })
    )

    return (
        <div className="min-h-screen max-w-4xl m-auto pb-32">

            <div className="flex px-3 py-2">
                <button onClick={() => herstory.goBack()} className="bg-purple-500 px-6 py-1 rounded-full text-white">
                    <i className="fas fa-chevron-left mr-2"/>
                    <span className="text-lg">Tilbake</span>
                </button>
            </div>
            <div className="">
                <img src={`/api/kanban/v1/drawing/as-built/download/full?id=${id}`} className="sm:rounded-xl m-auto" alt=""/>
                {isLoading ? (
                    <div className="flex justify-center items-center">
                        <i className="fad fa-spinner-third text-xl animate-spin"/>
                    </div>
                ):(
                    <div className="flex justify-between bg-gray-100 dark:bg-gray-800 px-3 py-2 mt-4 sm:rounded-xl">
                        <Metadata data={data}/>
                        <Delete data={data}/>
                    </div>
                )}
            </div>

        </div>
    )
}

const Delete = ({ data }) => {
    const { id, order_no, line_no, routing_no } = data

    const herstory = useHistory()

    const handler = () => fetch(`/api/kanban/v1/drawing/as-built?id=${id}`, {
        method: "DELETE"
    })

    const mutation = useMutation(handler, {
        onSuccess: () => {
            herstory.replace(`/route/${order_no}/${line_no}/${routing_no}`)
        }
    })

    const handleDelete = () => {
        mutation.mutate()
    }

    return (
        <button onClick={handleDelete} className="flex flex-col items-center justify-center bg-red-500 text-white font-bold px-3 py-2 rounded-xl">
            <i className="fas fa-trash"/>
            Slett
        </button>
    )
}

const Metadata = ({ data }) => {
    if (!data) return null

    const createdAt = dayjs(data.created_at).format('HH:mm DD MMMM YYYY')
    const { owner } = data

    const size = (data.size / 1000000).toFixed(1)

    return (
        <div>
            <p className="font-bold">{createdAt}</p>
            <p className="font-medium text-gray-700 dark:text-gray-400">{owner}</p>
            <p className="font-medium text-gray-700 dark:text-gray-400">{size}MB</p>
        </div>
    )
}