import React, { useLayoutEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Sopp from '../images/thune_sopp.svg'
import { Link, useHistory as useHerstory, useLocation } from 'react-router-dom'
import { useUserContext } from './routing'
import { NametagIcon } from '../components/nametag/icon'
import { useOutsideAlerter } from '../kanban/page'

const Navbar = () => {
    const location = useLocation()
    const herstory = useHerstory()
    const user = useUserContext()
    const [showMenu, setShowMenu] = useState(false)

    const showBack = location.pathname !== '/' && location.pathname !== '/oversikt'

    useLayoutEffect(() => {
        setShowMenu(false)
    }, [location.pathname])

    return (
        <div className="block p-2 m-auto max-w-6xl relative">
            <Container>
                <Left>
                    {showBack ? (
                        <BackButton className="sm:focus:ring-2 ring-pink-500 ring-offset-4 ring-offset-gray-900 ring-opacity-100 text-pink-100" onClick={() => herstory.push('/')}>
                            <i className="fas fa-home mr-1"/> Hjem
                        </BackButton>
                    ) : <span/>}
                </Left>
                <Logo className="transform active::scale-90" onClick={() => {
                    setShowMenu(!showMenu)
                }} src={Sopp}/>
                <Right>
                    <Link to="/user">
                        <NametagIcon fullName={user?.user?.name ?? ''}/>
                    </Link>
                </Right>
            </Container>
            {showMenu && (
                <Menu close={() => setShowMenu(false)}/>
            )}
        </div>
    )
}

const Menu = ({ close }) => {
    const ref = useRef()
    useOutsideAlerter(ref, close)
    return (
        <div className="z-30 mt-4 absolute max-w-sm w-full block left-1/2 transform -translate-y-1/2 -translate-x-1/2 top-full">
            <div ref={ref}
                 className="px-4 pb-4 pt-4 bg-gradient-to-br from-purple-400 via-indigo-400 to-blue-500 m-auto mt-4 rounded-2xl pop-open bg-red-500 w-full">

                <div className="mb-2">
                    <Link to="/kalkyle" className="w-full">
                        <div
                            className="flex flex-col flex-w items-center px-3 py-2 text-lg font-bold bg-indigo-200 bg-opacity-50 sm:hover:bg-opacity-80 transition-all duration-75 rounded-xl text-gray-900">

                            <i className="fad fa-shapes"/>
                            <p>
                                Formgiver
                            </p>
                        </div>
                    </Link>
                </div>

                <div className="flex space-x-2 ">
                    <Link to="/search" className="w-full">
                        <div
                            className="flex flex-col items-center px-3 py-2 text-lg font-bold bg-indigo-200 bg-opacity-50 sm:hover:bg-opacity-80 transition-all duration-75 rounded-xl text-gray-900">
                            <i className="fas fa-inbox"/>
                            <p>
                                Innboks
                            </p>
                        </div>
                    </Link>
                    <Link to="/app/herderi" className="w-full">
                        <div
                            className="flex flex-col items-center px-3 py-2 text-lg font-bold bg-indigo-200 bg-opacity-50 sm:hover:bg-opacity-80 transition-all duration-75 rounded-xl text-gray-900">
                            <i className="fad fa-oven"/>
                            <p>
                                Herderi
                            </p>
                        </div>
                    </Link>

                </div>
            </div>
        </div>
    )
}

const Logo = styled.img`
  width: 3rem;
  height: 3rem;
  cursor: pointer;
`

const Right = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
`

const Left = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  padding: 0 0 1rem 0;
  min-height: 4rem;
`


const BackButton = styled.button`
  font-family: "Inter var", sans-serif;
  background-color: var(--primary-pink);
  background: var(--pink-gradient);
  border-radius: 1rem;
  padding: .8rem 1rem;
  font-size: 1rem;

  @media (max-width: 800px) {
    font-size: .8rem;
  }
`

export default Navbar