import { useEffect, useState } from 'react'

export const keyParams = (routeKey, operationKey) => {
    return `route_key=${encodeURIComponent(routeKey)}&operation_key=${encodeURIComponent(operationKey)}`
}


export const useToggle = (defaultOpen) => {
    const [isOpen, setIsOpen] = useState(defaultOpen)
    const toggle = () => setIsOpen(!isOpen)
    const close = () => setIsOpen(false)
    const open = () => setIsOpen(true)

    return {
        isOpen, open, toggle, close
    }
}

export const useDefaultState = (defaultValue) => {
    const [value, setValue] = useState(defaultValue)
    useEffect(() => {
        setValue(defaultValue)
    }, [defaultValue])

    return [value, setValue]
}
