import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { ItemLister } from './item'
import useKanban from './useKanban'
import Spinner from '../components/spinner'
import { workCenterUrl } from '../home/page'
import { Divider } from '../route/page'
import * as d3 from 'd3'
import * as colors from 'tailwindcss/colors'
import dayjs from 'dayjs'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { atom, selector, useRecoilState, useRecoilValue } from 'recoil'
import { toast } from 'react-hot-toast'
import { matchSorter } from 'match-sorter'
import { CurrentManager, ResponsibleEmployee } from './responsible_employee'

const Kanban = () => {
    const { center, machine } = useParams()
    const { isLoading, error, kanban, isError, isFetching } = useKanban(center, machine)

    if (isError) return <p>{error.message}</p>

    if (isLoading) return (
        <div>
            <Spinner/>
        </div>
    )

    return (
        <div className="relative">
            <div className="sm:p-0 max-w-5xl m-auto" style={{ marginBottom: '30vh' }}>
                <WorkCenterLink center={center} name={kanban?.work_center?.name}/>
                <MachineCenterLinks kanban={kanban}/>
                <div className="grid grid-cols-12 gap-4 mt-4">
                    <div className="col-span-12">
                        <CurrentManager />
                    </div>
                    <div className="col-span-12 sm:col-span-6">
                        <Graph planned={kanban.item_group.planned.buckets}
                               available={kanban.item_group.available.buckets}
                               active={kanban.item_group.active.buckets}
                        />
                    </div>
                    <div className="col-span-12 sm:col-span-6">
                        <MenuBar/>
                    </div>
                </div>

                <ItemGroups kanban={kanban}/>
            </div>
        </div>
    )
}

const MenuBar = () => {
    const { prefs, isLoading } = useKanbanPreferencesQuery()
    const mutation = useKanbanPreferencesMutation()
    const [text, setText] = useRecoilState(itemGroupTextFilterState)

    return (
        <div
            className="flex flex-col sm:h-full space-y-3 shadow-paper-sm border border-gray-100 dark:border-transparent dark:bg-gray-800 rounded-lg p-2">
            <p className="font-bold text-gray-500 dark:text-gray-300 text-base">
                <i className="fad fa-eye"/> Hva vil du se? {mutation.isLoading && (
                <i className="fad fa-spinner-third animate-spin ml-2"/>
            )}
            </p>
            <div>
                <input type="text" id="item-group-components-filter"
                       onChange={e => setText(e.target.value)}
                       value={text}
                       placeholder="Plate T=25, 9920209, REG# 20-020..."
                       className="w-full bg-gray-100 dark:bg-gray-700 text-base px-3 py-2 rounded-lg outline-none focus:ring-2 ring-purple-500 ring-offset-2 ring-offset-gray-800 ring-opacity-50"/>
            </div>
            <div>
                {isLoading ? (
                        <div className="flex flex-col justify-center items-center">
                            <i className="fad fa-spinner-third animate-spin"/>
                        </div>
                    ) :
                    <MenuBarButtons mutation={mutation} showFiles={prefs.show_files} showMetadata={prefs.show_metadata}
                                    showOperations={prefs.show_operations}/>
                }
            </div>
        </div>
    )
}

const MenuBarButtons = ({ showFiles, showOperations, showMetadata, mutation }) => {

    const handleMutate = (n) => {
        const obj = Object.assign({}, {
            'show_files': showFiles,
            'show_operations': showOperations,
            'show_metadata': showMetadata
        }, n)
        mutation.mutate(obj)
    }

    return (
        <div className="flex flex-col flex-wrap sm:flex-row">
            <MenuBarButton selected={showFiles} onClick={() => handleMutate({ show_files: !showFiles })}
                           disabled={mutation.isLoading}>
                Filer
            </MenuBarButton>
            <MenuBarButton selected={showOperations} onClick={() => handleMutate({ show_operations: !showOperations })}
                           disabled={mutation.isLoading}>
                Operasjoner
            </MenuBarButton>
            <MenuBarButton selected={showMetadata} onClick={() => handleMutate({ show_metadata: !showMetadata })}
                           disabled={mutation.isLoading}>
                Metadata
            </MenuBarButton>
        </div>
    )
}

const kanbanPreferences = atom({
    key: 'kanban-preferences',
    default: {
        show_metadata: true,
        show_operations: true,
        show_files: true
    }
})

const useKanbanPreferencesMutation = () => {
    const client = useQueryClient()
    const handle = (prefs) =>
        fetch(`/api/kanban/v1/preferences/set`, {
            method: 'PUT',
            body: JSON.stringify(prefs)
        }).then(async res => {
            if (res.status !== 202) throw new Error(await res.text())
        })

    return useMutation(handle, {
        onSuccess: async () => {
            await client.invalidateQueries('kanban-preferences')
            toast.success('Sikten er endret')
        },
        onError: err => {
            toast.error('Noe gikk galt: ' + err)
            console.error(`[KanbanPreferences] failed: ${err}`)
        }
    })
}

export const useKanbanPreferences = () => useRecoilValue(kanbanPreferences)

const useKanbanPreferencesQuery = () => {
    const [prefs, setPrefs] = useRecoilState(kanbanPreferences)

    const handler = () =>
        fetch('/api/kanban/v1/preferences').then(async res => {
            if (res.status !== 200) throw new Error(await res.text())
            return res.json()
        })

    const { data, ...n } = useQuery('kanban-preferences', handler)

    useEffect(() => {
        if (data === undefined) return
        setPrefs(data)
    }, [data])


    return {
        prefs,
        ...n
    }
}

const MenuBarButton = ({ selected, children, ...n }) => {

    return (
        <button {...n}
                className={`px-6 py-1 m-1 rounded-md text-gray-700 dark:text-gray-200 sm:hover:bg-gray-200 sm:dark:hover:bg-gray-600 ${
                    selected && `
                bg-gray-100
                dark:bg-gray-700
            `
                }`}>
            {children}
        </button>
    )
}

const Tooltip = ({ dimensions, xScale }) => {
    const [date, setDate] = useState('')
    const tooltipSelection = d3.select(`#tooltip`)

    function onMouseMove(that) {
        const [mouseX, mouseY] = d3.pointer(that)
        console.log(`mx: ${mouseX}, my: ${mouseY}`)

        const xVal = xScale.invert(mouseX)

        setDate(dayjs(xVal).format('DD MMMM YYYY'))

        tooltipSelection.style(`opacity`, 1)
        tooltipSelection.style('transform', `translateX(${mouseX}px)`)
    }

    const onMouseLeave = () => {
        tooltipSelection.style(`opacity`, 0)
    }

    d3.select('#listening-rect')
        .on('mousemove', onMouseMove)
        .on('touchmove', onMouseMove)
        .on('mouseleave', onMouseLeave)
        .on('touchend', onMouseLeave)

    return (
        <div>
            <div
                className="absolute bottom-0 left-0 bg-gray-100 dark:bg-gray-700 px-3 rounded-t-xl pointer-events-none transition-opacity"
                id="tooltip">
                <p>{date}</p>
            </div>
        </div>
    )
}

const Graph = ({ planned, available, active }) => {
    const [ref, dimensions] = useChartDimensions()

    const all = [...planned, ...available, ...active]

    const xAccessor = d => {
        return new Date(d.date)
    }
    const yAccessor = d => d.hours

    const xExtent = d3.extent(all, xAccessor)
    const xScale = d3.scaleTime()
        .domain(xExtent)
        .range([0, dimensions.boundedWidth])

    const yExtent = d3.extent(all, yAccessor)
    const yScale = d3.scaleLinear()
        .domain([0, yExtent[1]])
        .range([dimensions.boundedHeight, 0])

    const xAccessorScaled = d => xScale(xAccessor(d))
    const yAccessorScaled = d => yScale(yAccessor(d))

    return (
        <div ref={ref} style={{ height: 200 }}
             className="w-full box-border relative shadow-paper-sm border border-gray-100 dark:border-transparent dark:bg-gray-800 rounded-xl">
            <Tooltip dimensions={dimensions} xScale={xScale}/>
            <svg width={dimensions.width} height={dimensions.height}>
                <g transform={`translate(${dimensions.marginLeft}, ${dimensions.marginTop})`}>
                    <Today dimensions={dimensions} xScale={xScale}/>

                    <Line data={active}
                          color={colors.red['600']}
                          xAccessor={xAccessorScaled}
                          yAccessor={yAccessorScaled}
                    />
                    <Line data={available}
                          color={colors.green['600']}
                          xAccessor={xAccessorScaled}
                          yAccessor={yAccessorScaled}
                    />
                    <Line data={planned}
                          color={colors.yellow['600']}
                          xAccessor={xAccessorScaled}
                          yAccessor={yAccessorScaled}
                    />
                    <Axis dimensions={dimensions} formatTick={d3.format('.0f')} scale={yScale}/>
                    <rect fill="transparent" id="listening-rect" width={dimensions.boundedWidth}
                          height={dimensions.boundedHeight}/>
                </g>
            </svg>
        </div>
    )
}

const Today = ({ dimensions, xScale }) => {
    const textX = xScale(new Date()) + 6
    const textY = dimensions.boundedHeight + dimensions.marginBottom / 2 + 6
    return (
        <g>
            <circle cx={xScale(new Date())} cy={dimensions.boundedHeight + dimensions.marginBottom / 2} r={3}
                    className="animate-pulse"
                    height={dimensions.boundedHeight} fill={colors.red['600']}/>
            <text fill={colors.red['700']} x={textX} y={textY} style={{
                transform: `translate(${textX}̋px, ${textY}px)`
            }}>
                Nå
            </text>
        </g>
    )
}

const Axis = ({ dimensions, label, formatTick, scale }) => {
    const ticks = scale.ticks(3)
    const textX = dimensions.marginLeft / 2

    return (
        <g>
            {ticks.map(tick => (
                <text fill={colors.gray['600']} key={tick} x={-textX} y={scale(tick)}>
                    {formatTick(tick)}t
                </text>
            ))}
        </g>
    )

}
const combineChartDimensions = dimensions => {
    let parsedDimensions = {
        marginTop: 40,
        marginRight: 30,
        marginBottom: 40,
        marginLeft: 75,
        ...dimensions
    }
    return {
        ...parsedDimensions,
        boundedHeight: Math.max(parsedDimensions.height - parsedDimensions.marginTop - parsedDimensions.marginBottom, 0),
        boundedWidth: Math.max(parsedDimensions.width - parsedDimensions.marginLeft - parsedDimensions.marginRight, 0)
    }
}
export const useChartDimensions = passedSettings => {
    const ref = useRef()
    const dimensions = combineChartDimensions(passedSettings)
    const [width, changeWidth] = useState(0)
    const [height, changeHeight] = useState(0)
    useEffect(() => {
        if (dimensions.width && dimensions.height) return
        const element = ref.current
        const resizeObserver = new ResizeObserver(entries => {
            if (!Array.isArray(entries)) return
            if (!entries.length) return
            const entry = entries[0]
            if (width != entry.contentRect.width) changeWidth(entry.contentRect.width)
            if (height != entry.contentRect.height) changeHeight(entry.contentRect.height)
        })
        resizeObserver.observe(element)
        return () => resizeObserver.unobserve(element)
    }, [])
    const newSettings = combineChartDimensions({
        ...dimensions,
        width: dimensions.width || width,
        height: dimensions.height || height
    })
    return [ref, newSettings]
}

const Line = ({ data, xAccessor, yAccessor, color = '#9980FA', interpolation = d3.curveMonotoneX }) => {
    const lineGenerator = d3.line()
        .x(xAccessor)
        .y(yAccessor)
        .curve(interpolation)

    return (
        <g>
            <path
                style={{
                    fill: 'none'
                }}
                className=""
                stroke={color}
                opacity={'80%'}
                d={lineGenerator(data) ?? ''}
            />
        </g>
    )
}

const WorkCenterLink = ({ center, name }) => (
    <div className="box-border max-w-full">
        <h1 className="flex items-center text-green-400 px-4 rounded-lg text-3xl sm:text-4xl md:text-7xl font-black transform skew-x-12 mb-4">
            <Link to={workCenterUrl(center, center)}>
            <span className="bg-white dark:bg-gray-900 px-2 hover:underline">
                {name.toUpperCase()}
            </span>
            </Link>
        </h1>
    </div>
)

const MachineCenterLinks = ({ kanban }) => {
    const { machine } = useParams()
    return (
        <div className="flex justify-center flex-wrap">
            {kanban?.work_center?.machines?.map(m => (
                <Link to={workCenterUrl(kanban?.work_center?.no, m?.no)}
                      key={`${kanban?.work_center.no}-${m.no}`}>
                    <div className={`
                            mr-2 mb-1
                            bg-gray-100 dark:bg-gray-800 px-4 py-2 rounded-md
                            sm:hover:bg-gray-200 sm:dark:hover:bg-gray-700
                            font-medium
                            ${machine === m.no && `bg-gradient-to-br from-pink-500 to-cyan-500 text-white`}
                    `}>
                        {m.name}
                    </div>
                </Link>
            ))}
        </div>
    )
}

const itemGroupTextFilterState = atom({
    key: 'itemGroupTextFilterState',
    default: ''
})

export const isFilteringItemGroupState = selector({
    key: 'isFilteringItemGroupState',
    get: ({ get }) => {
        return get(itemGroupTextFilterState).length > 0
    }
})

const ItemGroups = ({ kanban }) => {
    if (!kanban.item_group) return null
    const { active, available, planned } = kanban.item_group

    return (
        <div>
            <ItemGroup id="active" title={
                <span><i className="fas fa-fire text-red-500"/> Aktive</span>
            } subtitle={`Deler som jobbes med. Totalt ${active?.hours?.toFixed(0)} timer igjen.`} group={active.items}
                       showInitially={false}/>

            <ItemGroup id="ready" title={
                <span><i className="fas fa-check text-green-500"/> Klare</span>
            } subtitle={`Deler du kan starte på. Totalt ${available?.hours?.toFixed(1)} timer.`}
                       group={available.items ?? []}/>

            <ItemGroup id="planned" title={
                <span><i className="fas fa-calendar-day text-yellow-500"/> Planlagte</span>
            } subtitle={`Deler som trenger litt mer kjærlighet. Totalt ${planned?.hours?.toFixed(1)} timer.`}
                       group={planned.items} showInitially={false}/>

        </div>
    )
}

const useItemGroupAtom = (id, showInitially) =>
    useMemo(() => atom({
        key: `item-group-${id}`,
        default: showInitially
    }), [id])

const ItemGroup = ({ id, title, subtitle, group, showInitially = true }) => {
    const [show, setShow] = useRecoilState(useItemGroupAtom(id, showInitially))
    const filterText = useRecoilValue(itemGroupTextFilterState)

    const toggle = () => setShow(!show)

    const filteredGroup = useMemo(() => {
        if (filterText === "") return group
        const m = matchSorter(group ?? [], filterText, {
            keys: [
                'prod_order_no', 'sales_order_no',
                'owner_name', 'owner_email', 'description', 'no',
                itm => itm.folder.current.map(c => c.filename),
                itm => itm.components.map(c => c.description),
                itm => itm.components.map(c => c.item_no),
                itm => itm.components.map(c => c.item_no),
                itm => itm.operations.map(o => o.work_center_name)
            ],
            threshold: matchSorter.rankings.CONTAINS
        })
        return m
    }, [filterText, group])

    return (
        <div>
            <div className="py-4 px-2 sm:px-0">
                <Divider title={title} subtitle={subtitle} fn={toggle} bg="dark:bg-gray-900">
                    <Chevron show={show}/>
                    <p className="ml-2">{filteredGroup?.length ?? 0}stk</p>
                </Divider>
            </div>
            {(show) && (
                <ItemLister items={filteredGroup ?? []}/>
            )}
        </div>
    )
}

const Chevron = ({ show }) => {
    if (show) return <i className="fas fa-chevron-up"/>
    return <i className="fas fa-chevron-down"/>
}

export function getStickyValue(defaultValue, key) {
    const stickyValue = window.localStorage.getItem(key)
    return stickyValue !== null
        ? JSON.parse(stickyValue)
        : defaultValue
}

export function useStickyState(defaultValue, key) {
    const [value, setValue] = React.useState(() => {
        return getStickyValue(defaultValue, key)
    })
    React.useEffect(() => {
        window.localStorage.setItem(key, JSON.stringify(value))
    }, [key, value])

    return [value, setValue]
}

export function useOutsideAlerter(ref, fn) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                fn()
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [ref])
}


export default Kanban