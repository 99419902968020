import Sopp from '../images/thune_sopp.svg'
import { useMutation } from 'react-query'
import { useState } from 'react'
import { toast } from 'react-hot-toast'

export const SignUp = () => {
    const [email, setEmail] = useState("")
    const mutation = useSignUp(email)

    return (
        <div>
            <div className="p-4 flex items-center">
                <img className="max-h-8" src={Sopp} />
            </div>
            <div className="max-w-2xl m-auto mt-[20vh]">
                <div className="flex flex-col justify-center items-center">
                    <div className="flex space-x-2 ">
                        <i className="fa-solid fa-heart  text-red-500"/>
                        <i className="fa-regular fa-heart  text-red-500"/>
                        <i className="fa-regular fa-heart  text-red-500"/>
                    </div>
                    <p className="font-bold text-xl sm:text-3xl text-center">
                        Velkommen! Hva er E-Posten din?
                    </p>
                    <input
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                        autoFocus
                        className="my-4 px-3 py-2 text-xl sm:text-3xl focus:border-b-green-400 border-b-2 border-dashed dark:border-b-gray-500 focus:outline-none w-full text-center bg-transparent" />
                    <button onClick={mutation.mutate} className="text-3xl bg-purple-700 rounded-full font-bold px-6 py-3 text-white">
                        Neste ->
                    </button>
                </div>
            </div>
        </div>
    )
}

const useSignUp = (email) => {
    const handler = () => fetch(`/api/kanban/v1/sign_up/email?email=${encodeURIComponent(email)}`, {
        method: "POST"
    }).then(async res => {
            if (res.status !== 200) throw new Error(await res.text())
        })

    return useMutation(handler, {
        onError: (err) => {
            toast.error('' + err)
        },
        onSuccess: () => {
            toast.success("Bruker laget!")
        }
    })
}