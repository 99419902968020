import React from 'react'
import { useQuery } from 'react-query'

export const RouteStatus = {
    Error: -1,
    Undefined: 0,
    Planned: 1,
    InProgress: 2,
    Finished: 3
}

export const KanbanDispatch = React.createContext(null)
export const useKanbanDispatch = () => React.useContext(KanbanDispatch)

const useKanban = (center, machine) => {
    const url = `/api/kanban/v1/work-center?center=${center}&machine=${machine}`

    const { data, isLoading, isError, isFetching, error } = useQuery(["workcenter", center, machine], () =>
        fetch(url).then(res => {
            if (res.status !== 200) throw new Error("failed")
            return res.json()
        })
    , { refetchInterval: 10000 })

    return {
        error,
        isError,
        isLoading,
        isFetching,
        kanban: data,
    }
}

export default useKanban
