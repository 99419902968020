import { useEffect, useRef, useState } from 'react'
import { useMutation } from 'react-query'
import { useKalkyleCacheInvalidate } from './thought'
import { keyParams } from '../index'
import { useOutsideAlerter } from '../../kanban/page'
import { toast } from 'react-hot-toast'

export const DurationMenu = ({ runTime, setupTime, fullDuration, mutation }) => {
    const [show, setShow] = useState(false)

    const [oppstartHours, setOppstartHours] = useState(setupTime.hours)
    const [oppstartMinutes, setOppstartMinutes] = useState(setupTime.minutes)
    const [delHours, setDelHours] = useState(runTime.hours)
    const [delMinutes, setDeltMinutes] = useState(runTime.minutes)

    const toggle = () => setShow(!show)

    const ref = useRef()
    useOutsideAlerter(ref, () => {
        setShow(false)
    })


    const handleMutate = () => {
        setShow(false)
        mutation.mutate({
            oppstartHours, oppstartMinutes,
            delMinutes, delHours
        })
    }

    return (
        <div>
            <button onClick={toggle} className="px-3 text-gray-400 rounded-full sm:hover:bg-gray-100 sm:hover:dark:bg-gray-700">
                <i className="mr-1 far fa-clock"/>
                {mutation.isLoading ? (
                    <i className="fad fa-spinner-third animate-spin text-green-500"/>
                ) : (
                    <span>
                        {fullDuration.hours}h {fullDuration.minutes}m
                    </span>
                )}
            </button>
            {show && (
                <div ref={ref}
                     className="absolute right-2 left-2 top-full z-20 text-gray-800 bg-gradient-to-br from-blue-500 via-indigo-400 to-purple-400 rounded-xl sm:right-unset sm:max-w-md pop-open">
                    <div className="grid grid-cols-12 items-center p-4" style={{ gridGap: '0.5rem' }}>
                        <p className="col-span-12 text-lg font-bold text-center">Hvor lang tid kan dette ta?</p>

                        <DurationPicker
                            descripton="Oppstart"
                            hours={oppstartHours} minutes={oppstartMinutes} setHours={setOppstartHours}
                            setMinutes={setOppstartMinutes}
                            className="col-span-6 bg-indigo-300 bg-opacity-30 rounded-lg"/>

                        <DurationPicker
                            descripton="Del"
                            hours={delHours} minutes={delMinutes} setHours={setDelHours} setMinutes={setDeltMinutes}
                            className="col-span-6 bg-indigo-300 bg-opacity-30 rounded-lg"/>

                        <p className="col-span-12 font-medium">
                            Oppstartstid betyr arbeidet som må gjøres før første del blir produsert (f.eks. montere
                            brille).
                            Deltid er det arbeidet som gjøres for hver del.
                        </p>

                        <p className="col-span-4 text-center">
                            Totalt <span className="font-bold">n timer</span>
                        </p>

                        <div className="col-span-8">
                            <button
                                onClick={handleMutate}
                                disabled={mutation.isLoading}
                                className="px-8 py-1 w-full text-white bg-green-400 rounded-lg">
                                {mutation.isLoading ? (
                                    <i className="fad fa-spinner-third"/>
                                ) : 'OK, det stemmer bedre'}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

const DurationPicker = ({ descripton, hours, setHours, minutes, setMinutes, className }) => {
    const incrementHours = () => {
        setHours(p => p + 1)
    }
    const decrementHours = () => {
        if (hours === 0) return
        setHours(p => p - 1)
    }
    const incrementMinutes = () => {
        console.log('incremnt: ', typeof (minutes))
        const m = minutes + 5
        if (m >= 60) {
            setHours(p => p + 1)
            setMinutes(0)
            return
        }

        setMinutes(m)
    }
    const decrementMinutes = () => {
        const m = minutes - 5

        if (minutes <= 0) {
            if (hours === 0) return

            setHours(p => p - 1)
            setMinutes(55)
            return
        }

        setMinutes(m)
    }
    useEffect(() => {

    }, [hours, minutes])

    return (
        <div className={className}>
            <p className="font-bold text-center">{descripton}</p>
            <div className="flex justify-around">
                <div className="flex flex-col text-center">
                    <button className="text-gray-600 rounded-lg hover:bg-indigo-300 hover:bg-opacity-50"
                            onClick={incrementHours}>+
                    </button>
                    <p><span className="font-bold">{hours}</span> t</p>
                    <button className="text-gray-600" onClick={decrementHours}>-</button>
                </div>
                <div className="flex flex-col justify-center text-center">
                    <button className="text-gray-600" onClick={incrementMinutes}>+</button>
                    <p><span className="font-bold">{minutes}</span> min</p>
                    <button className="text-gray-600" onClick={decrementMinutes}>-</button>
                </div>
            </div>
        </div>
    )
}

export const usePublishedDurationMutation = (routingKey, operationKey) => {
    return useOperationDurationMutation(`/published-operation/duration?${keyParams(routingKey, operationKey)}`)
}
export const useThoughtOperationDurationMutation = (id) => {
    return useOperationDurationMutation(`/thought-operation/duration?id=${id}`)
}

const useOperationDurationMutation = (url) => {
    const { invalidateThoughtItems } = useKalkyleCacheInvalidate()

    const handle = ({ oppstartHours, oppstartMinutes, delHours, delMinutes  }) =>
        fetch(`/api/kanban/v1/kalkyle${url}`, {
            method: 'PUT',
            body: JSON.stringify({
                setup: {
                    hours: oppstartHours,
                    minutes: oppstartMinutes
                },
                operation: {
                    hours: delHours,
                    minutes: delMinutes
                }
            })
        }).then(async res => {
            if (res.status !== 202) throw new Error(await res.text())
        })

    return useMutation(handle, {
        onSuccess: async () => {
            await invalidateThoughtItems()
            toast.success('Varigheten er endret')
        },
        onError: err => {
            toast.error(`Error: ${err}` )
            console.error(`[OperationDuration] failed: ${err}`)
        }
    })
}

