import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'

export const useClipboard = (url) => {

    const {data, isLoading} = useQuery(["label", url], () =>
        fetch(url).then(async res => {
            if (res.status !== 200) {
                throw new Error(await res.status)
            }
            return res.blob()
        }))

    const copy = async (thang) => {
        await navigator.clipboard.writeText(thang)
    }

    const copyBlob = async () => {
        try {
            // eslint-disable-next-line no-undef
            const item = [new ClipboardItem({ 'image/png' : data })]
            await navigator.clipboard.write(item)
        } catch (err) {
            console.error(err)
        }
    }

    return {
        copy, copyBlob,
        isLoading
    }
}

export const useSnackbar = () => {
    const [showSnackbar, setShowSnackbar] = useState(false)

    useEffect(() => {
        if (!showSnackbar) return
        const timeout = setTimeout(() => {
            setShowSnackbar(false)
        }, 5000)

        return () => clearTimeout(timeout)
    }, [showSnackbar])

    return {
        show: showSnackbar,
        present: () => setShowSnackbar(true)
    }
}

export const TestPage = () => {
    const { status, copy } = useClipboard()

    const [showSnackbar, setShowSnackbar] = useState(false)

    const handleCopy = async () => {
        await copy('hei på deg :)')
        setShowSnackbar(true)
    }

    useEffect(() => {
        if (!showSnackbar) return
        const timeout = setTimeout(() => {
            setShowSnackbar(false)
        }, 5000)

        return () => clearTimeout(timeout)
    }, [showSnackbar])

    return (
        <div>
            <h1 className="text-blue-700">hei</h1>

            <p>{status}</p>

            <div className="flex">
                <a className="rounded-l-lg bg-indigo-500 hover:bg-indigo-600 text-white font-medium px-3 py-2 text-lg"
                   href="">
                    Merkelapp
                </a>
                <button onClick={handleCopy}
                        className="rounded-r-lg bg-fuchsia-400 hover:bg-fuchsia-500 text-white font-medium px-3 py-2 text-lg">
                    <i className="fad fa-clipboard"/>
                </button>
            </div>

            <div className={`
                    absolute left-0 right-0
                    transition-all
                    ${showSnackbar ? "bottom-8" : "-bottom-full"}`}>
                <div className="flex items-center m-auto max-w-sm bg-green-500 text-white px-3 py-2 rounded-xl">
                    <i className="fas fa-check text-xl"/>
                    <div className="ml-2">
                        <p className="font-medium p-0 m-0">
                            Merkelapp kopiert!
                            <br/>
                            <span className="font-regular text-green-100 p-0 m-0">
                                Nå er det bare lime den inn i printeren.
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const Snackbar = ({ show }) => {
    return (
        <div className={`
                    fixed left-0 right-0 z-40
                    transition-all
                    ${show ? "bottom-8" : "-bottom-full"}`}>
            <div className="flex items-center m-auto max-w-sm bg-green-500 text-white px-3 py-2 rounded-xl">
                <i className="fas fa-check text-xl"/>
                <div className="ml-2">
                    <p className="font-medium p-0 m-0">
                        Merkelapp kopiert!
                        <br/>
                        <span className="font-regular text-green-100 p-0 m-0">
                            Nå er det bare lime den i printer appen.
                        </span>
                    </p>
                </div>
            </div>
        </div>
    )
}