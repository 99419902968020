import { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { getUnit } from '../kanban/lager'
import { useRouteParams } from '../route/page'
import { toast } from 'react-hot-toast'

export const Consumption = ({ component, material }) => {
    const [quantity, setQuantity] = useQuantity(component.remaining_quantity)

    const mutation = useConsumptionMutation({
        orderNo: component.prod_order_no,
        lineNo: component.prod_order_line_no,
        routingNo: component.routing_no,
        itemNo: component.item_no,
        quantity: quantity,
        lotNo: material.lot_no,
        compNo: component.line_no,
        unitOfMeasure: material.unit_of_measure
    })

    return (
        <div className="">
            <p className="font-bold text-white px-1 text-lg">
                <i className="fas fa-cash-register mr-1 text-xl"/> Hvor mange {getUnit(component.unit_of_measure)} skal du forbruke?
            </p>
            <div className="flex justify-between items-center">
                <input value={quantity} onChange={setQuantity} type="number" autoFocus className={`
                    my-2
                    w-full px-3 py-2 
                    box-border
                    rounded-md 
                    bg-indigo-500 
                    text-white font-bold text-lg
                    outline-none ring-2 focus:ring-4 transition-all ring-indigo-200 ring-opacity-70`}
                />
                <p className="ml-2 font-bold">{getUnit(component.unit_of_measure)}</p>
            </div>
            <button onClick={mutation.mutate} disabled={mutation.isLoading} className="m-auto block px-6 py-1 my-2 transition-all bg-green-300 sm:hover:bg-green-200 sm:hover:ring-4 ring-2 ring-green-500 text-black rounded-lg">
                {mutation.isLoading ? (
                    <i className="fad fa-spinner-third animate-spin"/>
                ):(
                    <div className="items-center skew-x-12 font-black text-gray-900 text-base">
                        <i className="fad fa-sack-dollar mr-1"/>
                        FORBRUK
                    </div>
                )}
            </button>
        </div>
    )
}

const useQuantity = (init) => {
    const [quantity, setQuantity] = useState(init)

    const onChange = (event) => {
        const parsed = parseFloat(event.target.value)
        if (isNaN(parsed)) return
        if (parsed < 0) return
        setQuantity(parsed)
    }

    return [quantity, onChange]
}

const useConsumptionMutation = ({ orderNo, lineNo, compNo, itemNo, quantity, unitOfMeasure, lotNo }) => {
    const { routing, order, line, ...params } = useRouteParams()

    const queryClient = useQueryClient()

    const handler = () => fetch(`/api/kanban/v1/consumption`, {
        method: "POST",
        body: JSON.stringify({
            "order_no": orderNo,
            "line_no": lineNo,
            "component_line_no": compNo,
            "item_no": itemNo,
            "quantity": quantity,
            "unit_of_measure": unitOfMeasure,
            "lot_no": lotNo,
        })
    }).then(async res => {
        if (res.status !== 200) {
            throw new Error(`failed to consume: ${await res.text()}`)
        }
    })

    return useMutation(handler, {
        onSuccess: async () => {
            if (params.center && params.machine) {
                await queryClient.invalidateQueries(['workcenter', params.center, params.machine])
            }
            await queryClient.invalidateQueries(["route", order, line, routing])
            toast.custom(t => (
               <MaterialUsed t={t} unitOfMeasure={unitOfMeasure} quantity={quantity} />
            ))
        },
        onError: (msg) => {
            window.alert(msg)
        }
    })
}

export const Notification = ({ t, children }) => {
    return (
        <div className={`
            ${t.visible ? 'fade-in-up' : 'fade-out'}
            bg-green-500 dark:bg-green-600
            text-gray-900 dark:text-gray-100
            px-6 py-2 rounded-full
        `}>
            {children}
        </div>
    )
}

const MaterialUsed = ({ t, quantity, unitOfMeasure }) => {
    return (
        <Notification t={t}>
            <div className="flex space-x-2 items-center">
                <i className="fas fa-check"/>
                <div className="flex flex-col">
                    <p className="font-bold">{quantity}{getUnit(unitOfMeasure)} forbrukt!</p>
                </div>
            </div>
        </Notification>
    )
}
