import React, { useEffect, useState } from 'react'
import { Button, Container, Input } from './styles'
import { useHistory, useLocation } from 'react-router-dom'

const useVerify = () => {
    const herstory = useHistory()
    const [error, setError] = useState(null)

    const verify = (account, code) => {
        fetch(`/api/kanban/v1/verify?code=${code}&email=${account}`, {
            method: 'POST',
        }).then(res => {
            if (res.status !== 202) {
                setError(`${res.status} ${res.statusText}`)
                return
            }
            herstory.push("/")
        }).catch(err => {
            console.error(err)
            setError('ukjent error')
        })
    }

    return {
        verify,
        error,
    }
}

const Verify = () => {
    const location = useLocation()
    const { verify, error } = useVerify()
    const [code, setCode] = useState("")
    const [email, setEmail] = useState("")

    const handleSetCode = e => setCode(e.target.value)

    useEffect(() => {
        const params = new URLSearchParams(location.search)
        setEmail(params.get("email") ?? "")
    }, [location.pathname])

    if (error) return (
        <Container>
            <div>
                <h1>{error}</h1>
            </div>
        </Container>
    )

    return (
        <div>
            <Container>
                <div>
                    <h1 id="mega">Supert!</h1>
                    <p>Vi fant deg i Navision, og har sendt deg en <b>E-Post</b>.</p>
                    <p>Lim inn <b>koden</b> fra den under.</p>
                </div>
                <div>
                    <Input placeholder="xxxx-xxxx-xxxx-xxxx" onChange={handleSetCode} />
                </div>
                <Button onClick={() => {
                    console.log("weeeee")
                    verify(email, code)
                }}>
                    Verifiser
                </Button>
            </Container>
        </div>
    )
}

export default Verify