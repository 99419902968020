import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const Viewer = ({ Qr, closeButton, link, imageLink }) => {
    console.log("Viewer er her!")
    if (imageLink != undefined) {
        return (


            <div className="flex justify-items-start items-center max-h-2">
                <button className="p-0 m-0" onClick={() => closeButton()}>
                    <div className=" absolute inset-0 bg-black bg-opacity-70 z-30 p-0 m-0">
                    </div>
                    <div className="absolute p-16 top-0 left-0 w-full h-full z-40 flex flex-col justify-items-start items-center">
                    {link ? 
                    <Link to={link}>
                        <img className="rounded-lg object-contain max-w-full max-h-full flex-shrink m-0" src={imageLink} />
                    </Link>
                    :
                        <img className="rounded-lg object-contain max-w-full max-h-full flex-shrink m-0" src={imageLink} />
                    }
                        <button className=" border-4 rounded-b-lg w-20 h-12 m-0 flex-shrink-0" onClick={() => closeButton()}>Lukk</button>
                    </div>
                </button>
            </div>

        )
    }
    
    return null
}

export default Viewer;
