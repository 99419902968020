import { useQuery, useQueryClient } from 'react-query'
import { itemEntryQRCodeImageURL, useSetInventoryReolMutation } from './item'
import { Link } from 'react-router-dom'
import { getUnit } from '../kanban/lager'
import dayjs from 'dayjs'
import { TextMenu } from '../kalkyle/item/textMenu'
import { useRef } from 'react'
import { useFileUpload } from '../kalkyle/item/fileupload'
import { useDrop2 } from '../kalkyle/item/child'
import { toast } from 'react-hot-toast'
import { ProfilePicture } from '../route/page'

export const IncommingPurchaes = () => {
    const { inbox, isLoading } = useInbox()

    if (isLoading) return (
        <div className="max-w-7xl bg-gray-100/50 dark:bg-gray-800/50 animate-pulse min-h-screen rounded-2xl m-auto"/>
    )

    return (
        <div className="max-w-7xl m-auto rounded-2xl min-h-screen">
            <div className="bg-gray-100 dark:bg-gray-800 rounded-t-3xl px-2 py-4 sm:px-14 sm:py-8">
                <Today entries={inbox?.today ?? []}/>
            </div>

            <div className="bg-gray-50 dark:bg-gray-800/50 px-0 py-4 sm:px-14 sm:py-8">
                <div className="flex flex-col">
                    {inbox?.certificates?.map((p, i) => (
                        <Entry entry={p} key={i}/>
                    ))}
                </div>
            </div>
        </div>
    )
}

const Today = ({ entries }) => {
    if (entries.length === 0) return (
        <div className="min-h-[10rem] flex justify-center items-center flex-col">
            <i className="fa-solid fa-stars text-orange-400 text-2xl"/>
            <p className="text-gray-700 dark:text-gray-200">
                Ingenting Nytt for i Dag.
            </p>
        </div>
    )

    return (
        <div className="flex flex-col">
            {entries?.map((p, i) => (
                <Entry entry={p} key={i}/>
            ))}
        </div>
    )
}

const Entry = ({ entry }) => {
    const mutation = useSetInventoryReolMutation(entry.no)
    const client = useQueryClient()

    const ref = useRef()

    const didUpload = async () => {
        await client.invalidateQueries(['lager-rydder', 'dagens'])
        toast.success('Sertifikat Lastet opp!')
    }

    const {
        appendFiles,
        uploading
    } = useFileUpload(`/api/kanban/v1/inventory/certificate?entry_no=${encodeURIComponent(entry.no)}`, didUpload)
    const { isHovering, funcs } = useDrop2(() => {
    }, {}, appendFiles)

    const isUploading = uploading.length > 0

    const onClick = () => {
        ref?.current?.click()
    }

    return (
        <div
            style={{ gridTemplateColumns: '3.5rem 1fr auto', gridGap: ".5rem"}}
            {...funcs}
            className="grid items-center justify-between text-xs sm:text-sm relative group  sm:hover:dark:bg-gray-800 px-3 py-2 rounded-lg">

            <ProfilePicture email={entry?.purchaser?.e_mail ?? ''} className="w-[3.5rem] h-[3.5rem] rounded-full"/>

            <div className="box-border flex flex-col">
                <p className="font-bold sm:text-sm text-gray-900 dark:text-gray-100 truncate col-start-1 col-end-4 box-border">
                    {entry.material.description}
                </p>

                <div className="flex flex-col flex-wrap text-gray-400">
                    <div className="flex space-x-2 flex-wrap">
                        <VendorName vendor={entry?.vendor}/>
                        <p>-></p>
                        <ItemLink entry={entry}/>
                    </div>
                    <div className="px-1 flex space-x-2 text-sm flex-wrap">
                        <Quantity entry={entry}/>
                        <ChargeNo entry={entry} onClick={onClick} isUploading={isUploading}/>
                        <TextMenu text={entry.reol.no} placeholder="V30" positionClassName="m-auto"
                                  title="Hvor ligger denne?" mutation={mutation} className="">
                            <button
                                className="bg-gray-200 dark:bg-gray-700 px-2 text-gray-700 dark:text-gray-100 rounded-xl text-xs sm:text-sm font-mono">
                                <i className="fad fa-inventory text-xs"/>
                                {entry.reol.no.length > 0 && (
                                    <span className="ml-1 ">
                                     {entry.reol.no}
                                    </span>
                                )}
                            </button>
                        </TextMenu>
                        <div>
                            <a className="block m-auto text-green-900 dark:text-green-100"
                               target="_blank"
                               href={itemEntryQRCodeImageURL(entry.item_no, entry.no)}>
                                <i className="fad fa-tags"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex items-center whitespace-nowrap ">
                {dayjs(entry?.posting_date ?? '').format('DD MMM')}
            </div>

            <input style={{ display: 'none' }} type="file" multiple ref={ref} onChange={e => {
                const arr = []
                for (let i = 0; i < (e?.target?.files?.length ?? 0); i++) {
                    if (e.target?.files?.[i]) {
                        arr.push(e.target.files[i])
                    }
                }
                appendFiles(arr)
            }}/>

        </div>
    )
}

const VendorName = ({ vendor }) => {
    return (
        <p className="font-medium">{vendor?.name ?? '-'}</p>
    )
}

const ChargeNo = ({ entry, onClick, isUploading }) => {

    return (
        <button onClick={onClick}
                className="bg-purple-500 dark:bg-purple-900 text-purple-100 px-3 rounded-full flex items-center font-mono font-medium">
            {isUploading ? (
                <i className="fad fa-spinner-third animate-spin"/>
            ) : (
                <>
                    <i className="fas fa-file-certificate mr-1 text-xs"/>
                    <span>{entry.charge_no}</span>
                </>
            )}
        </button>
    )
}

const Quantity = ({ entry }) => {
    return (
        <div className="flex bg-gray-200 dark:bg-gray-700 text-gray-600 dark:text-gray-100 z-30 px-2 rounded-full font-mono">
            <p>
                {entry.quantity.toFixed(2)}
            </p>
            <p className="ml-1">
                {getUnit(entry.material.base_unit_of_measure)}
            </p>
        </div>
    )
}

const ItemLink = ({ entry }) => {
    return (
        <Link className="hover:underline hover:text-blue-500 block"
              to={`/item/${entry.item_no}/${entry.no}`}>{entry.item_no}</Link>
    )

}

const useInbox = () => {
    const { data, ...rest } = useQuery(['lager-rydder', 'dagens'], () =>
        fetch('/api/kanban/v1/inventory/incomming').then(async res => {
            if (res.status !== 200) throw new Error(await res.text())
            return res.json()
        })
    )

    return {
        inbox: data,
        ...rest
    }
}