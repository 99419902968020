import ReactGA from 'react-ga'

const GOOGLE_ANALYTICS_TRACKING_ID = 'UA-154231498-3'

const isProd = () => {
    if (typeof window === 'undefined') return false
    const { location } = window
    return (
        location.hostname === 'beta.thune.app' || location.hostname === 'kanban.thune.app'
    )
}

const initGoogleAnalytics = () => {
    if (!isProd()) {
        console.debug('[GOOGLE ANALYTICS] Goole analytics is disabled')
        return
    }

    ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING_ID)
}

const logPageView = (path) => {
    if (!isProd()) {
        console.debug(`[GOOGLE ANALYTICS] Page view: ${path}`)
        return
    }

    ReactGA.pageview(path)
}

export { initGoogleAnalytics, logPageView }
