import { useKalkyleCacheInvalidate } from '../operation/thought'
import { toast } from 'react-hot-toast'
import { useMutation } from 'react-query'
import { QuantityMenu } from './quantityMenu'
import { useParams } from 'react-router-dom'
import { useQuantityMutaiton } from './menubar'
import { getUnit } from '../../kanban/lager'
import { useToggle } from '../index'
import { Image } from '../../drawing/image'

export const Material = ({ material }) => {
    const quantityMutation = useQuantityMutaiton(material)
    const removeMutation = useRemoveMaterialMutation(material)

    return (
        <div
            className="relative flex flex-col items-center shadow-inner border-2 border-stone-100 dark:border-gray-700 dark:bg-gray-700/50 px-3 py-2 rounded-lg m-1">
            <Image no={material.no} className="max-h-20 rounded-lg"/>
            <div className="">
                <p className="font-medium">{material.description}</p>
                <p className="text-gray-400">{material.no}</p>
                <div className="flex justify-between">
                    <QuantityMenu mutation={quantityMutation}
                                  quantityTotal={material.quantity}
                                  quantityPer={material.quantity}
                                  unitOfMeasure={material.unit_of_measure}
                    />
                    <button className="" onClick={removeMutation.mutate} disabled={removeMutation.isLoading}>
                        {removeMutation.isLoading ? (
                            <i className="fad fa-spinner-third text-green-400 animate-spin"/>
                        ) : (
                            <i className="fad fa-trash text-gray-400"/>
                        )}
                    </button>
                </div>

                <Inventory material={material}/>
            </div>
        </div>
    )
}

const Inventory = ({ material }) => {
    const { isOpen, toggle } = useToggle(false)
    return (
        <div>
            <button className="block mx-auto my-0 p-0" onClick={toggle}>
                {isOpen ? (
                    <i className="fad fa-chevron-up"/>
                ) : (
                    <i className="fad fa-chevron-down"/>
                )}
            </button>

            {isOpen && (
                <div>
                    {material?.inventory?.map((inv, index) => (
                        <div className="flex justify-between text-gray-600 dark:text-gray-400">
                            <p>{inv.stock_quantity}{getUnit(material.unit_of_measure)}</p>
                            <p>{inv.lot_no}</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export const HoverMaterial = () => {
    return (
        <div className="flex flex-col bg-gray-100 dark:bg-gray-700 px-3 py-2 rounded-lg m-1">
            <i className="fad fa-spinner-third text-green-400 animate-spin"/>
        </div>
    )
}

const useRemoveMaterialMutation = (item) => {
    const { invalidateThoughtItems } = useKalkyleCacheInvalidate()

    let uri = `/thought-item/remove?id=${item.id}`
    if (item.external_metadata.published) {
        uri = `/materials/remove?parent_key=${encodeURIComponent(item.external_metadata.parent_key)}&material_key=${encodeURIComponent(item.external_metadata.key)}`
    }

    const handle = () =>
        fetch(`/api/kanban/v1/kalkyle${uri}`, {
            method: 'DELETE'
        }).then(async res => {
            if (res.status !== 202) throw new Error(await res.text())
        })

    return useMutation(handle, {
        onSuccess: async () => {
            await invalidateThoughtItems()
            toast.success('Materiale fjernet!')
        },
        onError: err => {
            toast.error('Noe gikk galt: ' + err)
            console.error(`[RemoveMaterial] failed: ${err}`)
        }
    })
}

export const useAddMaterialMutation = (itemID, callbackFn) => {
    const { id } = useParams()
    const { invalidateThoughtItems } = useKalkyleCacheInvalidate()

    const handle = (materialNo) => {
        return fetch(`/api/kanban/v1/kalkyle/materials/add?kalkyle_id=${id}&parent_id=${encodeURIComponent(itemID)}&no=${encodeURIComponent(materialNo)}`, {
            method: 'POST'
        }).then(async res => {
            if (res.status !== 202) throw new Error(await res.text())
        })
    }

    return useMutation(handle, {
        onSuccess: async () => {
            await invalidateThoughtItems()
            if (callbackFn) {
                callbackFn()
            } else {
                toast.success('Materiale lagt til!')
            }
        },
        onError: err => {
            toast.error('Error: ' + err)
            console.error(`[AddMaterial] failed: ${err}`)
        }
    })
}
