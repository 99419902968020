import { useRef } from 'react'
import { useDefaultState, useToggle } from '../index'
import { useOutsideAlerter } from '../../kanban/page'
import { useKeyPress } from '../toolbelt'

export const TextMenu = ({ title, text = "", mutation, children, className, positionClassName, placeholder }) => {
    const ref = useRef()
    const { isOpen, close, toggle, open } = useToggle(false)
    const [newNext, setNewText] = useDefaultState(text)

    useOutsideAlerter(ref, close)

    const handleMutate = () => {
        close()
        mutation.mutate(newNext)
    }

    useKeyPress('Enter', handleMutate,{}, isOpen)
    useKeyPress('Escape', close,{}, isOpen)

    return (
        <div ref={ref}>
            <button onClick={toggle} className={className}>
                {mutation.isLoading ? (
                    <i className="fad fa-spinner-third animate-spin text-green-500"/>
                ) : children}
            </button>
            {isOpen && (
                <div className="absolute top-full inset-0 z-50 pop-open">
                    <div
                        className={`
                            ${positionClassName}
                            max-w-md 
                            bg-gradient-to-br text-gray-800 from-blue-400 via-indigo-400 to-purple-500 
                            p-3 rounded-xl
                            flex flex-col`}>
                        <p className="font-bold text-base">{title}</p>
                        <input type="text" placeholder={placeholder ?? "ITM-1234"} value={newNext}
                               autoFocus
                               onChange={e => setNewText(e.target.value)}
                               className="mt-1 font-medium bg-gray-800 text-white rounded-lg w-full px-3 py-2 outline-none focus:ring-4 ring-indigo-300"/>
                        <button onClick={handleMutate}
                                className="bg-green-400 active:ring-2 ring-gray-800 outline-none mt-3 text-gray-800 m-auto px-6 py-1 rounded-full text-md skew-x-12 font-black">
                            SÅNN
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}
