import { PublishMenu } from './child'
import { useMutation } from 'react-query'
import { useKalkyleCacheInvalidate } from '../operation/thought'
import { toast } from 'react-hot-toast'
import { Description } from './description'
import { Tag } from './tag'
import { QuantityMenu } from './quantityMenu'

export const ItemMenuBar = ({ item }) => {
    const { id, tag, description, quantity, price } = item
    const quantityMutaion = useQuantityMutaiton(item)

    return (
        <div className="">
            <div className="relative">
                <Description id={id} description={description}/>
            </div>
            <div className="relative flex flex-wrap dark:text-gray-400">
                <Tag id={id} tag={tag} isPut={item?.drawing?.exists ?? false}/>
                <QuantityMenu title="Hvor mange skal vi lage?" unitOfMeasure={item.unit_of_measure}
                              itemNo={item.tag}
                              quantityPer={quantity} quantityTotal={quantity}
                              mutation={quantityMutaion}
                              className="font-bold px-2 sm:dark:hover:bg-gray-700 rounded-md sm:hover:no-underline"
                />
                <Price fullPrice={price?.in_full ?? 0}/>
                <PublishMenu published={item?.external_metadata?.published ?? false} id={id}/>
            </div>
        </div>
    )
}

const Price = ({ fullPrice }) => {
    return (
        <p className="flex items-center space-x-1 px-1">
            <i className="fad fa-coin"/>
            <span className="font-medium">
                {fullPrice?.toFixed(0) ?? '?'} kr
            </span>
        </p>
    )
}

export const useRemoveItemMutation = (itemID) => {
    const { invalidateThoughtItems } = useKalkyleCacheInvalidate()

    const handleRemoveItem = () =>
        fetch(`/api/kanban/v1/kalkyle/thought-item/remove?id=${itemID}`, {
            method: 'DELETE'
        }).then(async res => {
            if (res.status !== 202) throw new Error(await res.text())
        })

    const removeMutation = useMutation(handleRemoveItem, {
        onSuccess: async () => {
            await invalidateThoughtItems()
        }
    })

    return {
        removeMutation
    }
}

export const useQuantityMutaiton = (item) => {
    const { invalidateThoughtItems } = useKalkyleCacheInvalidate()

    let query = `?id=${item.id}`
    if (item.external_metadata.published) {
        query = `?key=${encodeURIComponent(item.external_metadata.key)}&parent_key=${encodeURIComponent(item.external_metadata.parent_key)}`
    }

    const handleMutate = ({ quantity }) => fetch(`/api/kanban/v1/kalkyle/item/quantity${query}`, {
        method: 'PUT',
        body: JSON.stringify({
            quantity
        })
    })

    return useMutation(handleMutate, {
        onSuccess: async data => {
            await invalidateThoughtItems()
            toast.success(`Antallet endret!`)
        }
    })
}