import { useDrop2 } from '../kalkyle/item/child'
import { Link, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { getUnit } from '../kanban/lager'
import { TextMenu } from '../kalkyle/item/textMenu'
import { useMutation, useQueryClient } from 'react-query'
import { useRecoilValue } from 'recoil'
import { toobeltTextSearchAtom } from '../kalkyle/toolbelt'
import { toast } from 'react-hot-toast'
import { useFileUpload } from '../kalkyle/item/fileupload'

export const ListItem = ({ item }) => {
    const { uploading, appendFiles } = useFileUpload(`/api/kanban/v1/kalkyle/item/image?no=${encodeURIComponent(item.no ?? '')}`)
    const { isHovering, funcs } = useDrop2(() => {
    }, {}, appendFiles)

    return (
        <div {...funcs}
             className={`${isHovering ? " shadow-[0_0_2rem] shadow-purple-400/50" : " "} transition-shadow rounded-lg border-gray-100 dark:border-gray-900  border dark:bg-gray-800 p-2`}>
            {uploading.map(u => (
                <p>uploading</p>
            ))}
            <div className="flex flex-col justify-center items-center mb-2 text-center">
                <Image no={item.no} />
                <Link to={`/search/${item.no}`}
                      className="text-gray-900 dark:text-gray-200 text-xl font-bold">{item.description}</Link>
                <p className="text-gray-800 dark:text-gray-300 text-lg">{item.no}</p>
            </div>
            <div className="flex flex-col space-y-2 w-full p-2">
                {item?.inventory?.map((inv) =>
                    <Inventory key={inv.entry_no} itemNo={item.no} itemBaseUnitOfMeasure={item.base_unit_of_measure}
                               inventory={inv}/>
                )}
            </div>
        </div>
    )
}

const Image = ({ no }) => {
    return (
        <img className="rounded-xl z-20 max-w-sm max-h-32" src={`/api/kanban/v1/media?item=${encodeURIComponent(no)}`} alt=""/>
    )
}

export const itemEntryQRCodeImageURL = (itemNo, entryNo) => `/api/kanban/v1/kalkyle/materials/search/getqr?item_no=${itemNo}&entry_no=${entryNo}`

const Inventory = ({ itemNo, itemBaseUnitOfMeasure, inventory }) => {
    const { entry_no, lot_no } = inventory
    const purchaseDate = dayjs(inventory.last_purchased_price).format('DD MMMM YYYY')

    const mutation = useSetInventoryReolMutation(inventory.entry_no)

    return (
        <div
            className="relative flex space-x-2 items-center p-2 bg-purple-400 bg-opacity-5 border-2 border-purple-400 border-opacity-5 rounded-lg">
            <div>
                <a className="block m-auto text-purple-900 dark:text-purple-100 text-lg"
                   target="_blank"
                   href={itemEntryQRCodeImageURL(itemNo, entry_no)}>
                    <i className="fad fa-tags"/>
                </a>
            </div>
            <div className=" flex flex-wrap justify-between items-center w-full">
                <div>
                    <a className="text-left text-purple-900 dark:text-purple-100 w-full text-lg sm:hover:underline"
                       href={itemEntryQRCodeImageURL(itemNo, entry_no)}
                    >{lot_no ?? '-'}</a>
                    <p className="text-purple-900 dark:text-purple-100">{inventory.stock_quantity}{getUnit(itemBaseUnitOfMeasure)}</p>
                    <p className="text-purple-900 dark:text-purple-100">{purchaseDate}</p>
                </div>
                <div>
                    <TextMenu text={inventory.reol.no} placeholder="V30" positionClassName="ml-auto"
                              title="Hvor ligger denne?" mutation={mutation} className="">
                        <button className="text-purple-900 dark:text-purple-100">
                            {inventory.reol.no}
                            <i className="fad fa-inventory ml-2"/>
                        </button>
                    </TextMenu>
                </div>
            </div>
        </div>
    )
}

export const useSetInventoryReolMutation = (entryNo, callbackFn) => {
    const client = useQueryClient()
    const searchTerm = useRecoilValue(toobeltTextSearchAtom)

    const { order, line, routing, center, machine } = useParams()

    const handle = (no) =>
        fetch(`/api/kanban/v1/inventory/reol?entry_no=${encodeURIComponent(entryNo)}`, {
            method: 'PUT',
            body: JSON.stringify({
                no
            })
        })

    return useMutation(handle, {
        onSuccess: async () => {
            await client.invalidateQueries(['kalkyle', 'materials', searchTerm])
            await client.invalidateQueries(['lager-rydder', 'dagens'])

            if (order && line && routing) {
                await client.invalidateQueries(['route', order, line, routing])
            }

            if (center && machine) {
                await client.invalidateQueries(["workcenter", center, machine])
            }

            if (callbackFn) {
                callbackFn()
            }

            toast.success('reol oppdatert!')
        },
        onError: err => {
            toast.error('kunne ikke oppdatere reol: ' + err)
            console.error(`[SetInventoryReol] failed: ${err}`)
        }
    })
}