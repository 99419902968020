import React, { useEffect, useRef, useState } from 'react'
import { useOutsideAlerter } from './page'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-hot-toast'
import { useRouteParams } from '../route/page'
import { Notification } from '../consumption/menu'
import { useToggle } from '../kalkyle'
import { Link, useParams } from 'react-router-dom'
import { useSnackbarContext } from '../navigation/routing'
import { WorkCenterMenu, WorkCenterMenuKanban } from '../kalkyle/operation/work_center'
import { WindowWithCoin } from './coin'

dayjs.extend(duration)

export const Operation = ({ quantity, operation }) => {
    const { isOpen, toggle } = useToggle(false)
    const { center, machine } = useParams()
    const diff = dayjs(operation.ending_date).diff(dayjs(), 'days')

    const { parts_manufactured, hours_used, is_done, hours_allocated, status } = operation.metadata
    const { prod_order_no, routing_reference_no, routing_no, operation: operation_no, task } = operation
    const { work_center_name, work_center_no, machine_center_no } = operation
    const { storage_location: storage } = operation
    const d = `mr-8`

    const active =
        (center === work_center_no && machine === machine_center_no)
        && (is_done === false)

    const transferOwnershipURL = `/transfer-ownership/${encodeURIComponent(prod_order_no)}/${encodeURIComponent(routing_reference_no)}/${encodeURIComponent(routing_no)}/${encodeURIComponent(operation_no)}`
    const hasLocation = storage.in_stock && storage.location.length > 1

    return (
        <div className="relative grid grid-cols-auto-fr items-center mb-2" style={{ gridGap: '0.5rem' }}>
            <OperationStatusIcon status={status} />
            <div className={`
                    flex justify-between items-center
                    p-4 rounded-2xl
                    ${active === true && `bg-warm-100 dark:bg-gray-700`}
                    ${active === false && `text-warm-400`}
                `}>
                <div>
                    <Title operation={operation} />

                    <WorkCenterMenuKanban
                        name={work_center_name}
                        prodOrderNo={prod_order_no}
                        routingRefNo={routing_reference_no}
                        routingNo={routing_no}
                        operationNo={operation_no}
                    />

                    <div className="flex flex-wrap items-center">
                        {operation.events.length > 0 ? (
                            <button className="mr-8 sm:hover:text-indigo-500 sm:hover:underline font-normal"
                                onClick={toggle}>
                                <i className="fad fa-fingerprint" /> {task}
                            </button>
                        ) : (
                            <p className={d}>
                                <i className="fad fa-fingerprint" /> {task}
                            </p>
                        )}
                        <p className={d}>
                            <i className="fad fa-calendar" /> {`${diff} dager`}
                        </p>
                        <ReportHours operation={operation}
                            className="hover:underline font-normal hover:font-bold hover:text-indigo-500">
                            <p className={d}>
                                <i className="far fa-clock" /> {hours_used.toFixed(1)} / {hours_allocated.toFixed(1)}
                            </p>
                        </ReportHours>
                        <LinkOrNot active={!is_done} url={transferOwnershipURL}>
                            <p className={`${d} `}>
                                <i className="fas fa-cubes" /> {parts_manufactured} / {quantity}
                            </p>
                        </LinkOrNot>
                        {hasLocation && (
                            <p className={d}>
                                <i className="fas fa-inventory" /> {storage.location}
                            </p>
                        )}
                    </div>
                    <EventsLister show={isOpen} operation={operation} />
                </div>
            </div>
        </div>
    )
}

export const Title = ({ operation }) => {
    const ref = useRef(null)
    const [show, setShow] = useState(false)
    const [title, setTitle] = useState(operation.description)

    useEffect(() => {
        setTitle(operation.description)
    }, [operation])

    const mutation = useOperationTitleMutation(title, operation, () => {
    })

    const handleShow = () => setShow(true)
    const handleTitle = event => {
        setTitle(event.target.value)
    }

    const handleMutate = () => {
        setShow(false)
        mutation.mutate()
    }

    useEffect(() => {
        if (show) {
            ref.current.focus()
        }
    }, [show])

    return (
        <>
            <button className="text-gray-600 text-left dark:text-gray-200" onClick={handleShow}>
                {mutation.isLoading ? (
                    <i className="fad fa-spinner-third animate-spin text-green-500" />
                ) : operation.description}
            </button>
            <TouchOutside update={setShow}>
                <ToggleDiv
                    toggle={show}
                    inactiveClassName="hidden"
                    className="pop-open absolute left-0 right-0 sm:left-4 sm:right-unset max-h-60 min-w-20 z-20 bg-gradient-to-br from-blue-600 via-indigo-600 to-purple-600 rounded-3xl p-4 grid grid-cols-6">
                    <p className="p-0 m-0 text-lg col-span-6 text-white font-bold mb-2 text-center">
                        Hva vil du kalle oppgaven?
                    </p>
                    <input ref={ref} type="text"
                        className="focus:ring focus:ring-green-300 text-warm-700 font-bold outline-none block text-lg col-span-6 p-4 bg-white rounded-2xl"
                        value={title}
                        onChange={handleTitle} autoFocus />
                    <div className="col-span-6">
                        <button
                            className="bg-green-400 m-auto block mt-4 rounded-2xl px-8 py-1 text-white"
                            onClick={handleMutate}>
                            Lagre
                        </button>
                    </div>
                </ToggleDiv>
            </TouchOutside>
        </>
    )
}

const EventsLister = ({ show, operation }) => {
    if (!show) return null
    return operation.events?.map((event, index) => (
        <Event event={event} key={index} />
    ))
}

const Event = ({ event }) => {

    const isHoursEvent = event.quantity > 0

    return (
        <div className="flex space-x-2">
            {isHoursEvent ? (
                <p>{event.quantity.toFixed(1)}t</p>
            ) : (
                <p>{event.output_quantity}stk</p>
            )}
            <p>·</p>
            <p>{event.resource_name}</p>
            <p>·</p>
            <p>{dayjs(event.posting_date).format('DD MMM YYYY')}</p>
        </div>
    )
}

const LinkOrNot = ({ url, active, children }) => {
    if (!active) return children

    return <Link to={url} className="hover:underline hover:font-bold hover:text-indigo-500">
        {children}
    </Link>

}

const useReportHours = (operation, onSuccess) => {
    const params = useParams()
    const client = useQueryClient()
    const snackbarContext = useSnackbarContext()

    const reportHours = report => fetch('/api/kanban/v1/report-hours', {
        method: 'POST',
        body: JSON.stringify({
            'task': operation.task,
            'hours': report.hours,
            'minutes': report.minutes
        })
    }).then(async res => {
        if (res.status !== 200) {
            throw new Error(await res.text())
        }
    })

    return useMutation(reportHours, {
        onSuccess: async () => {
            await client.invalidateQueries(['route', operation.prod_order_no, `${operation.routing_reference_no}`, operation.routing_no])
            await client.invalidateQueries(['production', 'order', operation.prod_order_no])

            if (params.center && params.machine) {
                await client.invalidateQueries(['workcenter', params.center, params.machine])
            }

            onSuccess()
            snackbarContext.setSnackbar()
        },
        onError: (error) => {
            window.alert(error.message)
        }
    })
}

const ReportHours = ({ operation, children, ...props }) => {
    const [show, setShow] = useState(false)
    const coinJumpFunction = useRef(null)

    const toggle = () => setShow(!show)

    const mutation = useReportHours(operation, () => {})

    const handleMutate = (hours, minutes) => {
        setShow(false)
        mutation.mutate({ hours, minutes })
    }

    return (
        <div className='touch-manipulation'>
            <button {...props} onClick={toggle}>
                {mutation.isLoading ? (
                    <i className="fad fa-spinner-third text-green-500 animate-spin" />
                ) : children}
            </button>
            {show && (
                <TimeSelector
                    className={"left-0 right-0 sm:left-1/4 sm:right-unset"}
                    buttonClick={handleMutate}
                    setShow={setShow}
                    show={show}
                    coinJumpFunction={coinJumpFunction}
                    buttonText={"Sånn, det stemmer bedre."}
                >
                    <WindowWithCoin jumpfuncIn={coinJumpFunction} />
                    <div className="col-span-6 h-1 bg-indigo-200 bg-opacity-50 rounded-full my-4" />
                    <div className="col-span-6 px-4 text-indigo-100 font-medium">
                        <Time time={operation?.run_time} tooltip="Kjør" iconClassName="fas fa-stopwatch" />
                        <Time time={operation?.setup_time} tooltip="Oppstilling"
                            iconClassName="fas fa-screwdriver" />
                        <Time time={operation?.wait_time} tooltip="Vente" iconClassName="fas fa-pause-circle" />
                        <Time time={operation?.move_time} tooltip="Flytte" iconClassName="fas fa-person-dolly" />
                    </div>

                </TimeSelector>
            )}
        </div>
    )
}

export const TimeSelector = ({ coinJumpFunction, buttonClick, show, setShow, buttonText, className, childrenBeforeButton, resetFunc, isLoading, initialTime, children }) => {
    const [hours, setHours] = useState(0)
    const [minutes, setMinutes] = useState(0)

    useEffect(() => {
        if (resetFunc) {
            resetFunc.current = reset
        }
        if (initialTime) {
            const hrs = Math.floor(initialTime)
            const mins = Math.round((initialTime-hrs) * 60)
            setHours(hrs)
            setMinutes(mins)
        }
    }, [])

    const reset = () => {
        setHours(0)
        setMinutes(0)
    }

    const incrementMinutes = () => {
        const m = minutes + 5
        if (m >= 60) {
            setHours(p => p + 1)
            setMinutes(0)
            return
        }
        coinJumpFunction && coinJumpFunction.current()
        setMinutes(m)
    }

    const decrementMinutes = () => {
        const m = minutes - 5

        if (minutes <= 0) {
            if (hours === 0) return

            setHours(p => p - 1)
            setMinutes(55)
            return
        }

        setMinutes(m)
    }

    const ref = useRef(null)
    useOutsideAlerter(ref, () => {
        setShow(false)
    })
    const incrementHours = () => {
        setHours(p => p + 1)
        coinJumpFunction && coinJumpFunction.current()
    }

    const decrementHours = () => {
        if (hours === 0) return
        setHours(p => p - 1)
    }

    return (
        <TouchOutside update={setShow}>
            <ToggleDiv
                toggle={show}
                inactiveClassName="hidden"
                className={`${className} text-white pop-open absolute min-w-20 z-20 bg-gradient-to-br from-blue-600 via-indigo-600 to-purple-600 rounded-3xl p-4 grid grid-cols-6`}>

                <p className="p-0 m-0 text-lg col-span-6 text-white font-bold mb-2 text-center">
                    Hvor lang tid har du brukt?
                </p>
                <div className="col-span-3 flex flex-col text-center z-10">
                    <button onClick={incrementHours}>+</button>
                    <p><span className="font-bold">{hours}</span> {hours === 1 ? 'time' : 'timer'}</p>
                    <button onClick={decrementHours}>-</button>
                </div>
                <div className="col-span-3 flex flex-col text-center justify-center z-10">
                    <button onClick={incrementMinutes}>+</button>
                    <p><span className="font-bold">{minutes}</span> minutter</p>
                    <button onClick={decrementMinutes}>-</button>
                </div>
                {childrenBeforeButton && children}
                <div className="col-span-6 z-10 m-auto">
                {isLoading ? <i className="fad fa-spinner-third text-xl animate-spin"/> 
                :
                    <button
                        className="bg-green-400 m-auto block mt-4 rounded-2xl px-8 py-1 text-white relative text-green-900"
                        onClick={() => buttonClick(hours, minutes)}>
                        {buttonText}
                    </button>
                }
                </div>
                {!childrenBeforeButton && children}
            </ToggleDiv>
        </TouchOutside>
    )
}

const Time = ({ iconClassName, className, time, tooltip }) => {

    return (
        <div className="flex justify-between items-center">
            <div className="flex items-center space-x-1">
                <i className={iconClassName} />
                <p className="text-indigo-200">{tooltip}</p>
            </div>
            <p className=""> {time?.toFixed(1) ?? 0}t</p>
        </div>
    )
}

const OperationStatusIcon = ({ status }) => {
    if (status === 'ERROR') {
        return <i className="fas fa-exclamation-circle operation_icon_base" />
    }
    if (status === 'IN_PROGRESS') {
        return <i className="fad fa-construction operation_icon_base operation-in-progress" />
    }
    if (status === 'FINISHED') {
        return <i className="fad fa-check-circle operation_icon_base operation-finished" />
    }
    if (status === 'SUB_CONTRACTOR') {
        return <i className="fas fa-handshake operation_icon_base operation-queued" />
    }
    return <i className="fad fa-circle operation_icon_base operation-queued" />
}


const Loading = () => (
    <div className="text-green-400 flex text-center justify-center items-center col-span-6">
        <i className="animate-spin w-8 h-8 fill-current" />
    </div>
)

export const LoadingDiv = ({ loading, children }) => {
    if (loading) return <Loading className="" />
    return children
}

export const TouchOutside = ({ update, children, className }) => {
    const ref = useRef(null)
    useOutsideAlerter(ref, () => {
        update(false)
    })

    return <div ref={ref} className={className}>{children}</div>
}

export const ToggleDiv = ({ toggle, className, inactiveClassName, activeClassName, children }) => {
    return (
        <div className={`${className} ${toggle ? activeClassName : inactiveClassName}`}>
            {children}
        </div>
    )
}

const useOperationTitleMutation = (title, operation, callbackFn) => {
    const queryClient = useQueryClient()
    const { order, line, routing, ...params } = useRouteParams()

    const handleUpdate = () =>
        fetch(`/api/kanban/v1/route`, {
            method: 'POST',
            body: JSON.stringify({
                'prod_order_no': operation.prod_order_no,
                'routing_no': operation.routing_no,
                'operation_no': operation.operation,
                'routing_reference_no': operation.routing_reference_no,
                'type': 'Arbeidssenter',
                'no': operation.work_center_no,
                'description': title,
                'run_time': `${operation.run_time}`
            })
        }).then(async res => {
            console.log(res)
            if (res.status !== 200) throw new Error(await res.text())
        })

    return useMutation(handleUpdate, {
        onSuccess: async () => {
            if (params.center && params.machine) {
                await queryClient.invalidateQueries(['workcenter', params.center, params.machine])
            }
            await queryClient.invalidateQueries(['route', order, line, routing])
            await queryClient.invalidateQueries(['production', 'order', order])
            callbackFn()
            toast.custom(t => (
                <Notification t={t}>
                    <div className="flex space-x-2 items-center">
                        <i className="fas fa-check" />
                        <div className="flex flex-col">
                            <p className="font-bold">Operasjon oppdatert!</p>
                        </div>
                    </div>
                </Notification>
            ))
        },
        onError: error => {
            window.alert(error)
        }
    })
}
