import { useEffect, useState } from 'react'

export const useFetch = (url, options) => {
    const [result, setResult] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [firstFetch, setFirstFetch] = useState(true)

    const [trigger, setTrigger] = useState(0)

    useEffect(() => {
        setLoading(true)
        setError(null)
        setResult(null)

        fetch(url, options)
            .then(res => {
                if (res.status !== 200) {
                    res.text().then(txt => {
                        console.error(txt)
                    })
                    setError(`${res.status} - ${res.statusText}`)
                    return
                }
                res.json().then(json => {
                    setResult(json)
                }).catch(err => {
                    setError('Ukjent feil')
                    throw err
                })
            }).catch(err => {
            console.error(err)
            setError(`Ukjent feil`)
        }).then(() => {
            setFirstFetch(false)
            setLoading(false)
        })
    }, [trigger])

    const update = () => {
        setTrigger(trigger + 1)
    }

    return {
        result,
        loading,
        error,
        update,
        setResult,
        setError,
        setLoading,
        firstFetch
    }
}