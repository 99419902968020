import { useQuery } from 'react-query'

export const HerderiPage = () => {
    const queryHandler = () => fetch(`/herderi/v2/fetch`)
        .then(async res => {
            if (res.status !== 200) throw new Error(`${await res.text()}`)
            return res.json()
        })

    const { data } = useQuery(['herderi'], queryHandler, {
        refetchInterval: 10000
    })

    return (
        <div className="px-2 max-w-6xl m-auto">
            <h1 className="text-center text-4xl font-bold">Herderiet</h1>
            <div className="grid my-8" style={{
                gridGap: '.5rem',
                gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))'
            }}>
                {data?.map(oven => (
                    <Oven name={oven.Name}
                          temperature={oven.Temperature}
                          duration={oven.Duration}
                          timeRemaining={oven.TimeRemaining}
                    />
                ))}
            </div>
            <div className="flex justify-center pt-4">
                <a href="https://legacy.thune.app/herderi/v2/view">
                    <div className="bg-green-300 text-gray-900 flex space-x-1 items-center px-4 py-2 rounded-full font-medium">
                        <i className="fad fa-external-link"/>
                        <p>Grafer</p>
                    </div>
                </a>
            </div>
        </div>
    )
}

const Oven = ({ name, temperature, duration, timeRemaining }) => {
    const isActive = timeRemaining > 0
    return (
        <div className={`
                bg-gray-100 dark:bg-gray-800 rounded-lg px-3 py-2
                ${isActive && `
                    border-2 border-red-300
                    bg-red-100 dark:bg-red-900
                `}
            `}>
            <p className="text-center font-bold">{name}</p>

            <div className="grid grid-cols-3">
                <div className="flex flex-col space-x-1 items-center">
                    <i className="fad fa-thermometer-three-quarters"/>
                    <p>{temperature}℃</p>
                </div>
                <div className="flex flex-col space-x-1 items-center">
                    <i className="fad fa-calendar-day"/>
                    <p>{duration.toFixed(1)}h</p>
                </div>
                {isActive && (
                    <div className="flex flex-col space-x-1 items-center">
                        <i className="fad fa-hourglass-half"/>
                        <p>{timeRemaining}min</p>
                    </div>
                )}
            </div>
        </div>
    )
}