import styled, { css } from 'styled-components'

const Seperator = styled.div`
  height: 4px;
  width: 5rem;
  border-radius: 2px;
  
 --trailing: #00c6ff;
 --leading: #0072ff;
  
  background: #f953c6;  /* fallback for old browsers */
  //background: -webkit-linear-gradient(to right, var(--trailing), var(--leading));  /* Chrome 10-25, Safari 5.1-6 */
  //background: linear-gradient(to right, var(--trailing), var(--leading)); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: var(--pink-gradient);
  
  ${props => props.flipGradient && css`
    background: #f953c6;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, var(--leading), var(--trailing));  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, var(--leading), var(--trailing)); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  `};
  
  
  ${props => props.lg && css`
    width: 100%;
    height: 1px;
    margin: 0.5em auto 2em auto;
  `};
  
  @media screen and (max-width: 800px) {
    //display: none;
  }

`
export default Seperator
