import { useToggle } from '../index'
import { useEffect, useRef, useState } from 'react'
import { useOutsideAlerter } from '../../kanban/page'
import { useKeyPress } from '../toolbelt'
import { getUnit } from '../../kanban/lager'
import { useKalkyleCacheInvalidate } from '../operation/thought'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-hot-toast'

export const QuantityMenu = ({ mutation, quantityPer, quantityTotal, unitOfMeasure, title = "Hvor mye trenger vi?", className }) => {
    const { inputText, quantity, valid, handleSetInputText } = useFloatInputState(quantityPer)

    const { isOpen, toggle, close } = useToggle(false)
    const ref = useRef()
    useOutsideAlerter(ref, close)

    const keyPressOptions = {
        ignoreInput: true
    }

    useKeyPress('Enter', () => {
        handleMutate()
    }, keyPressOptions, isOpen)

    const handleMutate = () => {
        if (!valid || mutation.isLoading) return
        close()
        mutation.mutate({
            quantity,
        })
    }

    return (
        <div ref={ref}>
            <button onClick={toggle} className={`text-gray-400 font-normal sm:hover:underline ${className}`}>
                {mutation.isLoading ? (
                    <i className="fad fa-spinner-third text-green-400 animate-spin"/>
                ) : (
                    <>
                        {quantityTotal}{getUnit(unitOfMeasure)}
                    </>
                )}
            </button>
            {isOpen && (
                <div className="absolute z-20 pop-open top-full left-0 popover-gradient p-3 rounded-xl text-gray-900">
                    <p className="text-center font-bold mb-2">{title}</p>
                    <div className="flex space-x-2 items-center justify-center">
                        <input autoFocus onChange={handleSetInputText}
                               className="px-3 py-2 text-base font-medium bg-indigo-300 rounded-lg outline-none focus:ring-4 ring-indigo-200"
                               type="number"
                               value={inputText}/>
                        <p className="font-bold">{getUnit(unitOfMeasure)}</p>
                    </div>
                    <button className={`
                                m-auto block mt-2 
                                font-bold 
                                rounded-full px-6 py-2 
                                transition-colors ${valid ? 'bg-green-400' : 'bg-gray-400 cursor-not-allowed'}`}
                            disabled={mutation.isLoading || !valid} onClick={handleMutate}>
                        {mutation.isLoading ? (
                            <i className="fad fa-spinner-third text-green-500 animate-spin"/>
                        ) : (
                            "Det stemmer bedre"
                        )}
                    </button>
                </div>
            )}
        </div>
    )
}

const UnitOfMeasures = ({ open, itemNo, currentUnitOfMeasure, newUnitOfMeasure, setNewUnitOfMeasure }) => {
    const { unitOfMeasures, isLoading } = useUnitOfMeasures(open, itemNo)

    if (isLoading) return (
        <div>
            <i className="fad fa-spinner-third animate-spin text-white text-base"/>
        </div>
    )

    return (
        <select value={newUnitOfMeasure} onChange={e => {
            setNewUnitOfMeasure(e.target.value)
        }}>
            <option value={currentUnitOfMeasure} className="font-bold">{getUnit(currentUnitOfMeasure)}</option>
            {unitOfMeasures?.map((unit, index) => (
                <option value={unit.code} key={index}>{getUnit(unit.code)}</option>
            ))}
        </select>
    )
}

const useUnitOfMeasures = (open, itemNop) => {
    const { data, isLoading } =  useQuery(['unit-of-measures', itemNop], () =>
        fetch(`/api/kanban/v1/kalkyle/unit-of-measures?no=${encodeURIComponent(itemNop)}`)
            .then(async res => {
            if (res.status !== 200) throw new Error(await res.text())
            return await res.json()
        }),
        {
            enabled: open
        }
    )

    return {
        unitOfMeasures: data,
        isLoading
    }
}

export const useFloatInputState = (f) => {
    const [valid, setValid] = useState(true)
    const [quantity, setQuantity] = useState(f)
    const [inputText, setInputText] = useState(`${f}`)

    useEffect(() => {
        setQuantity(f)
        setInputText(f)
    }, [f])


    useEffect(() => {
    }, [])

    const handleSetInputText = event => {
        setInputText(event.target.value)

        const fp = parseFloat(event.target.value)

        if (isNaN(fp) || fp === undefined || fp === 'undefined' || !isFinite(fp)) {
            setValid(false)
            return
        }

        if (!valid) setValid(true)
        setQuantity(fp)
    }

    return {
        handleSetInputText, valid, quantity, inputText
    }
}

export const useSetMaterialQuantityMutation = (parent_key, material_key) => {
    const { invalidateThoughtItems } = useKalkyleCacheInvalidate()

    const handle = ({ quantity }) =>
        fetch(`/api/kanban/v1/kalkyle/materials/quantity`, {
            method: 'POST',
            body: JSON.stringify({
                parent_key,
                material_key,
                quantity,
            })
        })

    return useMutation(handle, {
        onSuccess: async () => {
            await invalidateThoughtItems()
            toast.success(`Mengden er oppdatert!`)
        },
        onError: err => {
            toast.error('Noe gikk galt: ' + err)
            console.error(`[SetMaterialQuantity] failed: ${err}`)
        }
    })
}
