import { useRef } from 'react'
import { useMutation } from 'react-query'
import { toast } from 'react-hot-toast'
import { useOutsideAlerter } from '../../kanban/page'
import { useDefaultState, useToggle } from '../index'
import { useKalkyleCacheInvalidate } from '../operation/thought'
import { useKeyPress } from '../toolbelt'

export const Description = ({ id, description }) => {
    const ref = useRef()
    const { isOpen, close, toggle } = useToggle(false)
    const [newDescription, setNewDescription] = useDefaultState(description)
    const mutation = useDescriptionMutation(id, newDescription)

    useOutsideAlerter(ref, close)

    const handleMutate = () => {
        close()
        mutation.mutate()
    }

    useKeyPress('Enter', handleMutate,{}, isOpen)

    return (
        <div ref={ref}>
            <button onClick={toggle} className="font-bold text-lg hover:underline">
                <i className="fas fa-comments mr-1"/>
                {mutation.isLoading ? (
                    <i className="fad fa-spinner-third animate-spin text-green-500"/>
                ) : description}
            </button>
            {isOpen && (
                <div className="absolute top-full inset-0 z-50 pop-open">
                    <div
                        className="bg-gradient-to-br text-gray-800 from-blue-400 via-indigo-400 to-purple-500 p-3 rounded-xl flex flex-col">
                        <p className="font-bold text-base">Hvordan vil du beskrive delen?</p>
                        <input type="text" placeholder="ITM-1234" value={newDescription}
                               autoFocus
                               onChange={e => setNewDescription(e.target.value)}
                               className="mt-1 font-medium bg-gray-800 text-white rounded-lg w-full px-3 py-2 outline-none focus:ring-4 ring-indigo-300"/>
                        <button onClick={handleMutate}
                                className="bg-green-400 active:ring-2 ring-gray-800 outline-none mt-3 text-gray-800 m-auto px-6 py-1 rounded-full text-md">OK
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}

const useDescriptionMutation = (id, description, callbackFn) => {
    const { invalidateThoughtItems } = useKalkyleCacheInvalidate()

    const handleMutate = () =>
        fetch(`/api/kanban/v1/kalkyle/thought-item/description?id=${encodeURIComponent(id)}`, {
            method: 'PUT',
            body: JSON.stringify({
                description
            })
        }).then(async res => {
            if (res.status !== 202) throw new Error(await res.text())
        })

    return useMutation(handleMutate, {
        onSuccess: async () => {
            await invalidateThoughtItems()
            if (callbackFn) callbackFn()
            toast.success('Beskrivelse oppdatert!')
        },
        onError: err => {
            toast.error(err.toString())
        }
    })
}