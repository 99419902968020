import { useEffect, useState } from 'react'
import { useFetch } from './useFetch'

const useUser = () => {
    const { result, loading, error, update } = useFetch("/api/kanban/v1/user")

    const [user, setUser] = useState(null)
    const [uploading, setUploading] = useState(false)
    const [uploadError, setUploadingError] = useState(null)

    useEffect(() => {
        setUser(result)
    }, [result])

    const setMachineCenter = (machineCenterNo, workCenterNo) => {

    }

    const setCompany = async (id) => {
        const res = await fetch(`/api/kanban/v1/company?company=${id}`, {
            method: "POST"
        })
        if (res.status !== 200) {
            setUploadingError(`${res.status} ${res.statusText}`)
        }
    }

    return {
        user,
        uploadError,
        uploading,
        setMachineCenter,
        setCompany,
        update
    }
}

export default useUser