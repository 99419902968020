import React, { useState, useEffect } from 'react';
import { useHoursWorkedPerDay } from '../../home/useHome';

export const CalendarComponent = ({ showCalendar, setSelectedDate, selectedDate }) => {
    const [ukeDager, setUkeDager] = useState(['Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør', 'Søn'])
    const [months, setMonths] = useState(["Januar", "Februar", "Mars", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Desember"])
    const [years, setYears] = useState([])

    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
    const [selectedMonthString, setSelectedMonthString] = useState((new Date().getMonth() + 1).toString().padStart(2, '0'))
    const [selectedMonth, setSelectedMonth] = useState((new Date().getMonth()))

    const { data, isLoading } = useHoursWorkedPerDay(selectedYear, selectedMonthString)

    useEffect(() => {
        const thisYear = new Date().getFullYear()
        setYears([thisYear, thisYear - 1, thisYear - 2, thisYear - 3])
    }, [])
    useEffect(() => {
        let newMonth = (selectedMonth + 1).toString().padStart(2, '0')
        setSelectedMonthString(newMonth)
    }, [selectedMonth])

    const handleMonthChange = (e) => {
        setSelectedMonth(months.findIndex(m => m === e.target.value))
    }
    const handleYearChange = (e) => {
        setSelectedYear(e.target.value)
    }

    return (
        <div className={`rounded-xl p-2 transition-all origin-top-left ease-in pop-up w-96 ${showCalendar ? ' max-h-80 scale-100 ' : ' max-h-0 scale-0 '} `}>
            <div className='grid grid-cols-7 gap-[2px] justify-around outline outline-gray-700 rounded-lg'>
                <div className='text-xl font-bold flex col-span-7 justify-evenly items-center border-b-2 '>
                    <select
                        onChange={handleMonthChange}
                        defaultValue={months[selectedMonth]}
                        className=' pl-2 dark:bg-gray-800 w-1/2 text-center outline rounded-tl-lg outline-gray-700'
                        name="month"
                        id="monthselection"
                    >
                        {months.map((m, i) => {
                            return <option className='' key={i} value={m}>{m}</option>
                        })}
                    </select>

                    <select
                        onChange={handleYearChange}
                        defaultValue={selectedYear}
                        className=' pl-1 dark:bg-gray-800 w-1/2 text-center outline rounded-tr-lg outline-gray-700'
                        name="years"
                        id="yearselection"
                    >
                        {years.map((y, i) => {
                            return <option key={i} value={y}>{y}</option>
                        })}
                    </select>
                </div>  

                {ukeDager?.map((dag, i) => {
                    let className = 'p-1 flex justify-center items-center font-bold  '
                    return <div key={dag} className={className}>{dag}</div>
                })}
                <div className='col-span-7 h-[2px] bg-gray-700'>

                </div>
                {isLoading ? 
                    <div className='col-span-7 flex justify-center p-4'> 
                        <i className="fad fa-spinner-third text-5xl text-pink-500 animate-spin"/> 
                    </div>

                :
                data?.data?.map((dag, id) => {
                    return <CalDay date={dag} key={id} month={selectedMonth} setSelectedDate={setSelectedDate} selectedDate={selectedDate} />
                })
                }

            </div>
        </div>
    );
}

const CalDay = ({date, month, setSelectedDate, selectedDate }) => {
    const dateObj = new Date(date.date)
    const today = new Date()
    let isToday = AreDatesSame(dateObj, today)
    let isSelected = selectedDate === date.date || (selectedDate === null && isToday)
    let inFocus = dateObj.getMonth() === month
    let approved = date.approved

    if (
        today.getTime() < dateObj.getTime() ||
        dateObj.getDay() === 0 ||
        dateObj.getDay() === 6 ||
        isToday
    ) {
        approved = true
    }
    
    let className = ''

    if (isToday) className += 'bg-purple-300 dark:bg-red-300 '
    if (isSelected) className += 'font-bold outline outline-pink-500 outline-2 outline-offset-[-2px] '
    if (!approved) className += 'bg-rose-200 dark:bg-rose-800 '

    const handleClick = () => {
        if (isToday) {
            setSelectedDate(null)
        } else {
            setSelectedDate(date.date)
        }
    }

    return (
        <div onClick={handleClick} className={`flex items-center justify-center rounded-lg ${className} aspect-[4/3] text-lg relative select-none hover:bg-gray-200 dark:hover:bg-gray-500 ${!inFocus && 'text-gray-500'}`}>
            <div className='z-20'>
                {dateObj.getDate()}
            </div>
        </div>
    )
}

const AreDatesSame = (date1, date2) => {
    return date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear()
}