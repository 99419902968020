import React, { useMemo, useRef, useState } from 'react'
import dayjs from 'dayjs'
import { Link, useParams } from 'react-router-dom'
import { routeURL } from '../home/page'
import { atom, useRecoilState, useRecoilValue } from 'recoil'
import { Consumption } from '../consumption/menu'
import { isFilteringItemGroupState, useOutsideAlerter } from './page'
import { useToggle } from '../kalkyle'
import { useSetInventoryReolMutation } from '../inventory/item'
import { Image } from '../drawing/image'
import { useDrop2 } from '../kalkyle/item/child'
import { useFileUpload } from '../kalkyle/item/fileupload'
import { useQueryClient } from 'react-query'

const useComponentsToggle = (orderNo, lineNo, routingNo) => {
    const [open, setOpen] = useRecoilState(useToggleWithID(`components-toggle-${orderNo}-${lineNo}-${routingNo}̋`))
    const toggle = () => setOpen(p => !p)
    return [open, toggle]
}

const useToggleWithID = (id, def = false) => {
    return useMemo(() => {
        return atom({
            key: id,
            default: def
        })
    }, [id])
}

export const Components = ({ components: unordered, orderNo, lineNo, routingNo }) => {
    const [open, toggle] = useComponentsToggle(orderNo, lineNo, routingNo)

    const usedComponents = unordered.filter(c => c.remaining_quantity === 0)
    const unusedComponents = unordered.filter(c => c.remaining_quantity > 0)

    const { incomming, notInStock, inStock } = sortComponents(unusedComponents)
    const components = [...notInStock, ...incomming, ...inStock]

    const isFiltering = useRecoilValue(isFilteringItemGroupState)
    const calcOpen = open || isFiltering

    let color = 'text-gray-400'
    if (notInStock.length > 0) {
        color = 'text-red-500'
    } else if (incomming.length > 0) {
        color = 'text-yellow-500'
    } else if (inStock.length > 0) {
        color = 'text-green-500'
    }

    return (
        <div className={`grid grid-cols-auto-fr gap-2 items-start justify-start`}>
            <i className={`
                fas fa-shopping-cart z-10 
                operation_icon_base
                ${color}`}/>
            <div>
                <button className={color} onClick={toggle}>
                    {calcOpen ? (
                        <div>Skul <i className="fas fa-chevron-up"/></div>
                    ) : (
                        <div>Se Mer <i className="fas fa-chevron-down"/></div>
                    )}
                </button>
                {calcOpen && (
                    <div className="grid py-4" style={{
                        gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
                        gridGap: '1rem'
                    }}>
                        {[...components, ...usedComponents]
                            .map(c => <Component key={c.line_no} component={c}/>)}
                    </div>
                )}
            </div>
        </div>
    )
}

export const sortComponents = (components) => {
    const withPurchase = []
    const inStock = []
    const notInStock = []

    components.forEach(comp => {
        if (comp.metadata.in_stock) {
            inStock.push(comp)
            return
        }
        if (comp.replenishments.length > 0) {
            withPurchase.push(comp)
            return
        }

        notInStock.push(comp)
    })

    let color = 'text-gray-400'
    if (notInStock.length > 0) {
        color = 'text-red-500'
    } else if (withPurchase.length > 0) {
        color = 'text-yellow-500'
    } else if (inStock.length > 0) {
        color = 'text-green-500'
    }

    return {
        notInStock,
        incomming: withPurchase,
        inStock,
        color
    }
}

const componentState = (component) => {
    const incomming = component.replenishments.length > 0
    const inStock = component.metadata.in_stock || component.storage.in_stock
    const used = component.remaining_quantity === 0

    if (used) return 'USED'
    if (inStock) return 'IN_STOCK'
    if (incomming) return 'INCOMMING'

    return 'ERROR'
}

const Component = ({ component }) => {
    if (!component) return null
    const comptState = componentState(component)
    const { description, item_no, remaining_quantity, unit_of_measure, base_unit_of_measure } = component

    return (
        <div className={`
                flex flex-col
                py-4 rounded-xl
                text-sm
                relative
                shadow-2xl
                shadow-inner
                ${comptState === 'USED' && 'bg-gray-100 bg-opacity-30 dark:bg-gray-700 dark:bg-opacity-30 dark:border-4 dark:border-gray-700'}
                ${comptState === 'IN_STOCK' && 'bg-green-100 dark:bg-green-800 dark:bg-opacity-10 border-4 border-green-500 border-opacity-50 dark:border-green-600 ring-4 ring-green-500 ring-opacity-20'}
                ${comptState === 'INCOMMING' && 'bg-yellow-400 bg-opacity-5 dark:bg-yellow-700 dark:bg-opacity-10 border-4 border-yellow-500 border-opacity-50 dark:border-yellow-600 ring-4 ring-yellow-500 ring-opacity-20'}
                ${comptState === 'ERROR' && 'bg-red-100 bg-opacity-30 dark:bg-red-800 dark:bg-opacity-10 border-4 border-red-500 border-opacity-50 dark:border-opacity-50 dark:border-red-600 ring-4 ring-red-500 ring-opacity-20'}
        `}>
            <Placement placement={component.storage}/>

            <Image no={item_no} className="h-24 max-h-24 rounded-xl mx-auto"/>

            <RemainingQuantity component={component} quantity={remaining_quantity} unitOfMeasure={unit_of_measure}/>

            <div className="flex flex-col space-y-4">
                <div className="flex flex-col px-4 text-center">
                    <span className="font-bold">{description}</span>
                    <p className="dark:text-gray-100">{item_no}</p>
                </div>
            </div>

            <div className="flex flex-col space-y-4">
                <MaterialsLister component={component} baseUnitOfMeasure={base_unit_of_measure}/>
            </div>
        </div>
    )
}

const RemainingQuantity = ({ component, quantity, unitOfMeasure }) => {
    if (quantity > 0) return (
        <div
            className="absolute left-0 top-0 bg-pink-500 bg-opacity-50 px-3 py-1 rounded-tl-lg rounded-br-xl ring-4 ring-pink-500 ring-opacity-25">
            <p className="font-bold text-pink-900 dark:text-pink-100">
                <StockIcon component={component}/>
                {quantity}{getUnit(unitOfMeasure)}
            </p>
        </div>

    )
    return null
}

const MaterialsLister = ({ component, baseUnitOfMeasure }) => {
    if (component.location_code === '') return (
        <div>
            <p className="text-center">Mangler Lokalisasjon, kan desverre ikke ta ut materialer.</p>
        </div>
    )

    const { used_materials, available_materials, replenishments } = component

    return (
        <div className="flex flex-col justify-between px-2">
            {used_materials?.length > 0 && (
                <div className="flex flex-col justify-between">
                    <div className="flex items-center">
                        <div className="h-[2px] bg-gradient-to-l from-pink-500 w-full"/>
                        <p className="flex items-center font-black text-pink-400 px-3 py-1 border-2 border-pink-500 rounded-full">
                            <i className="fas fa-sack-dollar mr-1"/>
                            <span className="skew-x-12">
                            BRUKT
                            </span>
                        </p>
                    </div>
                    {used_materials?.map((material, index) => (
                        <UsedMaterial key={index} material={material} baseUnitOfMeasure={baseUnitOfMeasure}/>
                    ))}
                </div>
            )}
            {replenishments?.length > 0 && (
                <div className="flex flex-col space-y-2 justify-between">
                    <div className="flex items-center">
                        <div className="h-[2px] bg-gradient-to-l from-yellow-500 w-full"/>
                        <p className="flex items-center font-black text-yellow-500 px-3 py-1 border-2 border-yellow-500 rounded-full whitespace-nowrap">
                            <i className="fas fa-person-carry mr-1"/>
                            PÅ VEI
                        </p>
                    </div>
                    {replenishments?.map((replenishment, index) => (
                        <Replenishment key={index} replenishment={replenishment}/>
                    ))}
                </div>
            )}
            {available_materials?.length > 0 && (
                <div className="flex flex-col space-y-2 justify-between">
                    <div className="flex items-center">
                        <div className="h-[2px] bg-gradient-to-l from-green-400 dark:from-green-500 w-full"/>
                        <p className="flex items-center font-black text-green-500 dark:text-green-400 px-3 py-1 border-2 border-green-400 dark:border-green-500 rounded-full">
                            <i className="fas fa-inventory mr-1"/>
                            <span className="">
                                LAGER
                            </span>
                        </p>
                    </div>
                    {available_materials?.map((material, index) => (
                        <Material key={index} component={component} material={material}
                                  baseUnitOfMeasure={baseUnitOfMeasure}/>
                    ))}
                </div>
            )}
        </div>
    )
}


const UsedMaterial = ({ material, baseUnitOfMeasure, ...props }) => {
    const { quantity, lot_no } = material
    return (
        <div className="relative flex px-2 py-1 space-x-2 font-medium text-pink-900 dark:text-pink-200" {...props}>
            <p className="">
                <MaterialParagraphIcon unit={baseUnitOfMeasure}/> {Math.abs(quantity)}{getUnit(baseUnitOfMeasure)}
            </p>

            <UsedMaterialChargeNo entryNo={material?.entry_no} chargeNo={lot_no} certificate={material?.certificate}/>
        </div>
    )
}

const UsedMaterialChargeNo = ({ entryNo, chargeNo, certificate }) => {
    if (chargeNo?.length === 0) {
        return null
    }

    if (certificate.exists === false) {
        return (
            <CertifyUsedMaterialButton entryNo={entryNo} chargeNo={chargeNo}/>
        )
    }

    return (
        <a className="underline" target="_blank" href={`/api/kanban/v1/inventory/certificate?id=${certificate.id}`}>
            <i className="fas fa-file-certificate"/> {chargeNo}
        </a>
    )
}

const CertifyUsedMaterialButton = ({ entryNo, chargeNo }) => {
    const invalidateRoute = useInvalidateRoute()

    const { isOpen, open, close, toggle } = useToggle(false)

    const {
        appendFiles,
        uploading
    } = useFileUpload(`/api/kanban/v1/inventory/certificate?entry_no=${entryNo}`, invalidateRoute)
    const { isHovering, funcs } = useDrop2(() => {}, {}, appendFiles)


    return (
        <div className="flex space-x-2">
            <div {...funcs} className={`${isHovering && 'underline'}`}>
                <i className="fas fa-file-certificate"/> {chargeNo}
            </div>
            {uploading?.map((u, i) => (
                <div>
                    <i className="fad fa-spinner-third animate-spin text-green-500" />
                </div>
            ))}
        </div>
    )

}

const Material = ({ component, material, baseUnitOfMeasure }) => {
    const { open, isOpen, toggle, close } = useToggle(false)

    const [tab, setTab] = useState('CONSUMPTION')

    const ref = useRef()
    useOutsideAlerter(ref, () => {
        setTab('CONSUMPTION')
        close()
    })

    return (
        <div className="relative">
            <button onClick={toggle}
                    className="px-2 py-1 rounded-md text-left text-sm hover:text-purple-500 hover:bg-green-500 hover:bg-opacity-50 w-full">
                <MaterialParagraph material={material} baseUnitOfMeasure={baseUnitOfMeasure}/>
            </button>
            {isOpen && (
                <div ref={ref}
                     className="absolute top-full left-0 right-0 z-30 border-4 border-green-400 border-opacity-50 max-w-sm pop-open px-4 py-4 bg-gradient-to-br from-blue-500 to-purple-400 text-indigo-100 rounded-xl ring-8 ring-indigo-500 ring-opacity-50">
                    {tab === 'CONSUMPTION' && (
                        <Consumption component={component} material={material}/>
                    )}
                    {tab === 'REOL' && (
                        <UpdateReol reol={material?.reol} entryNo={material?.entry_no} close={close}/>
                    )}
                    {tab === 'CONSUMPTION' && (
                        <div>
                            <Divider/>
                            <TabBar setTab={setTab} entryNo={material?.entry_no} certificate={material?.certificate}/>
                        </div>
                    )}

                </div>
            )}
        </div>
    )
}

const UpdateReol = ({ entryNo, reol, close }) => {
    const [newReol, setNewReol] = useState(reol?.where ?? '')
    const mutation = useSetInventoryReolMutation(entryNo, close)

    const handleSetNewReol = event => {
        setNewReol(event.target.value)
    }

    const handleMutate = async () => {
        await mutation.mutate(newReol)
    }

    return (
        <div>
            <p className="font-bold text-white px-1 text-lg">
                <i className="fas fa-inventory mr-1 text-xl"/> Hvor har du lagt den?
            </p>
            <input value={newReol} onChange={handleSetNewReol} type="text" autoFocus className={`
                    my-2
                    w-full px-3 py-2 
                    box-border
                    rounded-md 
                    bg-indigo-500 
                    text-white font-bold text-lg
                    outline-none ring-2 focus:ring-4 transition-all ring-indigo-200 ring-opacity-70`}
            />
            <div className="flex justify-center items-center mt-4">
                <button onClick={handleMutate}
                        className="px-6 py-2 bg-green-300 text-green-900 font-bold sm:hover:bg-green-200 sm:hover:ring-4 ring-2 ring-green-500 rounded-lg ">
                    {mutation.isLoading ? (
                        <span><i className="fad fa-spinner-third animate-spin"/></span>
                    ) : (
                        'Sånn, det stemmer bedre.'
                    )}
                </button>
            </div>
        </div>
    )
}

const TabBar = ({ setTab, entryNo, certificate }) => {
    const invalidateRoute = useInvalidateRoute()

    const ref = useRef()
    const handleDrop = () => {
    }
    const {
        appendFiles,
        uploading
    } = useFileUpload(`/api/kanban/v1/inventory/certificate?entry_no=${entryNo}`, invalidateRoute)
    const { isHovering, funcs } = useDrop2(handleDrop, {}, appendFiles)

    const isUploading = uploading.length > 0

    const onClick = () => {
        ref?.current?.click()
    }

    return (
        <div>
            <div className="grid grid-cols-6 text-indigo-900 pt-2 px-2 pb-4" style={{ gridGap: '1rem' }}>
                <div
                    {...funcs}
                    onClick={onClick}
                    className={`flex items-center cursor-pointer justify-center flex-col py-2 px-3 col-span-3 bg-indigo-100/10 border-indigo-100 border-dashed border-2 text-indigo-100 rounded-md`}>
                    {uploading?.length > 0 ? (
                        <i className="fad fa-spinner-third animate-spin text-2xl"/>
                    ) : (
                        <>
                            <i className={`fa-duotone fa-arrow-down-to-bracket text-2xl`}/>
                            <p className="font-bold">
                                Sertifikat
                            </p>
                        </>
                    )}
                </div>
                <button onClick={() => setTab('REOL')}
                        className="flex items-center justify-center flex-col py-2 px-3 col-span-3 bg-indigo-100 bg-opacity-50 sm:hover:bg-opacity-70 rounded-md ring-4 transition-all sm:hover:ring-8 ring-indigo-100 ring-opacity-30">
                    <i className="fad fa-inventory text-2xl"/>
                    <p>
                        Velg Reol
                    </p>
                </button>
            </div>
            {certificate?.exists && (
                <a className="text-indigo-100 rounded-md w-full flex justify-center space-x-2 items-center font-bold hover:underline"
                   href={`/api/kanban/v1/inventory/certificate?id=${certificate?.id ?? ''}`}>
                    <i className="fa-solid fa-file-pdf"/>
                    <p>
                        Gi meg sertifikatet
                    </p>
                </a>
            )}

            <input style={{ display: 'none' }} type="file" ref={ref} onChange={e => {
                const arr = []
                for (let i = 0; i < (e?.target?.files?.length ?? 0); i++) {
                    if (e.target?.files?.[i]) {
                        arr.push(e.target.files[i])
                    }
                }
                appendFiles(arr)
            }}/>
        </div>
    )
}

const useInvalidateRoute = () => {
    const { order, line, routing } = useParams()
    const client = useQueryClient()

    return async () => {
        await client.invalidateQueries(['route', order, line, routing])
    }
}

const Divider = () => {
    return (
        <div className="flex items-center my-4">
            <div className="w-full h-1 rounded-lg bg-indigo-100  opacity-50 "/>
        </div>
    )
}


const MaterialParagraph = ({ material, baseUnitOfMeasure }) => {
    const { quantity, lot_no } = material
    return (
        <p className="flex items-center space-x-2 text-green-900 dark:text-green-100 font-medium">
            <div className="flex space-x-1 items-center">
                <MaterialParagraphIcon unit={baseUnitOfMeasure}/>
                <span>
                    {Math.abs(quantity)}{getUnit(baseUnitOfMeasure)}
                </span>
            </div>
            {lot_no?.length > 0 && (
                <span>
                    <i className="fas fa-file-certificate"/> {lot_no}
                </span>
            )}
            {material?.reol?.is_put && (
                <span
                    className="bg-purple-600 text-purple-100 bg-opacity-50 px-3 rounded-lg text-white"><i
                    className="fas fa-inventory mr-1 text-xs"/> {material?.reol?.where ?? '-'}</span>
            )}
        </p>
    )
}

const MaterialParagraphIcon = ({ unit }) => {
    switch (unit) {
        case 'STK':
            return <i className="fas fa-boxes"/>
        case 'KG':
            return <i className="fas fa-weight"/>
        default:
            return <i className="fas fa-ruler-triangle"/>
    }
}

const Placement = ({ placement }) => {
    if (!placement.in_stock) return null

    return (
        <div className="absolute top-0 left-0 bg-purple-600 px-3 py-2 rounded-tl-xl rounded-br-2xl">
            <i className="fas fa-inventory"/> {placement.location}
        </div>
    )
}

const Replenishment = ({ replenishment }) => {
    const { order, unit_of_measure, quantity, due_date } = replenishment
    const day = dayjs(due_date).locale('nb').format('DD MMMM')

    const hasProductionRoute = replenishment.line_no && replenishment.routing_no
    if (hasProductionRoute) return (
        <Link to={routeURL(order, replenishment.line_no, replenishment.routing_no)} className="hover:underline">
            <p>Laget {replenishment.finished_quantity} av {quantity}{getUnit(unit_of_measure)}</p>
            {replenishment.finished_quantity < quantity && (
                <p>- estimert ferdig {day}</p>
            )}
        </Link>
    )

    return (
        <div className="text-yellow-900 font-medium dark:text-yellow-100">
            <p>Bestillt {quantity}{getUnit(unit_of_measure)}, kommer {day}</p>
        </div>
    )

}

const StockIcon = ({ component }) => {
    const state = componentState(component)

    switch (state) {
        case 'USED':
            return null
        case 'IN_STOCK':
            return <i className="fas fa-check-circle mr-2"/>
        case 'INCOMMING':
            return <i className="fas fa-calendar mr-2"/>
    }

    return <i className="fas fa-times-circle mr-2"/>
}

export const getUnit = (raw) => {
    switch (raw) {
        case 'M2':
            return 'm²'
        case 'M':
            return 'm'
        case 'STK':
            return 'stk'
        default:
            return raw
    }
}