import { useEffect, useRef, useState } from 'react'
import { Divider, useRouteParams } from '../route/page'
import dayjs from 'dayjs'
import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { Popover, Transition } from '@headlessui/react'

export const Files = ({ className, folder, data }) => {
    const ref = useRef(null)
    const [files, setFiles] = useState(folder.current)
    const [reports, setReports] = useState(folder.reports)

    useEffect(() => {
        setFiles(folder.current)
        setReports(folder.reports)
    }, [folder])

    const { sticky, setSticky, handleSetSticky, normal, setNormal, handleSetNormal } = useFiles(folder)

    const { uploadingFiles, appendUploadingFiles } = useFileUploader(setFiles, data?.prod_order_no, data?.no)

    const onClick = () => {
        ref.current.click()
    }

    return (
        <div className={`flex flex-col text-gray-700 dark:text-gray-100 ${className}`}>
            <div>
                <Divider title="FILER"
                         subtitle="Nødvendig informasjon for utførelse."
                         fn={onClick}>
                    <i className="fas fa-plus text-lg"/>
                    <span className="ml-2">Ny Fil</span>
                </Divider>
                <Draggable appendUploadingFiles={appendUploadingFiles} fn={handleSetNormal} to={normal}
                           setTo={setNormal}
                           from={sticky} setFrom={setSticky}
                           className="" id="div1">
                    <FilesLister files={files}/>
                </Draggable>
            </div>

            <UploadingFilesLister files={uploadingFiles}/>

            <div className="my-2">
                <Divider title="RAPPORTER"
                         subtitle="Disse blir delt med kunden." />
                <div className="py-4">
                    <FilesLister files={reports}/>
                </div>
            </div>

            <input style={{ display: 'none' }} type="file" multiple ref={ref} onChange={e => {
                const arr = []
                for (let i = 0; i < (e?.target?.files?.length ?? 0); i++) {
                    if (e.target?.files?.[i]) {
                        arr.push(e.target.files[i])
                    }
                }
                appendUploadingFiles(arr)
            }}/>
        </div>
    )
}

const UploadingFilesLister = ({ files }) => {
    return (
        <div className="flex flex-wrap">
            {files?.map((file, index) => (
                <div className="relative dark:bg-gray-700 px-6 py-2 rounded-xl flex items-center ml-4 mb-4" key={index}>
                    <div className="z-20">
                        <i className="fad fa-spinner-third text-2xl animate-spin"/>
                    </div>
                    <div className="ml-4 z-20">
                        <p className="font-bold">{file.file.name ?? 'no name'}</p>
                        <p>{file.progress ?? 'venter...'}%</p>
                    </div>
                    <div
                        className="absolute left-0 top-0 bottom-0 bg-green-400 dark:bg-green-600 rounded-xl z-10 transition-all"
                        style={{ right: `${100 - file.progress}%` }}
                    />
                </div>
            ))}
        </div>
    )
}

const Draggable = ({ appendUploadingFiles, fn, to, setTo, from, setFrom, children, className, ...props }) => {
    const [hoverCount, setHoverCount] = useState(0)

    const onDrop = async (event) => {
        event.preventDefault()
        setHoverCount(0)

        if (event.dataTransfer.files) {
            handleDidDropFiles(event)
            return
        }

        const id = event.dataTransfer.getData('id')
        const file = from?.filter(f => f.id === id)?.[0]
        if (!file) return

        fn(file, () => {
            setTo(prev => [...prev, file])
            setFrom(prev => prev.filter(f => f.id !== id))
        })
    }

    const handleDidDropFiles = (event) => {
        const arr = []
        for (let i = 0; i < (event?.dataTransfer?.files?.length ?? 0); i++) {
            if (event.dataTransfer?.files?.[i]) {
                arr.push(event.dataTransfer.files[i])
            }
        }

        appendUploadingFiles(arr)
    }

    const onDragOver = async (event) => {
        event.preventDefault()
        setHoverCount(prev => prev + 1)
    }

    const onDragLeave = async (event) => {
        setHoverCount(prev => prev - 1)
    }

    return (
        <div {...props}
             className={`${className} border-4 rounded-xl border-transparent 
                ${hoverCount > 0 && 'bg-green-200 border-green-400 dark:bg-green-800 dark:border-green-600'}
             `}
             onDrop={onDrop} onDragOver={event => event.preventDefault()} onDragEnter={onDragOver}
             onDragLeave={onDragLeave}>
            {children}
        </div>
    )
}

export const FilesLister = ({ files }) => {

    const onDragStart = (event, file) => {
        event.dataTransfer.setData('id', file.id)
    }

    return files?.map(file => (
        <div draggable={true}
             key={file.id}
             onDragStart={event => onDragStart(event, file)}>
            <File file={file}/>
        </div>
    ))
}

const File = ({ file }) => {
    const owner = file.owner.toLowerCase()
    const filesize = (file.size / 1000000).toFixed(1)
    const lastModified = dayjs(file.last_modified).locale('nb').format('DD MMMM YYYY')

    const { deleteMutation, stickyMutation, renameMutation, reportMutation } = useDrawingMutations(useRouteParams())
    return (
        <div style={{ gridTemplateColumns: 'auto 1fr auto' }}
             className="items-center grid my-3 py-1 rounded-xl sm:hover:bg-white sm:dark:hover:bg-gray-800">
            <button onClick={() => stickyMutation.mutate(file)}
                    className="mr-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-xl p-4 min-w-3">
                {stickyMutation.isLoading ? (
                    <i className="fad fa-spinner-third animate-spin"/>
                ) : (
                    <i className={`
                    fas fa-thumbtack 
                    ${file.sticky && 'text-green-500'}
                `}/>
                )}
            </button>
            <div className="text-gray-500 dark:text-gray-400" draggable={false}>
                <a draggable={false}
                   className="font-bold text-gray-800 dark:text-gray-100 hover:underline"
                   href={`/api/kanban/v1/drawing?so=${file.order}&item=${file.item}&filename=${file.filename}`}>
                    {renameMutation.isLoading ? (
                        <i className="fad fa-spinner-third animate-spin"/>
                    ) : (
                        file.filename
                    )}
                </a>
                <p className="font-medium">{owner}</p>
                <div className="flex">
                    <p>{filesize}MB</p>
                    <p className="mx-1">·</p>
                    <p>{lastModified}</p>
                </div>
            </div>

            <FileEditMenu
                file={file}
                deleteMutation={deleteMutation}
                renameMutation={renameMutation}
                reportMutation={reportMutation}
            />

        </div>
    )
}

const FileEditMenu = ({ file, deleteMutation, renameMutation, reportMutation }) => {

    const handleRename = () => {
        const name = window.prompt(`Skriv inn det nye navnet`, file.filename)
        if (name === null) return
        renameMutation.mutate(Object.assign({}, file, {
            filename: name
        }))
    }

    return (
        <Popover className="relative">
            {({ open }) => (
                <>
                    <Popover.Button
                        className="hover:bg-gray-100 dark:hover:bg-gray-700 active:ring-2 ring-gray-100 dark:ring-gray-700 ring-offset-2 dark:ring-offset-gray-800 px-3 py-2 rounded-lg focus:outline-none">
                        <i className="fas fa-edit"/>
                    </Popover.Button>
                    <Transition
                        show={open}
                        enter="transition duration-100 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                    >
                        <Popover.Panel static className="absolute z-40 right-0 w-screen max-w-sm ">
                            <div
                                className="grid grid-cols-6 bg-gradient-to-br from-indigo-400 to-fuchsia-400 text-gray-900 px-3 py-3 rounded-lg"
                                style={{
                                    gridGap: '0.5rem'
                                }}>
                                <p className="col-span-6 font-bold text-base">Hva vil du gjøre med fila?</p>
                                <button
                                    className="col-span-6 focus:outline-none rounded-lg flex flex-col justify-center items-center px-3 py-2"
                                    style={{
                                        background: 'rgba(255, 255, 255, 0.3)'
                                    }}
                                    onClick={() => reportMutation.mutate(file)}
                                >
                                    {reportMutation.isLoading ? (
                                        <i className="fad fa-spinner-third text-xl animate-spin"/>
                                    ) : (
                                        <>
                                            <i className="fas fa-file-contract"/>
                                            Gjør om til {file.is_report ? "vanlig fil" : "rapport"}
                                        </>
                                    )}
                                </button>
                                <button onClick={() => deleteMutation.mutate(file.id)}
                                        className="col-span-3 focus:outline-none rounded-lg flex flex-col justify-center items-center px-3 py-2"
                                        style={{
                                            background: 'rgba(255, 255, 255, 0.3)'
                                        }}>
                                    {deleteMutation.isLoading ? (
                                        <i className="fad fa-spinner-third text-xl animate-spin"/>
                                    ) : (
                                        <>
                                            <i className="fas fa-trash-alt"/>
                                            Slett
                                        </>
                                    )}
                                </button>
                                <button onClick={handleRename}
                                        className="col-span-3 focus:outline-none rounded-lg flex flex-col items-center justify-center px-3 py-2"
                                        style={{
                                            background: 'rgba(255, 255, 255, 0.3)'
                                        }}>
                                    <i className="fad fa-file-edit"/>
                                    Bytt Filnavn
                                </button>
                            </div>
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    )
}

const useDrawingMutations = ({ order, line, routing }) => {
    const client = useQueryClient()

    const deleteHandler = (id) => fetch(`/api/kanban/v1/drawing?id=${id}`, {
        method: 'DELETE'
    })
    const deleteMutation = useMutation(deleteHandler, {
        onSuccess: async () => {
            await client.invalidateQueries(['route', order, line, routing])
        }
    })


    const stickyHandler = file => fetch(`/api/kanban/v1/drawing`, {
        method: 'PATCH',
        body: JSON.stringify({
            'id': file.id,
            'sticky': !file.sticky
        })
    })
    const stickyMutation = useMutation(stickyHandler, {
        onSuccess: async () => {
            await client.invalidateQueries(['route', order, line, routing])
            await client.invalidateQueries(['production', 'order', order])
        }
    })


    const renameHandler = file => fetch(`/api/kanban/v1/drawing?order=${encodeURIComponent(file.order)}&item=${encodeURIComponent(file.item)}&filename=${encodeURIComponent(file.filename)}&id=${encodeURIComponent(file.id)}`, {
        method: 'PUT'
    })
    const renameMutation = useMutation(renameHandler, {
        onSuccess: async () => {
            await client.invalidateQueries(['route', order, line, routing])
        }
    })


    const reportHandler = file => fetch(`/api/kanban/v1/drawing/report`, {
        method: 'POST',
        body: JSON.stringify({
            'id': file.id,
            'is_report': !file.is_report,
            'order_no': order,
            'line_no': parseInt(line),
            'routing_no': routing,
        })
    })
    const reportMutation = useMutation(reportHandler, {
        onSuccess: async () => {
            await client.invalidateQueries(['route', order, line, routing])
            await client.invalidateQueries(['production', 'order', order])
        }
    })

    return {
        deleteMutation,
        stickyMutation,
        renameMutation,
        reportMutation
    }
}

const useSticky = (sticky) => {
    return async (file, swap) => {
        const res = await fetch(`/api/kanban/v1/drawing`, {
            method: 'PATCH',
            body: JSON.stringify({
                'id': file.id,
                'sticky': sticky
            })
        })
        if (res.status !== 202) {
            window.alert('failed')
            return
        }

        swap()
    }
}

const useFiles = (folder) => {
    const [sticky, setSticky] = useState(folder.stickies ?? [])
    const [normal, setNormal] = useState(folder.current ?? [])

    const handleSetNormal = useSticky(false)
    const handleSetSticky = useSticky(true)

    useEffect(() => {
        setSticky(folder.stickies ?? [])
        setNormal(folder.current ?? [])
    }, [folder])

    return {
        sticky, setSticky, normal, setNormal,
        handleSetNormal, handleSetSticky
    }
}

const useFileUploader = (setFinished, no, item) => {
    const [uploadingFiles, setUploadingFiles] = useState([])

    const directURL = async (file) => {
        let url = `/api/kanban/v1/drawing?so=${no}&item=${item}`
        if (item) {
            url += `&item=${item}`
        }

        const res = await fetch(url, {
            method: 'POST',
            body: JSON.stringify({
                'filename': file.name,
                'size': file.size,
                'type': file.type
            })
        })

        if (res.status !== 200) {
            console.error(`${res.status} - ${res.statusText}`)
            throw res
        }

        const json = await res.json()
        return json?.direct_url
    }

    const directUpload = async (url, file) => {
        if (!file) return

        const res = await axios.put(url, file, {
            onUploadProgress: progressEvent => {
                const percentage = (progressEvent.loaded / progressEvent.total * 100).toFixed(0)

                setUploadingFiles(prev => {
                    return prev.map(p => {
                        if (p.file.name !== file.name) return p

                        console.log('p: ', p)
                        const n = Object.assign({}, p, {
                            progress: percentage
                        })
                        console.log('n: ', n)
                        return n
                    })
                })

            }
        })

        if (res.status !== 200) {
            console.error('FAIELD: ', res.status)
        }

    }

    const createRecord = async (file) => {
        const res = await fetch(`/api/kanban/v1/drawing/record?no=${no}&item=${item}`, {
            method: 'POST',
            body: JSON.stringify({
                'filename': file.name,
                'size': file.size,
                'last_modified': file.lastModified,
                'content_type': file.type
            })
        })
        if (res.status !== 200) {
            throw `${res.status} - ${res.statusText}`
        }

        return await res.json()
    }


    const handleAppendUploadingFiles = async (files) => {
        for (const file of files) {
            await setUploadingFiles(prev => [...prev, {
                progress: 0,
                file
            }])
        }

        for (const file of files) {
            const url = await directURL(file)
            await directUpload(url, file)
            const record = await createRecord(file)

            setFinished(prev => [...prev, record])
            setUploadingFiles(prev => prev.filter(p => p.file.name !== file.name))
        }

    }


    return {
        uploadingFiles, appendUploadingFiles: handleAppendUploadingFiles
    }
}