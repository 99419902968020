import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import { useEffect, useRef, useState } from 'react'
import { atom, useRecoilState, useRecoilValue } from 'recoil'
import { TextMenu } from './item/textMenu'
import { Link, useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useDrop2 } from './item/child'
import { SiteMap } from './sitemap'
import { BrainDumps } from './braindump'
import { Add } from './add'
import { ItemPage } from './item/page'
import { toast } from 'react-hot-toast'
import { useKalkyleCacheInvalidate } from './operation/thought'
import { Kalkyle } from './kalkyle'

export const KalkyleDetails = () => {
    const { id } = useParams()
    const { data } = useKalkyle()

    return (
        <div className="relative max-w-[1440px] m-auto min-h-screen pb-48">
            <div className="flex items-center justify-center relative mb-4">
                <KalkyleTitle title={data?.title}/>
                <div className="flex justify-center items-center absolute left-0 top-0 bottom-0">
                    <Link to={`/kalkyle/create/${id}`}
                          className="transform transition-transform sm:hover:scale-110 font-bold">
                        <i className="fad fa-crow mr-1"/>
                        Tilbake til oversikten
                    </Link>
                </div>
            </div>
            <div className="grid grid-cols-12 relative" style={{ gridGap: '1rem' }}>
                <div className="col-span-12 sm:col-span-3 ">
                    <div
                        className="hidden sticky top-0 md:flex flex-col space-y-8">
                        <SiteMap/>
                        <BrainDumps/>
                        <RemoveOperation/>
                        <Add/>
                    </div>
                </div>
                <div className="col-span-12 md:col-span-9 box-border">
                    <ItemPage/>
                </div>
            </div>
        </div>
    )
}

export const CreateKalkyle = ({}) => {
    const { data, isLoading, isFetching } = useKalkyle()
    return (
        <div>
            <div className="flex items-center justify-center relative mb-4">
                <KalkyleTitle title={data?.title}/>
                <div className="flex justify-center items-center absolute left-0 top-0 bottom-0">
                    <Link to="/kalkyle" className="transform transition-transform sm:hover:translate-x-2 font-bold">
                        <i className="fad fa-arrow-left"/>
                        Se Alle
                    </Link>
                </div>
            </div>
            {isLoading ? (
                <div className="flex justify-center items-center min-h-[50vh]">
                    <i className="fad fa-spinner-third text-2xl animate-spin text-purple-400"/>
                </div>
            ) : (
                <Kalkyle items={data?.items ?? []}/>
            )}
        </div>
    )
}

export const KalkyleChild = () => {
    const { id, childID } = useParams()
    const { data, isLoading } = useKalkyle()
    const child = kalkyleChild(data?.items, childID)

    return (
        <div>
            <div className="flex items-center justify-center relative mb-4">
                <KalkyleTitle title={data?.title}/>
                <div className="flex justify-center items-center absolute left-0 top-0 bottom-0">
                    <Link to={`/kalkyle/create/${id}`}
                          className="transform transition-transform sm:hover:scale-110 font-bold">
                        <i className="fad fa-crow mr-1"/>
                        Tilbake til oversikten
                    </Link>
                </div>
            </div>
            <Kalkyle items={child?.children ?? []}/>
        </div>
    )
}
export const currentHoveringAtom = atom({
    key: 'currentHoveringAtom',
    default: ''
})

export const useSetWhatsHovering = () => useRecoilState(currentHoveringAtom)

export const RemoveOperation = () => {
    const mutation = useRemoveMutation()
    const [whatsHovering] = useRecoilState(currentHoveringAtom)

    const { isHovering, funcs } = useDrop2(event => {
        const txt = event.dataTransfer.getData('text')
        const payload = JSON.parse(txt)

        switch (whatsHovering) {
            case 'operation':
                mutation.mutate({
                    id: payload.id,
                    resourceGroup: 'thought-operation'
                })
                break
            case 'item':
                mutation.mutate({
                    id: payload.id,
                    resourceGroup: 'thought-item'
                })
                break
            case 'published_item':
                console.log('id: ', payload)
                mutation.mutate({
                    id: payload.id,
                    resourceGroup: 'thought-item'
                })
        }

    }, {})

    const shouldWeCareAboutWhatsHovering = (whatsHovering === 'operation') || (whatsHovering === 'item') || (whatsHovering === 'published_item')
    if (shouldWeCareAboutWhatsHovering === false && !mutation.isLoading) return null

    return (
        <div {...funcs}
             className={`pop-open flex justify-center items-center bg-red-500 bg-opacity-20 border-2 border-red-500 rounded-lg text-red-500 ${isHovering && 'bg-opacity-30'}`}
             style={{ minHeight: '5rem' }}>
            {mutation.isLoading ? (
                <i className="fad fa-spinner-third text-xl text-white animate-spin"/>
            ) : (
                <i className="fad fa-trash-alt text-xl"/>
            )}
        </div>
    )
}
export const useRemoveMutation = () => {
    const { id, childID } = useParams()
    const client = useQueryClient()

    const handle = ({ id, resourceGroup }) =>
        fetch(`/api/kanban/v1/kalkyle/${resourceGroup}/remove?id=${encodeURIComponent(id)}`, {
            method: 'DELETE'
        }).then(async res => {
            if (res.status !== 202) throw new Error(await res.text())
        })

    return useMutation(handle, {
        onSuccess: async () => {
            await client.invalidateQueries(['thought-items', id])
            await client.invalidateQueries(['kalkyle', 'thought-item', childID])
            toast.success('Fjernet!')
        },
        onError: err => {
            toast.error('noe gikk galt:' + err)
            console.error(`[RemoveOperation] failed: ${err}`)
        }
    })
}
const kalkyleChild = (list, childID) => {

    for (const index in list) {
        const item = list[index]
        if (item.id === childID) {
            return item
        }

        const child = kalkyleChild(item.children ?? [], childID)
        if (child !== undefined) {
            return child
        }
    }
}

export const useKalkyle = () => {
    const { id } = useParams()
    return useQuery(['thought-items', id], () =>
        fetch(`/api/kanban/v1/kalkyle/thought-item/list?id=${id}`).then(async res => {
            if (res.status !== 200) throw new Error(await res.text())
            return res.json()
        }))
}

const KalkyleTitle = ({ title }) => {
    const mutation = useSetKalkyleTitleMutation()
    return (
        <div className="">
            <TextMenu title="Hvordan vil du beskrive kalkylen?"
                      className="text-center text-2xl"
                      text={title} mutation={mutation}>
                <h1 className="sm:hover:bg-gray-100 sm:dark:hover:bg-gray-800 text-green-400 transform skew-x-12 font-black rounded-lg px-3 py-1 text-4xl transform">{title}</h1>
            </TextMenu>
        </div>
    )
}

const useSetKalkyleTitleMutation = () => {
    const { id } = useParams()
    const { invalidateThoughtItems } = useKalkyleCacheInvalidate()

    const handle = (title) =>
        fetch(`/api/kanban/v1/kalkyle/title?id=${id}&title=${encodeURIComponent(title)}`, {
            method: 'PUT'
        }).then(async res => {
            if (res.status !== 202) throw new Error(await res.text())
        })

    return useMutation(handle, {
        onSuccess: async () => {
            await invalidateThoughtItems()
            toast.success('Tittel oppdatert!')
        },
        onError: err => {
            toast.error('Kunne ikke sette tittel: ' + err)
            console.error(`[SetKalkyleTitle] failed: ${err}`)
        }
    })
}

export const useColumnsCount = (min) => {
    const ref = useRef()

    const [config, setCofig] = useState({
        columnsCount: 0,
        cellWidth: 0,
        totalWidth: 0
    })

    useEffect(() => {
        const element = ref.current
        const resizeObserver = new ResizeObserver(entries => {
            if (!Array.isArray(entries)) return
            if (!entries.length) return
            const entry = entries[0]

            const width = entry.contentRect.width
            const d = Math.round(width / min)

            setCofig({
                columnsCount: d,
                totalWidth: width,
                cellWidth: width / d
            })
        })
        resizeObserver.observe(element)
        return () => resizeObserver.unobserve(element)
    }, [])

    return [ref, config]
}


export const useWhatsHovering = () => useRecoilValue(currentHoveringAtom)