import { useRef } from 'react'
import { useOutsideAlerter } from '../../kanban/page'
import { useMutation } from 'react-query'
import { toast } from 'react-hot-toast'
import { useKalkyleCacheInvalidate } from './thought'
import { keyParams, useDefaultState, useToggle } from '../index'

export const OperationDescription = ({ description, mutation }) => {
    const [newDescription, setNewDescription] = useDefaultState(description)
    const { isOpen, close, toggle } = useToggle(false)

    const ref = useRef()
    useOutsideAlerter(ref, close)

    const handleMutate = () => {
        close()
        mutation.mutate(newDescription)
    }

    return (
        <div ref={ref}>
            <button onClick={toggle} className="font-medium sm:hover:underline text-left">
                {description}
                {mutation.isLoading && (
                    <i className="fad fa-spinner-third animate-spin text-green-500 ml-1"/>
                )}
            </button>
            {isOpen && (
                <div className="pop-open absolute top-full left-0 right-0 p-3 bg-gradient-to-br from-yellow-300 to-orange-500 z-30 rounded-xl">
                    <div className="flex flex-col">
                        <p className="font-bold text-base text-yellow-900">Hvordan vil du beskrive oppgaven?</p>
                        <input autoFocus className="mt-1 bg-yellow-100 bg-opacity-50 rounded-lg px-3 py-2 text-base outline-none border-4 border-transparent focus:border-yellow-100 text-yellow-900 font-medium" type="text" value={newDescription} onChange={e => setNewDescription(e.target.value)}/>
                        <button onClick={handleMutate} className="mt-3 bg-yellow-200 text-yellow-900 py-1 m-auto px-6 rounded-full">
                            Det passer bedre
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}

export const usePublishedOperationDescriptionMutation = (routeKey, operationKey) => {
    return useOperationDescriptionMutation(`/published-operation/description?${keyParams(routeKey, operationKey)}`)
}

export const useThoughtOperationDescriptionMutation = (id) => {
    return useOperationDescriptionMutation(`/thought-operation/description?id=${id}`)
}

const useOperationDescriptionMutation = (resource) => {
    const { invalidateThoughtItems } = useKalkyleCacheInvalidate()

    const handle = (description) =>
        fetch(`/api/kanban/v1/kalkyle${resource}`, {
            method: 'PUT',
            body: JSON.stringify({
                description
            })
        }).then(async res => {
            if (res.status !== 202) throw new Error(await res.text())
        })

    return useMutation(handle, {
        onSuccess: async () => {
            await invalidateThoughtItems()
            toast.success('Beskrivelse Oppdatert!')
        },
        onError: async err => {
            toast.error('Klarte ikke å oppdatere beskrivelse: ' + err)
            console.error(`[PublishedOperationDescriptionMutation] failed: ${err}`)
        }
    })
}
