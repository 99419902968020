import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'

const insertEntry = ({ desc, timeSpent, id }) => {
    return axios.post(`/api/kanban/v1/kanban/indirect-time-usage/add?description=${desc}&time_spent=${timeSpent}&id=${id ? id : ""}`)
}

const deleteEntry = ({id}) => {
    return axios.delete(`/api/kanban/v1/kanban/indirect-time-usage/delete?id=${id}`)
}

export const useRemoveIndirectTime = () => {
    const queryClient = useQueryClient()

    return useMutation(deleteEntry, {
        /* onMutate: async (mutationObj) => {
            const { id } = mutationObj

            await queryClient.cancelQueries('home')
            const previousData = await queryClient.getQueryData('home')
            queryClient.setQueryData('home', (oldData) => {
                let previousEntries = []
                if (oldData.today.indirect_time !== null) {
                    previousEntries = oldData.today.indirect_time.filter(p => p.id !== id)
                }
                return {
                    ...previousEntries ?? [],
                    today: {items: previousEntries?.today.items,
                    indirect_time: previousEntries}
                }
            })

            return { previousData }
        }, */
        onError: (_error, _entry, context) => {
            queryClient.setQueryData(context.previousData)
        },
        onSettled: () => {
            queryClient.invalidateQueries('home')
        }
    })
}

export const useAddIndirectTime = () => {
    const queryClient = useQueryClient()

    return useMutation(insertEntry, {
/*         onMutate: async (mutationObj) => {
            const { desc, timeSpent, id } = mutationObj

            await queryClient.cancelQueries('home')
            const previousData = await queryClient.getQueryData('home')
            queryClient.setQueryData('home', (oldData) => {
                let previousEntries = []
                if (oldData.today.indirect_time !== null) {
                    previousEntries = oldData.today.indirect_time.filter(p => p.id !== id)
                }
                return {
                    ...oldData,
                    today: {items: oldData.today.items,
                    indirect_time: [...previousEntries, {
                        description: desc,
                        time_spent: timeSpent,
                    }]}
                }
            })

            return { previousData }
        }, */
        onError: (_error, _entry, context) => {
            queryClient.setQueryData(context.previousData)
        },
        onSettled: () => {
            queryClient.invalidateQueries('home')
        }
    })
}

