import { atom, selector, useRecoilState } from 'recoil'
import { useQuery } from 'react-query'
import { Document, Page, pdfjs } from 'react-pdf'
import { forwardRef, useEffect, useRef, useState } from 'react'
import { useToggle } from './index'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { toast } from 'react-hot-toast'
import { useOutsideAlerter } from '../kanban/page'
import { useFileUpload } from './item/fileupload'

const pdfViewerPageNumberAtom = atom({
    key: 'pdfViewerPageNumberAtom',
    default: 1
})

export const pdfViwerPageCountAtom = atom({
    key: 'pdfViwerPageCountAtom',
    default: 0
})

const pdfViewerPageNumberSelector = selector({
    key: 'pdfViewerPageNumberSelector',
    get: ({ get }) => get(pdfViewerPageNumberAtom),
    set: ({ set, get }, newValue) => {
        const val = get(pdfViewerPageNumberAtom)
        const pageCount = get(pdfViwerPageCountAtom)
        if (newValue <= 0) return set(pdfViewerPageNumberAtom, val)
        if (newValue > pageCount) return set(pdfViewerPageNumberAtom, val)
        return set(pdfViewerPageNumberAtom, newValue)
    }
})

export const ActualPdfViewer = forwardRef(({ url, isLoading, dimensions, className = 'rounded-2xl overflow-hidden' }, ref) => {
    const [surl, setSurl] = useState(url)
    const [, setNumberOfPages] = useRecoilState(pdfViwerPageCountAtom)
    const [pageNumber, setPageNumber] = useRecoilState(pdfViewerPageNumberSelector)

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

    useEffect(() => {
        if (url !== surl) setSurl(url)
    }, url)

    function onDocumentLoadSuccess({ numPages }) {
        setNumberOfPages(numPages)
        setPageNumber(1)
    }

    if (isLoading) return (
        <div className="flex justify-center items-center">
            <i className="fad fa-spinner-third animate-spin text-indigo-400 text-2xl"/>
        </div>
    )

    return (
        <div>
            <Document
                file={surl ?? ''}
                loading="" noData=""
                className="pop-open"
                onLoadSuccess={onDocumentLoadSuccess}
            >
                <div className={className}>
                    <Page loading="" noData="" width={dimensions.width} pageNumber={pageNumber} canvasRef={ref}/>
                </div>
            </Document>
        </div>
    )
})


export const ActualPDFViewerMenu = forwardRef(({ canvasState, className, url, itemNo }, ref) => {
    const [pageNumber, setPageNumber] = useRecoilState(pdfViewerPageNumberSelector)
    const [pageCount] = useRecoilState(pdfViwerPageCountAtom)

    return (
        <div className={` flex justify-center space-x-4 items-center ${className} mt-4`}>
            <a href={url} target="_blank"
               className="bg-gray-100 dark:bg-gray-700 sm:hover:bg-green-300 sm:hover:text-green-900 rounded-full px-6 py-2 ">
                <i className="fad fa-download"/>
            </a>

            <div
                className="flex space-x-2 justify-center items-center bg-gray-100 dark:bg-gray-700 rounded-full overflow-hidden">
                <button
                    onClick={() => setPageNumber(n => n - 1)}
                    className="px-3 py-2 sm:hover:bg-indigo-500 rounded-sm">
                    <i className="fad fa-long-arrow-left"/>
                </button>
                <p>Side {pageNumber}/{pageCount}</p>
                <button
                    onClick={() => setPageNumber(n => n + 1)}
                    className="px-3 py-2 sm:hover:bg-indigo-300 sm:hover:dark:bg-indigo-500 rounded-sm">
                    <i className="fad fa-long-arrow-right"/>
                </button>
            </div>

            <Crop canvasState={canvasState} itemNo={itemNo} ref={ref}/>

        </div>
    )
})

const Crop = forwardRef(({ itemNo }, ref) => {
    const { close, open, isOpen } = useToggle(false)
    const [pdfImage, setPDFImage] = useState('')

    const outsideListenerRef = useRef()
    const cropRef = useRef()
    const [crop, setCrop] = useState()

    const { uploading, appendFiles } = useFileUpload(
        `/api/kanban/v1/kalkyle/item/image?no=${encodeURIComponent(itemNo ?? '')}`,
        ({ id }) => {
            toast.success("Croppen ble endra!")
        })

    useEffect(() => {
        const save = ref?.current?.toDataURL('image/png')
        if (save) setPDFImage(save)
    })

    const done = async () => {
        close()
        const img = await getCroppedImg(cropRef.current, crop, 'file.png')
        appendFiles([img])
    }

    const onLoad = (img) => {
        cropRef.current = img
    }

    useOutsideAlerter(outsideListenerRef, close)

    return (
        <div className="">
            <button onClick={open} className="bg-gray-100 dark:bg-gray-700 sm:hover:bg-pink-300 text-gray-800 dark:text-gray-100 sm:hover:text-pink-900 py-2 px-3 rounded-full">
                {(uploading?.length > 0) ? (
                    <i className={"fad fa-spinner-third animate-spin"} />
                ):(
                    <i className="fad fa-crop-alt"/>
                )}
            </button>
            {isOpen && (
                <div ref={outsideListenerRef} className="pop-open absolute z-30 left-0 top-0 right-0 bg-gradient-to-br from-pink-300 via-purple-300 to-purple-500 p-4 rounded-xl">
                    <ReactCrop onImageLoaded={onLoad} crop={crop} onChange={newCrop => setCrop(newCrop)} src={pdfImage} className="rounded-xl overflow-hidden"/>
                    <button onClick={done} className="flex w-full px-6 py-3 mt-2 rounded-xl bg-pink-100/50 sm:hover:bg-pink-100/70 text-pink-900">
                        {(uploading?.length > 0) ? (
                            <i className={"fad fa-spinner-third animate-spin"} />
                        ):(
                            <p className="text-center w-full skew-x-12 font-black">
                                BRUK DETTE SOM BILDE
                            </p>
                        )}
                    </button>
                </div>
            )}
        </div>
    )
})

export function getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    console.log(`x: ${scaleX}, y: ${scaleY}`)
    canvas.width = crop.width
    canvas.height = crop.height
    const ctx = canvas.getContext('2d')

    // New lines to be added
    const pixelRatio = window.devicePixelRatio
    canvas.width = crop.width * pixelRatio
    canvas.height = crop.height * pixelRatio
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx.imageSmoothingQuality = 'high'

    ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
    )

    // As Base64 string
    // const base64Image = canvas.toDataURL("image/jpeg");
    // return base64Image;

    // As a blob
    return new Promise((resolve, reject) => {
        canvas.toBlob(
            (blob) => {
                blob.name = fileName
                resolve(blob)
            },
            'image/jpeg',
            1
        )
    })
}

export const useDrawing = (no, resourceQuery = 'no') => {
    const { data, ...n } = useQuery(['drawing', 'pdf', no], () =>
            fetch(`/api/kanban/v1/kalkyle/item/drawing?${resourceQuery}=${encodeURIComponent(no)}`)
                .then(async res => {
                    if (res.status !== 200) throw new Error('failed to fetch drawing: ' + await res.text())
                    return res.blob()
                })
                .then(b => {
                    console.log({ b })
                    return {
                        url: URL.createObjectURL(b)
                    }
                }),
        {
            refetchOnMount: false,
            refetchOnWindowFocus: false
        }
    )

    return {
        drawing: data, ...n
    }
}


function highlightPattern(text, pattern) {
    const splitText = text.split(pattern)

    if (splitText.length <= 1) {
        return text
    }

    const matches = text.match(pattern)

    console.log({ matches })

    return splitText.reduce((arr, element, index) => (matches[index] ? [
        ...arr,
        element,
        <mark className="text-red-500 fill-current" key={index}>
            {matches[index]}
        </mark>
    ] : [...arr, element]), [])
}
