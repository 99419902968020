import { useRef, useState } from 'react'
import { useOutsideAlerter } from '../../kanban/page'
import { useKalkyleCacheInvalidate } from '../operation/thought'
import { useMutation } from 'react-query'
import { toast } from 'react-hot-toast'
import { useDefaultState, useToggle } from '../index'
import { useKeyPress } from '../toolbelt'
import axios from 'axios'
import { useDrop2 } from './child'

export const Tag = ({ id, tag, isPut }) => {
    const [newTag, setNewTag] = useDefaultState(tag)
    const ref = useRef()
    const { isOpen, open, close } = useToggle(false)
    useOutsideAlerter(ref, close)

    const mutation = useTagMutation(id, newTag)

    const handleMutate = () => {
        close()
        mutation.mutate()
    }

    useKeyPress('Enter', handleMutate,{}, isOpen)

    const { uploading, appendFiles } = useItemDrawingUpload(id, tag)
    const isUploading = uploading.length > 0
    const { isHovering, funcs } = useDrop2(() => {}, {}, appendFiles)


    const missingDrawing = (isPut === false) && (tag.length > 0)

    return (
        <div>
            <button {...funcs} onClick={open} style={{ padding: '1px 6px' }}
                    className={`flex items-center space-x-1
                            ${isHovering ? "bg-green-500 text-white" : "sm:hover:bg-gray-200 sm:dark:hover:bg-gray-700"}
                             px-1 rounded-lg`}>
                <i className="fad fa-drafting-compass"/>
                <div>
                    {(mutation.isLoading || isUploading) ? (
                        <p className="fad fa-spinner-third animate-spin text-green-400"/>
                    ) : (
                        <p>{tag.length > 0 ? tag : '-'}</p>
                    )}

                </div>
                {missingDrawing === true && (
                    <i className="fad fa-times-circle text-red-500" />
                )}
            </button>
            {isOpen && (
                <div className="absolute top-full inset-0 z-30 pop-open" ref={ref}>
                    <div
                        className="bg-gradient-to-br text-gray-800 from-blue-400 via-indigo-400 to-purple-500 p-3 rounded-xl flex flex-col">
                        <p className="font-bold text-base">Hva er tegningsnummeret?</p>
                        <input type="text" placeholder="ITM-1234" value={newTag}
                               autoFocus
                               onChange={e => setNewTag(e.target.value)}
                               className="mt-1 font-medium bg-gray-800 text-white rounded-lg w-full px-3 py-2 outline-none focus:ring-4 ring-indigo-300"/>
                        <button onClick={handleMutate}
                                className="bg-green-400 active:ring-2 ring-gray-800 outline-none mt-3 text-gray-800 m-auto px-6 py-1 rounded-full text-md">OK
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}

const useItemDrawingUpload = (id, no) => {
    return useFileUpload(`/api/kanban/v1/kalkyle/item/drawing?id=${encodeURIComponent(id)}&no=${encodeURIComponent(no)}`)
}

export const useFileUpload = (path) => {
    const { invalidateThoughtItems } = useKalkyleCacheInvalidate()
    const [uploading, setUploading] = useState([])

    const appendFiles = async (files) => {
        console.log('append files: ', files)
        for (const file of files) {
            await setUploading(prev => [...prev, {
                progress: 0,
                objectURL: URL.createObjectURL(file),
                file: file
            }])
        }

        for (const file of files) {
            try {
                await handler(file)
                await setUploading(prev => prev.filter(p => p.file.name !== file.name))
            } catch (err) {
                console.log('err ', err)
            }
        }
    }

    const handler = async (file) => {
        const data = new FormData()
        data.append('fileupload', file)

        try {
            const res = await axios.post(path, data, {
                onUploadProgress: progressEvent => {
                    const percentage = (progressEvent.loaded / progressEvent.total * 100).toFixed(0)
                    setUploading(prev => {
                        return prev.map(p => {
                            if (p.file.name !== file.name) return p
                            return Object.assign({}, p, {
                                progress: percentage
                            })
                        })
                    })
                }
            })

            if (res.status === 202) {
                toast.success(`${file.name} er lasta opp!`)
            }

            await invalidateThoughtItems()

        } catch (err) {
            toast.error(`kunne ikke laste opp bilde`)
            console.log('err: ', err)
        }

    }

    return {
        appendFiles, uploading
    }
}


const useTagMutation = (id, tag) => {
    const { invalidateThoughtItems } = useKalkyleCacheInvalidate()
    const handleTagMutation = () =>
        fetch(`/api/kanban/v1/kalkyle/thought-item/tag?id=${encodeURIComponent(id)}`, {
            method: 'PUT',
            body: JSON.stringify({
                tag
            })
        }).then(async res => {
            console.log('res: ', res)
            if (res.status !== 202) throw new Error(await res.text())
        })

    return useMutation(handleTagMutation, {
        onSuccess: async () => {
            await invalidateThoughtItems()
            toast.success('Tegningsnummer oppdatert!')
        },
        onError: (err) => {
            toast.error(err.toString())
        }
    })
}