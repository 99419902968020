import axios from 'axios'
import { useState } from 'react'
import { useQueryClient } from 'react-query'

export const UploadingAsBuiltImage = ({ file }) => {
    const { objectURL, progress } = file

    return (
        <div
            className="relative flex justify-center items-center bg-gray-100 dark:bg-gray-700 rounded-xl h-32 overflow-hidden"
            style={{
                minWidth: '8rem',
                backgroundImage: `url(${objectURL})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }}>
            <i className="fad fa-spinner-third text-xl animate-spin z-20"/>
            <div className="absolute bottom-0 left-0 right-0 bg-green-500 z-10 opacity-50"
                 style={{ top: `${100 - progress}%` }}/>
        </div>
    )
}

export const useAsBuiltUpload = (orderNo, lineNo, routingNo) => {
    const [uploading, setUploading] = useState([])
    const query = `?order_no=${encodeURIComponent(orderNo)}&line_no=${encodeURIComponent(lineNo)}&routing_no=${encodeURIComponent(routingNo)}`

    const client = useQueryClient()

    const appendFiles = async (files) => {
        for (const file of files) {
            await setUploading(prev => [ ...prev, {
                progress: 0,
                objectURL: URL.createObjectURL(file),
                file: file
            }])
        }

        for (const file of files) {
            const record = await handler(file)
            await setUploading(prev => prev.filter(p => p.file.name !== file.name))

            const key = ['route', orderNo, lineNo, routingNo]
            const data = client.getQueryData(key)
            client.setQueryData(key, Object.assign({}, data, {
                as_built_records: [record, ...data.as_built_records]
            }))
        }
    }

    const handler = async (file) => {
        const data = new FormData()
        data.append('fileupload', file)

        const res = await axios.put(`/api/kanban/v1/drawing/as-built/complete${query}`, data, {
            onUploadProgress: progressEvent => {
                const percentage = (progressEvent.loaded / progressEvent.total * 100).toFixed(0)
                setUploading(prev => {
                    return prev.map(p => {
                        if (p.file.name !== file.name) return p
                        return Object.assign({}, p, {
                            progress: percentage
                        })
                    })
                })
            }
        })

        if (res.status !== 200) {
            window.alert(`kunne ikke laste opp fil: ${file.name}`)
            return
        }

        return res.data
    }

    return {
        uploading,
        appendFiles
    }
}
