import { useParams } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import { ActualPdfViewer, ActualPDFViewerMenu, useDrawing } from '../drawing'
import { useChartDimensions, useOutsideAlerter } from '../../kanban/page'
import { ItemMenuBar } from './menubar'
import { ThoughtOperationsLister, useKalkyleCacheInvalidate } from '../operation/thought'
import { TextMenu } from './textMenu'
import { toast } from 'react-hot-toast'
import { useMaterialSearch } from '../toolbelt'
import { useRef, useState } from 'react'
import { useToggle } from '../index'
import { getUnit } from '../../kanban/lager'
import { Material, useAddMaterialMutation } from './material'
import { Image } from '../../drawing/image'

export const ItemPage = () => {
    const { id, childID } = useParams()
    const { item, isLoading } = useThoughtItem(childID)

    if (isLoading) return (
        <div
            className="max-w-4xl shadow-[2rem_2rem_6rem_rgba(0,0,0,.1)] h-[50vh] dark:bg-gray-800 rounded-xl m-auto pb-4 animate-pulse">
        </div>
    )

    return (
        <div>
            <div className="shadow-[2rem_2rem_6rem_rgba(0,0,0,.1)] dark:bg-gray-800 rounded-xl m-auto pb-4">
                {item?.drawing?.exists ? (
                    <PDFViewer id={item?.drawing?.id} itemNo={item.tag}/>
                ) : (
                    <div className="pt-4 pl-4 pr-4">
                        <Image no={item?.tag} className="h-64 max-w-fit rounded-2xl"/>
                    </div>
                )}

                <div className="px-4 pt-2">
                    <SafeItemMenuBarWrapper item={item} isLoading={isLoading}/>
                </div>

                <div className="relative px-4 py-2">
                    <MaterialLister materials={item?.materials}/>
                    <ChildrenLister id={id} chilren={item?.children?.published ?? []} />
                    <AddMaterialButton externalMetadata={item?.external_metadata}/>
                </div>

                <div className="relative">
                    <ThoughtOperationsLister operations={item?.operations ?? []}/>
                    <AddOperationButton item={item}/>
                </div>
            </div>

            <div className="h-[50vh]"/>
        </div>
    )
}

const ChildrenLister = ({ id, chilren }) => {
    return (
        <div className="flex flex-col space-y-2">
            {chilren?.map((c, i) => (

                <div key={i} className="flex items-center space-x-2 text-purple-900 dark:text-purple-400 font-medium bg-purple-500 bg-opacity-5 px-3 py-2 rounded-lg">
                    <Image no={c.tag} />
                    <div>
                        <p className="font-bold">{c.description}</p>
                        <p className="">{c.tag}</p>
                        <div className="flex space-x-2">
                            <p>{c.quantity}stk</p>
                            <p>{c.metadata.full_price}kr</p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

const MaterialLister = ({ materials }) => {
    return (
        <div>
            <div className="grid" style={{ gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))' }}>
                {materials?.map((mat, index) => (
                    <Material material={mat} key={index}/>
                ))}
            </div>
        </div>
    )
}

const AddMaterialButton = () => {
    const ref = useRef()
    const { isOpen, close, toggle, open } = useToggle(false)
    const [term, setTerm] = useState('')
    const { data, isLoading, isFetching } = useMaterialSearch(term)

    useOutsideAlerter(ref, close)

    return (
        <div className="" ref={ref}>
            <button onClick={toggle}
                    className="flex space-x-1 items-center px-2 rounded-full sm:hover:dark:bg-gray-700 sm:hover:bg-gray-100 rounded-lg group">
                <i className="fad fa-cart-plus text-xl text-green-500 dark:text-green-400 group-hover:dark:text-green-300 group-hover:dark:text-green-400"/>
                <span className="font-medium text-sm text-gray-500 dark:text-gray-300">
                        Vi trenger noe mer...
                </span>
            </button>
            {isOpen && (
                <div
                    className="pop-open absolute top-full z-30 left-0 right-0 bg-gradient-to-br from-yellow-400 to-orange-500 p-4 rounded-xl">
                    <p className="text-base text-yellow-900 font-bold">Hva trenger vi?</p>
                    <input
                        value={term}
                        className="my-2 w-full text-base rounded-lg px-3 py-2 bg-yellow-100 text-orange-900 border-4 border-yellow-400 focus:outline-none focus:border-green-400"
                        autoFocus type="text" onChange={e => setTerm(e.target.value)}/>
                    {isLoading && (
                        <i className="fad fa-spinner-third animate-spin"/>
                    )}
                    <div className="grid"
                         style={{ gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))', gridGap: '0.5em' }}>
                        {data?.map((material, index) => (
                            <MaterialButton material={material} key={index} close={close}/>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}

const MaterialButton = ({ material, close }) => {
    const { childID } = useParams()
    let toastID

    const mutation = useAddMaterialMutation(childID, () => {
        toast.dismiss(toastID)
        toast.success(`${material.description} er lagt til!`)
    })

    const handleMutation = async () => {
        close()
        toastID = toast.loading(`Legger til ${material.description}`)
        mutation.mutate(material.no)
    }

    return (
        <div className="bg-yellow-200 bg-opacity-50 p-2 rounded-lg cursor-pointer sm:hover:bg-opacity-60"
             onClick={handleMutation}>
            <p className="font-bold text-orange-900">{material.description}</p>
            <p className="text-yellow-900">{material.no}</p>

            {material?.inventory?.map((inv, i) => (
                <div className="flex flex-wrap space-x-2">
                    <p className="text-orange-900">{inv.stock_quantity}{getUnit(material.base_unit_of_measure)}</p>
                    {inv?.reol?.has_set && (
                        <p className="px-3 rounded-full bg-purple-500"><i
                            className="fad fa-inventory mr-1"/>{inv.reol.no}</p>
                    )}
                </div>
            ))}
        </div>
    )
}

export const PDFViewer = ({ id, itemNo, resourceQuery = 'id' }) => {
    const [ref, dimensions] = useChartDimensions()
    const { drawing, isLoading: dawingisloading } = useDrawing(id ?? '', resourceQuery)
    const pdfRef = useRef()

    return (
        <div className="p-4 relative" ref={ref}>
            <ActualPdfViewer dimensions={dimensions} isLoading={dawingisloading} url={drawing?.url} ref={pdfRef}/>
            <ActualPDFViewerMenu  url={drawing?.url} ref={pdfRef} itemNo={itemNo}/>
        </div>
    )
}

const AddOperationButton = ({ item }) => {
    const { id } = useParams()
    const mut = useAddOperationMutation(id, item)

    return (
        <div className="px-4">
            <TextMenu mutation={mut}
                      className="flex space-x-1 items-center px-2 rounded-full sm:hover:dark:bg-gray-700 sm:hover:bg-gray-100 rounded-lg group"
                      title="Hva skal vi gjøre?" positionClassName="" placeholder="Frese noe...">
                <i className="fad fa-user-hard-hat text-xl text-green-500 dark:text-green-400 group-hover:dark:text-green-300 group-hover:dark:text-green-400"/>
                <span className="font-medium text-sm text-gray-500 dark:text-gray-300">
                        Vi skal gjøre noe mer...
                    </span>
            </TextMenu>
        </div>
    )
}

const useAddOperationMutation = (kalkyleID, item) => {
    const { invalidateThoughtItems } = useKalkyleCacheInvalidate()
    let queries = `/default?kalkyle_id=${kalkyleID}&item_id=${item.id}`

    if (item.external_metadata.published) {
        queries = `/published?parent_key=${encodeURIComponent(item.external_metadata.key)}`
    }

    const handle = (description) =>
        fetch(`/api/kanban/v3/kalkyle/operation/add/directly${queries}`, {
            method: 'POST',
            body: JSON.stringify({
                description
            })
        }).then(async res => {
            if (res.status !== 202) throw new Error(await res.text())
        })

    return useMutation(handle, {
        onSuccess: async () => {
            await invalidateThoughtItems()
            toast.success('Gjøremål lagt til!')
        },
        onError: err => {
            toast.error('Kunne ikke legge til operasjon: ' + err)
            console.error(`[AddOperation] failed: ${err}`)
        }
    })
}

const SafeItemMenuBarWrapper = ({ item, isLoading }) => {
    if (isLoading) return null
    return <ItemMenuBar item={item}/>
}

const useThoughtItem = (id) => {
    const handler = () => fetch(`/api/kanban/v1/kalkyle/thought-item?id=${id}`)
        .then(async res => {
            if (res.status !== 200) throw new Error(await res.text())
            return res.json()
        })

    const { data, ...rest } = useQuery(['kalkyle', 'thought-item', id], handler)

    return {
        item: data,
        ...rest
    }
}