import { Child } from './item/child'
import { SiteMap } from './sitemap'
import { BrainDumps } from './braindump'
import { Add } from './add'
import { RemoveOperation, useColumnsCount } from './page'
import { useLayoutEffect, useState } from 'react'

export const Kalkyle = ({ items }) => {
    const [ref, config] = useColumnsCount(550)

    return (
        <div className="relative m-auto min-h-screen pb-48">
            <div className="grid grid-cols-12" style={{ gridGap: '1rem' }}>
                <div className="col-span-12 sm:col-span-3 flex flex-col space-y-8">
                    <SiteMap/>
                    <BrainDumps/>
                    <RemoveOperation/>
                    <Add/>
                </div>
                <div className="col-span-12 sm:col-span-9 box-border" ref={ref}>
                    <ItemsLister items={items} config={config}/>
                </div>
            </div>
        </div>
    )
}
const ItemsLister = ({ items, config }) => {
    const { cellWidth, columnsCount } = config
    const groupedThoughtItems = useItemList(items, columnsCount)

    return (
        <div className="flex space-x-4">
            {groupedThoughtItems.map((c, i) => (
                <div className="flex flex-col max-2 space-y-4" style={{ width: cellWidth }} key={i}>
                    {c?.map((itm, j) => (
                        <Child item={itm} key={j} />
                    ))}
                </div>
            ))}
        </div>
    )
}

const useItemList = (thoughtItems, columnsCount) => {
    const [list, setList] = useState([[]])

    useLayoutEffect(() => {
        let colCount = 0
        let neuelist = [[]]

        for (let i in thoughtItems) {
            if (neuelist.length <= colCount) {
                neuelist.push([])
            }
            neuelist[colCount].push(thoughtItems[i])
            if (colCount >= columnsCount - 1) {
                colCount = 0
            } else {
                colCount++
            }
        }
        setList(neuelist)
    }, [thoughtItems, columnsCount])

    return list
}
