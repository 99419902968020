import React, { Suspense, useEffect, useRef } from 'react'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { Canvas, useFrame, useLoader } from '@react-three/fiber'
import colors from 'tailwindcss/colors'

const Coin = ({ jumpfunc }) => {

    useEffect(() => {
        jumpfunc.current = jump
    }, [])

    const acceleration = useRef({ x: 0, y: 0, z: 0 })
    const spinSpeed = useRef(0.01)

    const jump = () => {
        spinSpeed.current += 0.2
        if (mesh.current.position.y > 7) return
        acceleration.current.y = Math.max((mesh.current.position.y-7), 0.2)
    }

    const obj = useLoader(OBJLoader, 'https://raw.githubusercontent.com/AleksSpifu/objfile/main/coin.obj')
    const mesh = useRef(null)
    useFrame(() => {

        mesh.current.position.x += acceleration.current.x
        mesh.current.position.y += acceleration.current.y
        mesh.current.position.z += acceleration.current.z
        if (mesh.current.position.y > 6) {
            if (acceleration.current.y > 0) {
                acceleration.current.y = 0
            }
        }
        if (mesh.current.position.y > 0) {
            acceleration.current.y -= 0.01
        }
        else if (mesh.current.position.y < 0) {
            acceleration.current.y = 0
            mesh.current.position.y = 0
        }
        if (spinSpeed.current > 0.01) {
            spinSpeed.current *= 0.98
        }
        else if (spinSpeed.current < 0.01) {
            spinSpeed.current = 0.01
        }
        mesh.current.rotation.y += spinSpeed.current
    })

    return (
        <mesh ref={mesh} onClick={() => jump()}>
            <primitive object={obj} scale={5} position={[0, -4, 0]}>
            </primitive>
            <meshStandardMaterial attach='material' color='blue' />
        </mesh>
    )
}

export const WindowWithCoin = ({jumpfuncIn}) => {

    return (
        <div className='absolute -z-30 inset-x-0 -bottom-full -top-full -translate-y-2/3 '>
            <Canvas colorManagement camera={{ position: [-5, 2, 10], fov: 30 }}>
                <Suspense fallback={null}>
                    <ambientLight intensity={0.1} color={colors.yellow["500"]} />
                    <directionalLight
                        color={colors.yellow["200"]}
                        position={[2, 2, 4]}
                        intensity={1}

                    />
                    <directionalLight
                        color={colors.yellow["700"]}
                        position={[-2, -2, 4]}
                        intensity={1}
                    />
                    <Coin jumpfunc={jumpfuncIn} />
                </Suspense>

            </Canvas>
        </div>
    );
}