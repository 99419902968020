import React, { useEffect, useRef, useState } from 'react'
import { toobeltTextSearchAtom, useMaterialSearch } from '../kalkyle/toolbelt'
import { useRecoilState } from 'recoil'
import { IncommingPurchaes } from './incomming'
import { ListItem } from './item'

export const InventoryPage = () => {
    const [searchTerm, setSearchTerm] = useRecoilState(toobeltTextSearchAtom)
    const { data, isLoading, isFetching } = useMaterialSearch(searchTerm)

    const searching = searchTerm.length > 0
    return (
        <div className="">

            <div className="max-w-7xl m-auto">
                <h1 className="flex text-4xl md:text-6xl text-green-400 font-black skew-x-12">
                    <i className="fa-solid fa-inbox md:text-7xl mr-4"/>
                    INNBOKS
                </h1>
            </div>

            <div className="flex flex-col m-auto max-w-4xl px-2 py-4">
                <SearchBar isFetching={isFetching} term={searchTerm} setSearchTerm={setSearchTerm}/>
            </div>

            {searching ? (
                <div className="grid"
                     style={{ gridTemplateColumns: 'repeat(auto-fit, minmax(400px, 1fr))', gridGap: '1rem' }}>
                    {data?.map((d, i) => (
                        <ListItem key={i} item={d}/>
                    ))}
                </div>
            ) : (
                <IncommingPurchaes/>
            )}
        </div>
    )
}

const SearchBar = ({ term, setSearchTerm, isFetching }) => {
    const [state, setState] = useState('')
    const initial = useRef(true)

    // creates an offset so we won't overload the search api
    useEffect(() => {
        if (initial.current) {
            initial.current = false
            return
        }

        const timer = setTimeout(() => {
            setSearchTerm(state)
        }, 200)

        return () => clearTimeout(timer)
    }, [setSearchTerm, state])

    return (
        <input
            className={`text-lg text-gray-900 outline-none focus:ring-2 ${isFetching ? 'ring-green-400' : 'ring-purple-400'} ring-opacity-50 ring-offset-2 ring-offset-white dark:ring-offset-gray-900 rounded-lg py-3 px-6 w-full bg-gray-100 dark:bg-gray-800/50 dark:text-white`}
            autoFocus
            type="text"
            autoCorrect="off"
            autoComplete="off"
            autoCapitalize="none"
            spellCheck="false"
            role="combobox"
            placeholder="Søk etter et materiale..."
            value={term}
            onChange={event => setSearchTerm(event.currentTarget.value)}
        />
    )
}
