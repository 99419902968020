import React from 'react'
import ReactDOM from 'react-dom'
import '@fortawesome/fontawesome-pro/css/all.css'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import * as Sentry from '@sentry/browser'

import { initGoogleAnalytics, logPageView } from './analytics'

import './styling/index.css'
import './styling/ownership.css'
import './styling/drawing.css'
import './tailwind.css'
import './styling/kanban.css'
import './styling/spinner.css'
import './styling/record.css'
import './styling/slide.css'
import './styling/operation.css'
import './styling/animate.css'

import { useBouncer } from './components/bouncer'
import Verify from './pages/login/verify'
import Login from './pages/login'
import App from './navigation/routing'
import useUser from './hooks/useUser'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { SignUp } from './login/sign_up'
import { Toaster } from 'react-hot-toast'

initGoogleAnalytics()
Sentry.init({
    dsn: 'https://4574b07a2ca04756985db79e3dd482b1@sentry.io/1783865'
})

const AppRouter = () => {
    useBouncer()
    useUser()

    if (module.hot) {
        module.hot.accept( () => {
        }) // you don't need this if you're using CRA
    }

    return (
        <div>
            <Route
                path="/"
                render={props => {
                    logPageView(props.location.pathname)
                    return null
                }}
            />
            <Switch>
                <Route path="/login/verify">
                    <Verify/>
                </Route>
                <Route path="/login/sign-up">
                    <SignUp />
                </Route>
                <Route path="/login">
                    <Login/>
                </Route>
                <Route path="/">
                    <div className="min-h-screen">
                        <App/>
                    </div>
                </Route>
            </Switch>

            <Toaster position="bottom-center" />
        </div>
    )
}

const queryClient = new QueryClient()

ReactDOM.render(
    <QueryClientProvider client={queryClient}>
        <Router>
            <AppRouter/>
        </Router>
        <ReactQueryDevtools initialIsOpen={false}/>
    </QueryClientProvider>,
    document.getElementById('root')
)
