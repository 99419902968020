import { useState } from 'react'
import { Link, useHistory as useHerstory } from 'react-router-dom'

export function WebauthnPage() {
    const herstory = useHerstory()
    const [keyname, setKeyname] = useState("")

    const { register, login } = useWebAuthn(keyname, () => {
        herstory.push("/user")
    })

    return (
        <div className="overflow-hidden max-w-5xl m-auto py-8 px-4">
            <div className="slide shadow-paper-lg dark:bg-depth-6 rounded-3xl max-w-md m-auto block p-4">
                <div className="">
                    <h1 className="text-3xl text-center font-bold">Legg til en <br/> sikkerhetsnøkkel</h1>
                    <h2 className="text-center mt-4">Gi denne sikkerhetsnøkkelen et kallenavn så det er lettere å se forskjellen på dem senere.</h2>
                    <input type="text"
                           className="mt-4 p-2 w-full border-b-2 outline-none border-dashed border-gray-500 focus:border-purple-500 bg-transparent text-xl font-bold text-center"
                           placeholder="iPad Touch ID"
                           autoFocus
                           onChange={event => setKeyname(event.target.value)}
                           value={keyname}
                    />
                    <div className="flex justify-between mt-4">
                        <Link to="/user">
                            <button className="text-red-500 hover:text-red-400 px-8 py-2 rounded-full">
                                Avbryt
                            </button>
                        </Link>
                        <button
                            className="bg-purple-400 hover:bg-purple-500 rounded-full px-8 py-2 text-white"
                            onClick={register}>Legg Til
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function useWebAuthn(input, callback) {
    const [loading, setLoading] = useState(false)

    const registrationOptions = async () => {
        const res = await fetch('/api/kanban/v1/webauthn/register/begin')
        const options = await res.json()
        console.log('options: ', options)

        options.publicKey.challenge = bufferDecode(options.publicKey.challenge)
        options.publicKey.user.id = bufferDecode(options.publicKey.user.id)

        return options
    }

    const beginRegistration = async () => {
        const opts = await registrationOptions()
        return await navigator.credentials.create(opts)
    }

    const finishRegistration = async (credential) => {
        let attestationObject = credential.response.attestationObject
        let clientDataJSON = credential.response.clientDataJSON
        let rawId = new Uint8Array(credential.rawId)

        const body = JSON.stringify({
            id: credential.id,
            rawId: bufferEncode(rawId),
            type: credential.type,
            response: {
                attestationObject: bufferEncode(attestationObject),
                clientDataJSON: bufferEncode(clientDataJSON)
            }
        })

        const res = await fetch('/api/kanban/v1/webauthn/register/finish', {
            method: 'POST',
            headers: {
                "keyname": input,
            },
            body
        })
    }

    const register = async () => {
        try {
            setLoading(true)
            const creds = await beginRegistration()
            console.log(creds)
            await finishRegistration(creds)
            setLoading(false)
            callback()
        } catch (err) {
            console.error(err)
            window.alert("noe gikk galt: " + err)
        }
    }

    const loginOptions = async () => {
        const res = await fetch(`/api/kanban/v1/webauthn/login/begin?account=${input}`)
        let options = await res.json()
        options.publicKey.challenge = bufferDecode(options.publicKey.challenge)
        options.publicKey.allowCredentials = options.publicKey.allowCredentials.map(item =>
            Object.assign({}, item, {
                id: bufferDecode(item.id)
            })
        )
        return options
    }

    const login = async () => {
        try {
            const options = await loginOptions()
            const creds = await navigator.credentials.get(options)
            await finishLogin(creds)
        } catch (err) {
            console.error(err)
            window.alert("noe gikk galt: " + err)
        }
    }

    const finishLogin = async (credential) => {
        const authData = new Uint8Array(credential.response.authenticatorData)
        const clientDataJSON = new Uint8Array(credential.response.clientDataJSON)
        const rawId = new Uint8Array(credential.rawId)
        const signature = new Uint8Array(credential.response.signature)
        const userHansle = new Uint8Array(credential.response.userHandle)

        const body = JSON.stringify({
            id: credential.id,
            rawId: bufferEncode(rawId),
            type: credential.type,
            response: {
                authenticatorData: bufferEncode(authData),
                clientDataJSON: bufferEncode(clientDataJSON),
                signature: bufferEncode(signature),
                userHansle: bufferEncode(userHansle)
            }
        })

        const res = await fetch("/api/kanban/v1/webauthn/login/finish", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body
        })

        callback()
    }

    return {
        register,
        login,
        loading
    }
}

// ArrayBuffer to URLBase64
function bufferEncode(value) {
    return btoa(String.fromCharCode.apply(null, new Uint8Array(value)))
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '')
}

// Base64 to ArrayBuffer
function bufferDecode(value) {
    return Uint8Array.from(atob(value), c => c.charCodeAt(0))
}
