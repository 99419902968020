import axios from "axios"
import { useQuery, useMutation, useQueryClient } from "react-query"


export const useResponsibleEmployee = (center, machine) => {
    return useQuery(['responsible-employee', center, machine], fetchResponsibleEmployee,
    {
        retry: false,
    })
}

export const useEligibleEmployees = () => {
    return useQuery('eligible-employees', fetchEligibleEmployees,
    {
        keepPreviousData: true,
        refetchOnWindowFocus: true,
        cacheTime: 3600000,

    })
}

export const useAllResponsibleEmployees = () => {
    return useQuery('all-responsible-employees', fetchAllResponsibleEmployees,
    {

    })
}

const fetchResponsibleEmployee = ({ queryKey }) => {
    const center = queryKey[1]
    const machine = queryKey[2]
    //return axios.get('https://randomuser.me/api/?results=1')
    return axios.get(`/api/kanban/v1/kanban/responsible-employee/get?work_center=${center}&machine_center=${machine}`)
}

const fetchEligibleEmployees = ({ queryKey }) => {
    const center = queryKey[1]
    const machine = queryKey[2]
    //return axios.get('https://randomuser.me/api/?results=20')
    return axios.get('/api/kanban/v1/kanban/responsible-employee/get-eligible')
}

const fetchAllResponsibleEmployees = () => {
    return axios.get('/api/kanban/v1/kanban/responsible-employee/get-all-responsible')
}


export const useMutateResponsibleEmployee = () => {
    const queryClient = useQueryClient()

    return useMutation(setResponsibleEmployee, {
        onMutate: async (mutationObj) => {
            const {center, machine, emp} = mutationObj
            //console.log('attempting to mutate', center, machine, emp.name.first)
            await queryClient.cancelQueries(['responsible-employee', center, machine])
            await queryClient.cancelQueries('all-responsible-employees')

            const previousData = queryClient.getQueryData(['responsible-employee', center, machine])

            const eligibleEmps = await queryClient.getQueryData('eligible-employees')
            queryClient.setQueryData(['responsible-employee', center, machine], (oldData) => {

                return { 
                    ...oldData,
                    data: eligibleEmps.data.find(e => e.employee_no === emp?.employee_no)
                    
                }
                //Data: {results: [eligibleEmps.data.results.find(e => e.id.value === mutationObj.emp.id.value)]}}
            })

            queryClient.setQueryData('all-responsible-employees', (oldData) => {
                emp.machine_center_no = machine
                emp.work_center_no = center
                if (oldData.data === null) {
                    return {
                        ...oldData,
                        data: [emp]
                    }
                }

                const oldResponsible = oldData.data && oldData.data.filter(e => e.machine_center_no !== machine && e.work_center_no !== center)
                return {
                    ...oldData,
                    data: [...oldResponsible, emp] 
                }
            })
            
            return {previousData}
        },
        onError: (_error, _hero, context) => (queryClient.setQueryData(context.previousData)),
        onSettled: () => {
            queryClient.invalidateQueries('responsible-employee')
            queryClient.invalidateQueries('all-responsible-employees')
        },
    })
}



const setResponsibleEmployee = ({center, machine, emp}) => {
    //console.log('putting', center, machine, emp)
    return axios.post(`/api/kanban/v1/kanban/responsible-employee/set?work_center=${center}&machine_center=${machine}&emp_no=${emp.employee_no}`)
}